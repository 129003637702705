import React, { useEffect, useState } from 'react';
import EmpruntService from '../../../controleurs/services/Emprunt';
import PassagerService from '../../../controleurs/services/Passager';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    Button,
    Modal,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import Utilisateur from '../../../controleurs/services/Utilisateur';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        secondary: {
            main: '#8bc34a',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575',
        },
    },
});

function EmpruntDetails({ empruntId, onClose }) {
    const [emprunt, setEmprunt] = useState(null);
    const [passagers, setPassagers] = useState([]);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    useEffect(() => {
        const fetchEmpruntDetails = async () => {
            try {
                const empruntData = await EmpruntService.getEmpruntById(empruntId);
                setEmprunt(empruntData);

                const passagersData = await UtilisateurService.getPassagers(empruntId);
                setPassagers(passagersData);
            } catch (error) {
                console.error('Erreur lors de la récupération des détails de l\'emprunt:', error);
            }
        };

        fetchEmpruntDetails();
    }, [empruntId]);

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    if (!emprunt) {
        return <Typography>Chargement...</Typography>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Détails de l'emprunt
                    </Typography>
                    <Paper elevation={3}>
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Véhicule</Typography>
                                    <Typography>
                                        {`${emprunt.vehicule.marque} ${emprunt.vehicule.modele} - ${emprunt.vehicule.immatriculation}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Conducteur</Typography>
                                    <Typography>
                                        {`${emprunt.utilisateur.nom} ${emprunt.utilisateur.prenom}`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Destination</Typography>
                                    <Typography>
                                        {emprunt.destination ? emprunt.destination.adresseComplete : 'Non spécifiée'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Date de prise en charge prévue</Typography>
                                    <Typography>
                                        {format(new Date(emprunt.datePriseEnChargePrevue), 'dd/MM/yyyy HH:mm', { locale: fr })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Date de rendu prévue</Typography>
                                    <Typography>
                                        {format(new Date(emprunt.dateRenduPrevue), 'dd/MM/yyyy HH:mm', { locale: fr })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Lieu de prise en charge</Typography>
                                    <Typography>
                                        {emprunt.lieuDePriseEnCharge ? emprunt.lieuDePriseEnCharge.designation : 'Non spécifié'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Lieu de rendu</Typography>
                                    <Typography>
                                        {emprunt.lieuRendu ? emprunt.lieuRendu.designation : 'Non spécifié'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Passagers</Typography>
                                    {passagers.length > 0 ? (
                                        passagers.map((passager, index) => (
                                            <Typography key={index}>
                                                {`${passager.nom} ${passager.prenom}`}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography>Aucun passager</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Motif</Typography>
                                    <Typography>
                                        {emprunt.motif || 'Non spécifié'}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">État</Typography>
                                    <Typography>
                                        {emprunt.etat}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={onClose}>
                                        Fermer
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la récupération des détails de l'emprunt.
                    </Typography>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default EmpruntDetails;