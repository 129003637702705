import React, { useEffect, useState } from 'react';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import RoleService from '../../../controleurs/services/Role';
import EntrepriseService from '../../../controleurs/services/Entreprise';
import Utilisateur from '../../../models/types/Utilisateur';

const Register = () => {
    const [utilisateur, setUtilisateur] = useState(new Utilisateur('', '', '', '', '', '', '', '', '', '', '', ''));
    const [errors, setErrors] = useState({});
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [roles, setRoles] = useState([]);
    const [entreprises, setEntreprises] = useState([]);

    const validateForm = () => {
        const requiredFields = ['nom', 'prenom', 'dateDeNaissance', 'adresse', 'email', 'telephone', 'motDePasse', 'idEntreprise', 'poste', 'matricule', 'idRole'];
        const isValid = requiredFields.every(field => utilisateur[field] && !errors[field]);
        setIsFormValid(isValid);
    };

    useEffect(() => {
        validateForm();
    }, [utilisateur, errors]);

    useEffect(() => {
        RoleService.getAllRoles()
            .then(data => {
                if (Array.isArray(data)) {
                    setRoles(data);
                } else {
                    console.log("Les données renvoyées ne sont pas un tableau");
                    setRoles([]);
                }
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des rôles :', error);
                setErrors({ ...errors, serverError: error.message });
            });

        EntrepriseService.getAllEntreprises()
            .then(data => {
                if (Array.isArray(data)) {
                    setEntreprises(data);
                } else {
                    console.log("Les données renvoyées ne sont pas un tableau");
                    setEntreprises([]);
                }
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des entreprises :', error);
                setErrors({ ...errors, serverError: error.message });
            });
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUtilisateur(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        validateField(name, type === 'checkbox' ? checked : value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            // Ajoutez ici des validations spécifiques pour chaque champ si nécessaire
            default:
                error = '';
                break;
        }
        setErrors(prevErrors => ({ ...prevErrors, [name]: error }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        const utilisateurInstance = new Utilisateur(
            utilisateur.nom,
            utilisateur.prenom,
            utilisateur.dateDeNaissance,
            utilisateur.adresse,
            utilisateur.email,
            utilisateur.telephone,
            utilisateur.motDePasse,
            utilisateur.idEntreprise,
            utilisateur.poste,
            utilisateur.matricule,
            utilisateur.idRole
        );
        const utilisateurJSON = JSON.stringify(utilisateurInstance);
        formData.append('utilisateur', utilisateurJSON);

        UtilisateurService.addUtilisateur(formData)
            .then(() => {
                setOpenSuccessModal(true);
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout de l\'utilisateur :', error);
                setErrors({ ...errors, serverError: error.message });
                setOpenErrorModal(true);
            });
    };

    const handleCancel = () => {
        setUtilisateur(new Utilisateur('', '', '', '', '', '', '', '', '', '', '', ''));
        setErrors({});
        setRoles([]);
        setEntreprises([]);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        setUtilisateur(new Utilisateur('', '', '', '', '', '', '', '', '', '', '', ''));
        setErrors({});
        setRoles([]);
        setEntreprises([]);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="nom" value={utilisateur.nom} onChange={handleChange} placeholder="Nom" />
            <input type="text" name="prenom" value={utilisateur.prenom} onChange={handleChange} placeholder="Prénom" />
            <input type="date" name="dateDeNaissance" value={utilisateur.dateDeNaissance} onChange={handleChange} placeholder="Date de Naissance" />
            <input type="text" name="adresse" value={utilisateur.adresse} onChange={handleChange} placeholder="Adresse" />
            <input type="email" name="email" value={utilisateur.email} onChange={handleChange} placeholder="Email" />
            <input type="tel" name="telephone" value={utilisateur.telephone} onChange={handleChange} placeholder="Téléphone" />
            <input type="password" name="motDePasse" value={utilisateur.motDePasse} onChange={handleChange} placeholder="Mot de Passe" />
            <select name="idEntreprise" value={utilisateur.idEntreprise} onChange={handleChange}>
                <option value="">Sélectionnez une entreprise</option>
                {entreprises.map(entreprise => (
                    <option key={entreprise.id} value={entreprise.id}>{entreprise.nom}</option>
                ))}
            </select>
            <input type="text" name="poste" value={utilisateur.poste} onChange={handleChange} placeholder="Poste" />
            <input type="number" name="matricule" value={utilisateur.matricule} onChange={handleChange} placeholder="Matricule" />
            <select name="idRole" value={utilisateur.idRole} onChange={handleChange}>
                <option value="">Sélectionnez un rôle</option>
                {roles.map(role => (
                    <option key={role.id} value={role.id}>{role.name}</option>
                ))}
            </select>
            <button type="submit" disabled={!isFormValid}>S'inscrire</button>
            <button type="button" onClick={handleCancel}>Annuler</button>
            {openSuccessModal && (
                <div>
                    <p>Inscription réussie !</p>
                    <button onClick={handleCloseSuccessModal}>Fermer</button>
                </div>
            )}
            {openErrorModal && (
                <div>
                    <p>Erreur lors de l'inscription.</p>
                    <button onClick={handleCloseErrorModal}>Fermer</button>
                </div>
            )}
        </form>
    );
};

export default Register;