import React, { useState, useEffect } from 'react';
import {
    Box, Container, Typography, Paper, CircularProgress, FormControl,
    InputLabel, Select, MenuItem, IconButton, Button,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Checkbox, ListItemText, OutlinedInput, useMediaQuery,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar,
    Alert, TextField, Autocomplete, Chip, ListSubheader, InputAdornment, OutlinedInput, Modal, List, ListItem
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import VehiculeService from '../../../controleurs/services/Vehicule';
import EmpruntService from '../../../controleurs/services/Emprunt';
import UtilisateurService from '../../../controleurs/services/Utilisateur'
import EmpruntDetails from './EmpruntDetails';
import UpdateEmprunt from './UpdateEmprunt';
import MailService from '../../../controleurs/services/Mail';

const theme = createTheme({
    palette: {
        primary: { main: '#6755A5' },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const eventColors = ['#FF9AA2', '#FFB7B2', '#FFDAC1', '#E2F0CB', '#B5EAD7', '#C7CEEA', '#B5EAD7'];

const formatTime = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        console.error('Invalid date:', date);
        return 'Invalid date';
    }
    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
};

const EmpruntItem = ({ emprunt, dayStart, dayEnd, isFirstDay, isLastDay, onEdit, onDelete, onClick }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleEdit = (event) => {
        event.stopPropagation();
        onEdit(emprunt);
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        onDelete(emprunt);
    };
    const backgroundColor = emprunt.etat === 'REFUSE'
        ? 'rgba(200, 200, 200, 0.7)'  // Gris semi-transparent pour les emprunts refusés
        : eventColors[emprunt.id % eventColors.length];

    return (
        <Box
            onClick={() => onClick(emprunt)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                backgroundColor: backgroundColor,
                borderRadius: '4px',
                padding: '2px',
                margin: '1px 0',
                fontSize: '0.7rem',
                cursor: 'pointer',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
                overflow: 'visible',
                minHeight: '40px',
                ...(isFirstDay ? { borderLeft: '2px solid black' } : {}),
                ...(isLastDay ? { borderRight: '2px solid black' } : {}),
                opacity: emprunt.etat === 'REFUSE' ? 0.7 : 1, // Réduire l'opacité pour les emprunts refusés
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                <Typography variant="caption" sx={{ fontWeight: 'bold', maxWidth: 'calc(100% - 50px)', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {`${emprunt.utilisateur.prenom} ${emprunt.utilisateur.nom}`}
                </Typography>
                <Box sx={{ display: 'flex', flexShrink: 0 }}>
                    <IconButton
                        size="small"
                        onClick={handleEdit}
                        sx={{ padding: '2px', marginRight: '2px' }}
                    >
                        <EditIcon sx={{ fontSize: '0.8rem' }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={handleDelete}
                        sx={{ padding: '2px' }}
                    >
                        <DeleteIcon sx={{ fontSize: '0.8rem' }} />
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1 }}>
                {isFirstDay && (
                    <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
                        {`Début: ${formatTime(emprunt.datePriseEnChargePrevue)}`}
                    </Typography>
                )}
                {isLastDay && (
                    <Typography variant="caption" sx={{ fontSize: '0.8rem', marginLeft: 'auto' }}>
                        {`Fin: ${formatTime(emprunt.dateRenduPrevue)}`}
                    </Typography>
                )}
            </Box>
            {isHovered && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        backgroundColor: 'white',
                        boxShadow: 3,
                        borderRadius: 1,
                        p: 2,
                        minWidth: 200,
                        pointerEvents: 'none',
                    }}
                >
                    <Typography variant="h6">Détails de l'emprunt</Typography>
                    <Typography>Places disponibles: {emprunt.nbPlaceDisponible ?? 'N/A'}</Typography>
                    <Typography>Utilisateur: {emprunt.utilisateur ? `${emprunt.utilisateur.prenom || ''} ${emprunt.utilisateur.nom || ''}`.trim() || 'N/A' : 'N/A'}</Typography>
                    <Typography>Destination: {emprunt.destination.ville ?? 'N/A'}</Typography>
                    <Typography>Prise en charge prévue: {emprunt.datePriseEnChargePrevue ? emprunt.datePriseEnChargePrevue.toLocaleString() : 'N/A'}</Typography>
                    <Typography>Prise en charge effective: {emprunt.datePriseEnCharge ? emprunt.datePriseEnCharge.toLocaleString() : 'N/A'}</Typography>
                    <Typography>Rendu prévu: {emprunt.dateRenduPrevue ? emprunt.dateRenduPrevue.toLocaleString() : 'N/A'}</Typography>
                    <Typography>Rendu effectif: {emprunt.dateRendu ? emprunt.dateRendu.toLocaleString() : 'N/A'}</Typography>
                    <Typography>Lieu de prise en charge: {emprunt.lieuDePriseEnCharge?.designation || 'N/A'}</Typography>
                    <Typography>Lieu de rendu: {emprunt.lieuRendu?.designation || 'N/A'}</Typography>
                    <Typography>Km au départ: {emprunt.kmPriseEnCharge ?? 'N/A'}</Typography>
                    <Typography>Km au retour: {emprunt.kmRemise ?? 'N/A'}</Typography>
                    <Typography>État: {emprunt.etat || 'N/A'}</Typography>
                    <Typography>Motif: {emprunt.motif || 'N/A'}</Typography>
                </Box>
            )}
        </Box>
    );
};

const ETATS = ['DEMANDE', 'AFFECTE', 'VALIDE', 'REFUSE', 'TERMINE'];

const PlanningEmprunts = ({ entrepriseId, onNavigateToAddEmprunt }) => {
    const [vehicules, setVehicules] = useState([]);
    const [emprunts, setEmprunts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedVehicules, setSelectedVehicules] = useState([]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [hoveredRow, setHoveredRow] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [empruntToDelete, setEmpruntToDelete] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [deletedEmpruntInfo, setDeletedEmpruntInfo] = useState(null);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [showEmpruntDetails, setShowEmpruntDetails] = useState(false);
    const [selectedEmpruntId, setSelectedEmpruntId] = useState(null);
    const [showUpdateEmprunt, setShowUpdateEmprunt] = useState(false);
    const [selectedEmprunt, setSelectedEmprunt] = useState(null);
    const [selectedEtats, setSelectedEtats] = useState(ETATS.filter(etat => etat !== 'REFUSE'));
    const [selectedConducteurs, setSelectedConducteurs] = useState([]);
    const [conducteurs, setConducteurs] = useState([]);
    const [immatriculationFilter, setImmatriculationFilter] = useState('');
    const [filteredVehicules, setFilteredVehicules] = useState([]);
    const [filteredConducteurs, setFilteredConducteurs] = useState([]);
    const [conducteurFilter, setConducteurFilter] = useState('');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [detailedErrors, setDetailedErrors] = useState([]);
    const [globalSearchTerm, setGlobalSearchTerm] = useState('');
    const [selectedVehiculeStatuts, setSelectedVehiculeStatuts] = useState([]);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        fetchData();
    }, [entrepriseId]);

    const sortedEtats = ETATS.sort((a, b) => a.localeCompare(b));

    // Trier les conducteurs par nom et prénom
    const sortedConducteurs = [...conducteurs].sort((a, b) =>
        `${a.nom} ${a.prenom}`.localeCompare(`${b.nom} ${b.prenom}`)
    );

    // Trier les véhicules par immatriculation
    const sortedVehicules = [...vehicules].sort((a, b) =>
        a.immatriculation.localeCompare(b.immatriculation)
    );

    const handleGlobalSearchChange = (event) => {
        setGlobalSearchTerm(event.target.value.toLowerCase());
    };
    const handleVehiculeStatutChange = (event) => {
        setSelectedVehiculeStatuts(event.target.value);
    };
    const getWeekDates = (date) => {
        const start = new Date(date);
        start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
        const end = new Date(start);
        end.setDate(end.getDate() + 6);
        return { start, end };
    };

    const { start: weekStart, end: weekEnd } = getWeekDates(currentDate);

    const handleEditEmprunt = (emprunt) => {
        setSelectedEmprunt(emprunt);
        setShowUpdateEmprunt(true);
    };
    const handleShowError = (message, details = []) => {
        setErrorMessage(message);
        setDetailedErrors(details);
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        setErrorMessage('');
        setDetailedErrors([]);
    };
    const handleUpdateEmpruntClose = () => {
        setShowUpdateEmprunt(false);
        setSelectedEmprunt(null);
        fetchData(); // Rafraîchir les données après la mise à jour
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const [vehiculesData, empruntsData, conducteursData] = await Promise.all([
                VehiculeService.getVehiculesByEntrepriseId(entrepriseId),
                EmpruntService.getEmpruntByEntrepriseId(entrepriseId),
                UtilisateurService.findUtilisateursByEntrepriseId(entrepriseId)
            ]);

            setVehicules(vehiculesData);
            setEmprunts(empruntsData.map(emprunt => ({
                ...emprunt,
                datePriseEnChargePrevue: new Date(emprunt.datePriseEnChargePrevue),
                dateRenduPrevue: new Date(emprunt.dateRenduPrevue),
                datePriseEnCharge: emprunt.datePriseEnCharge ? new Date(emprunt.datePriseEnCharge) : null,
                dateRendu: emprunt.dateRendu ? new Date(emprunt.dateRendu) : null,
            })));
            setConducteurs(conducteursData);
        } catch (error) {
            console.error('Erreur lors de la récupération des données:', error);
            handleShowError("Erreur lors de la récupération des données", [error.message]);
        } finally {
            setLoading(false);
        }
    };
    const handleEtatChange = (event) => {
        setSelectedEtats(event.target.value);
    };

    const handleConducteurChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('all')) {
            setSelectedConducteurs(selectedConducteurs.length === conducteurs.length ? [] : conducteurs.map(c => c.id));
        } else {
            setSelectedConducteurs(typeof value === 'string' ? value.split(',') : value);
        }
    };

    const handleImmatriculationFilterChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setImmatriculationFilter(searchValue);
        const filtered = vehicules.filter(vehicule =>
            vehicule.immatriculation.toLowerCase().includes(searchValue)
        );
        setFilteredVehicules(filtered);
    };

    useEffect(() => {
        const filtered = vehicules.filter(vehicule =>
            (selectedVehicules.length === 0 || selectedVehicules.includes(vehicule.id)) &&
            (globalSearchTerm === '' ||
                vehicule.immatriculation.toLowerCase().includes(globalSearchTerm) ||
                emprunts.some(emprunt =>
                    emprunt.vehicule.id === vehicule.id &&
                    `${emprunt.utilisateur.nom} ${emprunt.utilisateur.prenom}`.toLowerCase().includes(globalSearchTerm)
                )
            )
        );
        setFilteredVehicules(filtered);
    }, [vehicules, emprunts, globalSearchTerm, selectedVehicules]);

    const filteredEmprunts = emprunts.filter(emprunt =>
        (selectedEtats.length === 0 || selectedEtats.includes(emprunt.etat)) &&
        (selectedConducteurs.length === 0 || selectedConducteurs.includes(emprunt.utilisateur.id)) &&
        (selectedVehicules.length === 0 || selectedVehicules.includes(emprunt.vehicule.id)) &&
        (emprunt.datePriseEnChargePrevue <= weekEnd && emprunt.dateRenduPrevue >= weekStart) &&
        (globalSearchTerm === '' ||
            emprunt.vehicule.immatriculation.toLowerCase().includes(globalSearchTerm) ||
            `${emprunt.utilisateur.nom} ${emprunt.utilisateur.prenom}`.toLowerCase().includes(globalSearchTerm))
    );
    useEffect(() => {
        const newFilteredVehicules = vehicules.filter(vehicule =>
            (selectedVehicules.length === 0 || selectedVehicules.includes(vehicule.id)) &&
            (selectedVehiculeStatuts.length === 0 || selectedVehiculeStatuts.includes(vehicule.statut)) &&
            (filteredEmprunts.some(emprunt => emprunt.vehicule.id === vehicule.id) ||
                (globalSearchTerm === '' && selectedConducteurs.length === 0) ||
                vehicule.immatriculation.toLowerCase().includes(globalSearchTerm.toLowerCase()))
        );
        setFilteredVehicules(newFilteredVehicules);
    }, [vehicules, filteredEmprunts, selectedVehicules, selectedVehiculeStatuts, globalSearchTerm, selectedConducteurs]);

    useEffect(() => {
        setFilteredVehicules(vehicules);
    }, [vehicules]);

    const handleConducteurFilterChange = (event) => {
        const searchValue = event.target.value.toLowerCase();
        setConducteurFilter(searchValue);
        const filtered = conducteurs.filter(conducteur =>
            `${conducteur.prenom} ${conducteur.nom}`.toLowerCase().includes(searchValue)
        );
        setFilteredConducteurs(filtered);
    };
    const getVehiculeBackgroundColor = (statut) => {
        switch (statut) {
            case 'INDISPONIBLE':
                return '#FFE4B5'; // Orange clair
            case 'DESACTIVE':
                return '#D3D3D3'; // Gris clair
            default:
                return 'inherit';
        }
    };

    useEffect(() => {
        setFilteredConducteurs(conducteurs);
    }, [conducteurs]);

    const changePeriod = (delta) => {
        const newDate = new Date(currentDate);
        if (isMobile) {
            newDate.setDate(newDate.getDate() + delta);
        } else {
            newDate.setDate(newDate.getDate() + (7 * delta));
        }
        setCurrentDate(newDate);
    };

    const handleVehiculeChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('all')) {
            setSelectedVehicules(selectedVehicules.length === vehicules.length ? [] : vehicules.map(v => v.id));
        } else {
            setSelectedVehicules(typeof value === 'string' ? value.split(',') : value);
        }
    };

    const handleEmpruntClick = (emprunt) => {
        setSelectedEmpruntId(emprunt.id);
        setShowEmpruntDetails(true);
    };

    const handleSortVehicules = () => {
        const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newOrder);
        const sortedVehicules = [...vehicules].sort((a, b) => {
            return newOrder === 'asc'
                ? a.immatriculation.localeCompare(b.immatriculation)
                : b.immatriculation.localeCompare(a.immatriculation);
        });
        setVehicules(sortedVehicules);
    };
    const handleConfirmDelete = async () => {
        setDeleteConfirmOpen(false);
        if (empruntToDelete) {
            try {
                const mailService = new MailService();
                await EmpruntService.deleteEmprunt(empruntToDelete.id);
                await mailService.sendDeletionEmail(empruntToDelete);
                setEmprunts(prevEmprunts => prevEmprunts.filter(e => e.id !== empruntToDelete.id));
                console.log('Emprunt supprimé avec succès:', empruntToDelete);

                setDeletedEmpruntInfo({
                    utilisateur: `${empruntToDelete.utilisateur.prenom} ${empruntToDelete.utilisateur.nom}`,
                    debut: new Date(empruntToDelete.datePriseEnChargePrevue).toLocaleString(),
                    fin: new Date(empruntToDelete.dateRenduPrevue).toLocaleString()
                });

                setSuccessModalOpen(true);

                await fetchData();
            } catch (error) {
                console.error("Erreur lors de la suppression de l'emprunt:", error);
                handleShowError("Erreur lors de la suppression de l'emprunt", [error.response?.data?.message || error.message]);
            } finally {
                setEmpruntToDelete(null);
            }
        }
    };
    useEffect(() => {
        const newFilteredVehicules = vehicules.filter(vehicule =>
            (selectedVehicules.length === 0 || selectedVehicules.includes(vehicule.id)) &&
            (filteredEmprunts.some(emprunt => emprunt.vehicule.id === vehicule.id) ||
                (globalSearchTerm === '' && selectedConducteurs.length === 0))
        );
        setFilteredVehicules(newFilteredVehicules);
    }, [vehicules, filteredEmprunts, selectedVehicules, globalSearchTerm, selectedConducteurs]);

    const handleRefresh = () => {
        fetchData();
    }
    const confirmDeleteEmprunt = async () => {
        if (empruntToDelete) {
            try {
                const response = await EmpruntService.deleteEmprunt(empruntToDelete.id);

                setEmprunts(prevEmprunts => prevEmprunts.filter(e => e.id !== empruntToDelete.id));

                await fetchData();
            } catch (error) {
                // Afficher le message d'erreur dans le Snackbar
                setSnackbarMessage(error.response?.data?.message || "Une erreur est survenue lors de la suppression de l'emprunt.");
                setSnackbarOpen(true);
            } finally {
                setDeleteDialogOpen(false);
                setEmpruntToDelete(null);
            }
        }
    };
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };
    const handleDeleteEmprunt = (emprunt) => {
        setEmpruntToDelete(emprunt);
        setDeleteConfirmOpen(true);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
        setEmpruntToDelete(null);
    };

    const handleCloseSuccessModal = () => {
        setSuccessModalOpen(false);
        setDeletedEmpruntInfo(null);
    };

    const cancelDeleteEmprunt = () => {
        setDeleteDialogOpen(false);
        setEmpruntToDelete(null);
    };

    const getDaysToDisplay = () => {
        if (isMobile) {
            return [currentDate];
        } else {
            const days = [];
            const startOfWeek = new Date(currentDate);
            startOfWeek.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));
            for (let i = 0; i < 7; i++) {
                const day = new Date(startOfWeek);
                day.setDate(startOfWeek.getDate() + i);
                days.push(day);
            }
            return days;
        }

        const handleAddClick = () => {
            if (onNavigateToAddEmprunt) {
                onNavigateToAddEmprunt();
            } else {
                console.warn("La fonction onNavigateToAddEmprunt n'est pas définie");
            }
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xl" sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Box my={4} sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box display="flex" alignItems="center" mb={2}>
                        <Typography variant="h4" component="h1" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                            Planning des emprunts
                            <IconButton
                                onClick={handleRefresh}
                                color="primary"
                                sx={{
                                    ml: 2,
                                    p: 0,
                                    '& svg': {
                                        fontSize: '1.5rem'
                                    }
                                }}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Typography>
                    </Box>
                    <Paper elevation={3} sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <Box p={2} display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                            <Box display="flex" alignItems="center" flexWrap="wrap">
                                {/* Champ de recherche global */}
                                <TextField
                                    variant="outlined"
                                    placeholder="Rechercher un conducteur ou un véhicule"
                                    value={globalSearchTerm}
                                    onChange={handleGlobalSearchChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ m: 1, width: 300 }}
                                />
                                {/* Filtre des états */}
                                <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                                    <InputLabel id="etat-filter-label">État de l'emprunt</InputLabel>
                                    <Select
                                        labelId="etat-filter-label"
                                        multiple
                                        value={selectedEtats}
                                        onChange={handleEtatChange}
                                        input={<OutlinedInput label="État de l'emprunt" />}
                                        renderValue={(selected) => `${selected.length} sélectionné(s)`}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        {sortedEtats.map((etat) => (
                                            <MenuItem key={etat} value={etat}>
                                                <Checkbox checked={selectedEtats.indexOf(etat) > -1} />
                                                <ListItemText primary={etat} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Filtre des conducteurs */}
                                <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                                    <InputLabel id="conducteur-filter-label">Conducteurs</InputLabel>
                                    <Select
                                        labelId="conducteur-filter-label"
                                        multiple
                                        value={selectedConducteurs}
                                        onChange={handleConducteurChange}
                                        input={<OutlinedInput label="Conducteurs" />}
                                        renderValue={(selected) => `${selected.length} sélectionné(s)`}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="all">
                                            <Checkbox
                                                checked={selectedConducteurs.length === conducteurs.length}
                                                indeterminate={selectedConducteurs.length > 0 && selectedConducteurs.length < conducteurs.length}
                                            />
                                            <ListItemText primary="Sélectionner tout" />
                                        </MenuItem>
                                        {sortedConducteurs.map((conducteur) => (
                                            <MenuItem key={conducteur.id} value={conducteur.id}>
                                                <Checkbox checked={selectedConducteurs.indexOf(conducteur.id) > -1} />
                                                <ListItemText primary={`${conducteur.nom} ${conducteur.prenom}`} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* Filtre des véhicules */}
                                <FormControl variant="outlined" sx={{ m: 1, width: 200 }}>
                                    <InputLabel id="vehicule-filter-label">Véhicules</InputLabel>
                                    <Select
                                        labelId="vehicule-filter-label"
                                        multiple
                                        value={selectedVehicules}
                                        onChange={handleVehiculeChange}
                                        input={<OutlinedInput label="Véhicules" />}
                                        renderValue={(selected) => `${selected.length} sélectionné(s)`}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 48 * 4.5 + 8,
                                                    width: 250,
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="all">
                                            <Checkbox
                                                checked={selectedVehicules.length === vehicules.length}
                                                indeterminate={selectedVehicules.length > 0 && selectedVehicules.length < vehicules.length}
                                            />
                                            <ListItemText primary="Sélectionner tout" />
                                        </MenuItem>
                                        {sortedVehicules.map((vehicule) => (
                                            <MenuItem key={vehicule.id} value={vehicule.id}>
                                                <Checkbox checked={selectedVehicules.indexOf(vehicule.id) > -1} />
                                                <ListItemText primary={vehicule.immatriculation} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    color="success"
                                    startIcon={<AddIcon />}
                                    sx={{ ml: 1 }}
                                    onClick={onNavigateToAddEmprunt}
                                >
                                    Ajouter
                                </Button>
                            </Box>
                            <Box>
                                <IconButton onClick={() => changePeriod(-1)}><ArrowBackIosIcon /></IconButton>
                                <Typography variant="h6" component="span" sx={{ mx: 2 }}>
                                    {isMobile
                                        ? currentDate.toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', month: 'long' })
                                        : `${getDaysToDisplay()[0].toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })} - ${getDaysToDisplay()[6].toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' })}`
                                    }
                                </Typography>
                                <IconButton onClick={() => changePeriod(1)}><ArrowForwardIosIcon /></IconButton>
                            </Box>
                        </Box>
                        {loading ? (
                            <Box display="flex" justifyContent="center" py={4}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <TableContainer
                                component={Paper}
                                sx={{
                                    height: 'calc(100vh - 200px)',
                                    overflow: 'auto',
                                    flex: 1
                                }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    backgroundColor: 'background.paper',
                                                    zIndex: 2
                                                }}
                                            >
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    Véhicule
                                                    <IconButton size="small" onClick={handleSortVehicules}>
                                                        <SortIcon />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                            {getDaysToDisplay().map((day, index) => (
                                                <TableCell
                                                    key={index}
                                                    align="center"
                                                    sx={{
                                                        verticalAlign: 'middle',
                                                        position: 'sticky',
                                                        top: 0,
                                                        backgroundColor: 'background.paper',
                                                        zIndex: 2
                                                    }}
                                                >
                                                    {day.toLocaleDateString('fr-FR', { weekday: 'short', day: 'numeric', month: 'numeric' })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredVehicules.map((vehicule) => (
                                            <TableRow
                                                key={vehicule.id}
                                                sx={{
                                                    bgcolor: hoveredRow === vehicule.id ? '#f5f5f5' : getVehiculeBackgroundColor(vehicule.statut),
                                                    '&:hover': {
                                                        bgcolor: '#f5f5f5',
                                                    }
                                                }}
                                                onMouseEnter={() => setHoveredRow(vehicule.id)}
                                                onMouseLeave={() => setHoveredRow(null)}
                                            >
                                                <TableCell>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <DirectionsCarIcon sx={{ mr: 1 }} />
                                                            <Typography>{vehicule.immatriculation}</Typography>
                                                        </Box>
                                                        <Typography variant="caption" sx={{ mt: 0.5 }}>
                                                            ({vehicule.statut})
                                                        </Typography>
                                                    </Box>
                                                </TableCell>
                                                {getDaysToDisplay().map((day, dayIndex) => {
                                                    const dayStart = new Date(day.getFullYear(), day.getMonth(), day.getDate());
                                                    const dayEnd = new Date(dayStart);
                                                    dayEnd.setDate(dayEnd.getDate() + 1);

                                                    const empruntsForDay = filteredEmprunts.filter(e =>
                                                        e.vehicule.id === vehicule.id &&
                                                        e.datePriseEnChargePrevue < dayEnd &&
                                                        e.dateRenduPrevue > dayStart
                                                    );

                                                    return (
                                                        <TableCell key={dayIndex} sx={{ position: 'relative', height: '100px', minWidth: '150px', padding: '4px' }}>
                                                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
                                                                {empruntsForDay.map((emprunt) => (
                                                                    <EmpruntItem
                                                                        key={emprunt.id}
                                                                        emprunt={emprunt}
                                                                        dayStart={dayStart}
                                                                        dayEnd={dayEnd}
                                                                        isFirstDay={emprunt.datePriseEnChargePrevue >= dayStart && emprunt.datePriseEnChargePrevue < dayEnd}
                                                                        isLastDay={emprunt.dateRenduPrevue >= dayStart && emprunt.dateRenduPrevue < dayEnd}
                                                                        onEdit={handleEditEmprunt}
                                                                        onDelete={handleDeleteEmprunt}
                                                                        onClick={handleEmpruntClick}
                                                                    />
                                                                ))}
                                                            </Box>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Paper>
                </Box>

                <Dialog
                    open={successModalOpen}
                    onClose={handleCloseSuccessModal}
                    aria-labelledby="success-dialog-title"
                    aria-describedby="success-dialog-description"
                >
                    <DialogTitle id="success-dialog-title">
                        {"Suppression réussie"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="success-dialog-description">
                            L'emprunt a été supprimé avec succès.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSuccessModal} color="primary" autoFocus>
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deleteConfirmOpen}
                    onClose={handleCancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Êtes-vous sûr de vouloir supprimer cet emprunt ?
                            {empruntToDelete && (
                                <p>
                                    Emprunt de {empruntToDelete.utilisateur.prenom} {empruntToDelete.utilisateur.nom}
                                    <br />
                                    Du : {new Date(empruntToDelete.datePriseEnChargePrevue).toLocaleString()}
                                    <br />
                                    Au : {new Date(empruntToDelete.dateRenduPrevue).toLocaleString()}
                                </p>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
                {showEmpruntDetails && (
                    <Dialog
                        open={showEmpruntDetails}
                        onClose={() => setShowEmpruntDetails(false)}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>Détails de l'emprunt</DialogTitle>
                        <DialogContent>
                            <EmpruntDetails
                                empruntId={selectedEmpruntId}
                                onClose={() => setShowEmpruntDetails(false)}
                            />
                        </DialogContent>
                    </Dialog>
                )}

                {showUpdateEmprunt && selectedEmprunt && (
                    <Dialog
                        open={showUpdateEmprunt}
                        onClose={handleUpdateEmpruntClose}
                        fullWidth
                        maxWidth="md"
                    >
                        <DialogTitle>Modifier l'emprunt</DialogTitle>
                        <DialogContent>
                            <UpdateEmprunt
                                entrepriseId={entrepriseId}
                                empruntId={selectedEmprunt.id}
                                onEmpruntUpdated={handleUpdateEmpruntClose}
                            />
                        </DialogContent>
                    </Dialog>
                )}
            </Container>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80%',
                        maxHeight: '80%',
                        overflow: 'auto',
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main, mb: 2 }}>
                        {errorMessage}
                    </Typography>
                    {detailedErrors.length > 0 && (
                        <List>
                            {detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}
export default PlanningEmprunts