// ListeSites.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteService from '../../../controleurs/services/Site';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { blue, red, green } from '@mui/material/colors';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp, Add as AddIcon  } from '@mui/icons-material';
import { Search as SearchIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Button,
    TablePagination,
    InputAdornment,
    Modal
} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const SortableTableCell = styled(TableCell)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTableSortLabel-root': {
        display: 'inline-flex',
        alignItems: 'center',
    },
    '& .MuiTableSortLabel-icon': {
        marginLeft: theme.spacing(1),
    },
    color: 'white', // Ajout de cette ligne pour rendre le texte blanc
}));

const SortArrowIcon = ({ direction }) => {
    return (
        <Box
            component={direction === 'asc' ? KeyboardArrowUp : KeyboardArrowDown}
            sx={{
                ml: 1,
                width: 16,
                height: 16,
                flexShrink: 0,
            }}
        />
    );
};

function ListeSites({ entrepriseId, onSiteClick, onSiteUpdate, onAddSite}) {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('asc');
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [showResetIcon, setShowResetIcon] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        SiteService.getSitesByEntrepriseId(entrepriseId)
            .then((data) => {
                setSites(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching sites:', error);
                setLoading(false);
            });
    }, [entrepriseId]);

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleAddClick = () => {
        if (onAddSite) {
            onAddSite();
        } else {
            console.warn("La fonction onAddSite n'est pas définie");
        }
    };

    const handleDeleteClick = (event, siteId) => {
        event.stopPropagation();

        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce site ?')) {
            SiteService.deleteSite(siteId)
                .then(() => {
                    setSites(sites.filter((site) => site.id !== siteId));
                })
                .catch((error) => {
                    console.error('Erreur lors de la suppression du site :', error);
                    setErrors({ ...errors, serverError: error.message });
                    setOpenErrorModal(true);
                });
        }
    };

    const handleSort = (column) => {
        if (orderBy === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setOrder('asc');
        }
    };

    const sortedSites = (searchResults.length > 0 ? searchResults : sites).sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        if (orderBy === 'adresse.rue') {
            aValue = a.adresse.rue;
            bValue = b.adresse.rue;
        } else if (orderBy === 'utilisateur.nom') {
            aValue = a.utilisateur ? `${a.utilisateur.prenom} ${a.utilisateur.nom}` : '';
            bValue = b.utilisateur ? `${b.utilisateur.prenom} ${b.utilisateur.nom}` : '';
        }

        if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleEditClick = (event, siteId) => {
        event.stopPropagation();
        onSiteUpdate(siteId);
    };
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchCriteria(searchValue);
        setShowResetIcon(searchValue.trim() !== '');
        if (searchValue.trim() !== '') {
            SiteService.searchSites(searchValue)
                .then((data) => {
                    setSearchResults(data);
                    setPage(0);
                })
                .catch((error) => {
                    console.error('Erreur lors de la recherche de sites :', error);
                });
        } else {
            setSearchResults([]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedSites = searchResults.length > 0 ? searchResults : sortedSites;
    const displayedSites = sortedSites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleOpenModal = (site) => {
        setSelectedSite(site);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Liste des sites
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                        <TextField
                            label="Rechercher"
                            value={searchCriteria}
                            onChange={handleSearch}
                            variant="outlined"
                            size="small"
                            sx={{ width: 400 }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showResetIcon && (
                                            <IconButton
                                                aria-label="reset"
                                                onClick={() => {
                                                    setSearchCriteria('');
                                                    setSearchResults([]);
                                                    setShowResetIcon(false);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            aria-label="search"
                                            edge="end"
                                            sx={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            sx={{ ml: 1 }}
                            onClick={handleAddClick}
                        >
                            Ajouter
                        </Button>
                    </Box>
                    <Paper elevation={3}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" py={4}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                                                <SortableTableCell align="center" onClick={() => handleSort('id')}>
                                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        ID
                                                        {orderBy === 'id' && <SortArrowIcon direction={order} />}
                                                    </Box>
                                                </SortableTableCell>
                                                <SortableTableCell align="center" onClick={() => handleSort('designation')}>
                                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        Désignation
                                                        {orderBy === 'designation' && <SortArrowIcon direction={order} />}
                                                    </Box>
                                                </SortableTableCell>
                                                {!isMobile && (
                                                    <>
                                                        <SortableTableCell align="center" onClick={() => handleSort('adresse.rue')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Adresse
                                                                {orderBy === 'adresse.rue' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('actif')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Actif
                                                                {orderBy === 'actif' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('utilisateur.nom')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Gestionnaire
                                                                {orderBy === 'utilisateur.nom' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                    </>
                                                )}
                                                <TableCell align="center" sx={{ color: 'white' }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayedSites.map((site) => (
                                                <StyledTableRow key={site.id}>
                                                    <TableCell align="center">
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                bgcolor: theme.palette.primary.main,
                                                                color: 'white',
                                                                width: 40,
                                                                height: 40,
                                                                borderRadius: '50%',
                                                            }}
                                                        >
                                                <Typography variant="body2">{site.id}</Typography>
                                            </Box>
                                        </TableCell>
                                                    <TableCell align="center">{site.designation}</TableCell>
                                                    {!isMobile && (
                                                        <>
                                                            <TableCell align="center">
                                                                {site.adresse.numero} {site.adresse.rue}, {site.adresse.codePostal} {site.adresse.ville}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {site.actif ? 'Oui' : 'Non'}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {site.utilisateur ? `${site.utilisateur.prenom} ${site.utilisateur.nom}` : '-'}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    <TableCell align="center">
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => handleEditClick(event, site.id)}
                                                                sx={{ marginRight: '0px' }}
                                                            >
                                                                <EditIcon style={{ color: blue[500] }} />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => handleDeleteClick(event, site.id)}
                                                                sx={{ marginRight: '0px' }}
                                                            >
                                                                <DeleteIcon style={{ color: red[500] }} />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleOpenModal(site);
                                                                }}
                                                            >
                                                                <InfoIcon style={{ color: blue[500] }} />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={paginatedSites.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Paper>
                </Box>
            </Container>
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                aria-labelledby="site-modal-title"
                aria-describedby="site-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    {selectedSite && (
                        <>
                            <Typography variant="h6" id="site-modal-title" gutterBottom>
                                Informations supplémentaires
                            </Typography>
                            <Typography id="site-modal-description">
                                <strong>Horaires d'ouverture :</strong> {selectedSite.horaireOuverture}
                            </Typography>
                            <Typography id="site-modal-description">
                                <strong>Procédure :</strong>
                                <Box
                                    sx={{
                                        maxHeight: '200px', // Ajustez la hauteur maximale selon vos besoins
                                        overflow: 'auto', // Ajoute une barre de défilement si nécessaire
                                        marginTop: '4px', // Ajoute un espace entre le titre et le contenu
                                    }}
                                >
                                    {selectedSite.procedure}
                                </Box>
                            </Typography>
                            <Typography id="site-modal-description">
                                <strong>Complément d'information :</strong> {selectedSite.complementInformation}
                            </Typography>
                            <Typography id="site-modal-description">
                                <strong>Contact gestionnaire :</strong> {selectedSite.utilisateur ? `${selectedSite.utilisateur.email} ${selectedSite.utilisateur.telephone}` : '-'}
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la suppression du site :
                    </Typography>
                    <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
                        {errors.serverError}
                    </Typography>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default ListeSites;