import React, { useEffect, useState } from 'react';
import EmpruntService from '../../../controleurs/services/Emprunt';
import VehiculeService from '../../../controleurs/services/Vehicule';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import SiteService from '../../../controleurs/services/Site';
import Emprunt from '../../../models/types/Emprunt';
import Adresse from '../../../models/types/Adresse';
import axios from 'axios';
import MailService from '../../../controleurs/services/Mail';

import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
    Typography,
    Autocomplete,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        cancel: {
            main: '#e0e0e0',
        },
        success: {
            main: '#8bc34a',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575',
        },
    },
});

const ETATS = ['AFFECTE', 'VALIDE', 'DEMANDE', 'TERMINE', 'REFUSE'];

function AddEmprunt({ entrepriseId, onEmpruntAdded }) {
    const [empruntData, setEmpruntData] = useState({
        id: null,
        vehicule: null,
        entreprise: { id: entrepriseId },
        nbPlaceDisponible: null,
        utilisateur: null,
        destination: null,
        datePriseEnChargePrevue: null,
        datePriseEnCharge: null,
        dateRenduPrevue: null,
        dateRendu: null,
        lieuDePriseEnCharge: null,
        lieuRendu: null,
        kmPriseEnCharge: null,
        kmRemise: null,
        etat: 'DEMANDE',
        motif: ''
    });
    const [errors, setErrors] = useState({});
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [vehicules, setVehicules] = useState([]);
    const [conducteurs, setConducteurs] = useState([]);
    const [sites, setSites] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [passagers, setPassagers] = useState([]);
    const [allUtilisateurs, setAllUtilisateurs] = useState([]);
    const [placesDisponibles, setPlacesDisponibles] = useState(0);
    const [filteredVehicules, setFilteredVehicules] = useState([]);
    const [filteredConducteurs, setFilteredConducteurs] = useState([]);

    useEffect(() => {
        VehiculeService.getVehiculesByEntrepriseId(entrepriseId)
            .then(data => {
                const sortedVehicules = data.sort((a, b) => 
                    `${a.marque} ${a.modele}`.localeCompare(`${b.marque} ${b.modele}`)
                );
                setVehicules(sortedVehicules);
                setFilteredVehicules(sortedVehicules);
            })
            .catch(error => console.error('Erreur lors de la récupération des véhicules :', error));

        UtilisateurService.findUtilisateursByEntrepriseId(entrepriseId)
            .then(data => {
                const sortedConducteurs = data.sort((a, b) => a.nom.localeCompare(b.nom));
                setConducteurs(sortedConducteurs);
                setFilteredConducteurs(sortedConducteurs);
                setAllUtilisateurs(data);
            })
            .catch(error => console.error('Erreur lors de la récupération des utilisateurs :', error));

        SiteService.getSitesByEntrepriseId(entrepriseId)
            .then(data => setSites(data))
            .catch(error => console.error('Erreur lors de la récupération des sites :', error));
    }, [entrepriseId]);

    useEffect(() => {
        validateForm();
    }, [empruntData, errors]);

    useEffect(() => {
        if (empruntData.vehicule) {
            const totalPlaces = empruntData.vehicule.nbPlace;
            const placesPrises = 1 + passagers.length; // 1 pour le conducteur
            setPlacesDisponibles(Math.max(0, totalPlaces - placesPrises));
        }
    }, [empruntData.vehicule, passagers]);

    useEffect(() => {
        if (empruntData.vehicule) {
            const totalPlaces = empruntData.vehicule.nbPlace;
            const placesPrises = 1 + passagers.length; // 1 pour le conducteur
            const placesDisponibles = Math.max(0, totalPlaces - placesPrises);

            setEmpruntData(prevState => ({
                ...prevState,
                nbPlaceDisponible: placesDisponibles
            }));
        }
    }, [empruntData.vehicule, passagers]);

    const validateForm = () => {
        const requiredFields = ['vehicule', 'utilisateur', 'destination', 'datePriseEnChargePrevue', 'dateRenduPrevue', 'lieuDePriseEnCharge', 'lieuRendu'];
        const isValid = requiredFields.every(field => empruntData[field] && !errors[field]);
        setIsFormValid(isValid);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmpruntData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
    };

    const handleDateChange = (name, value) => {
        setEmpruntData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
    };

    const handleVehiculeChange = (e) => {
        const selectedVehicule = vehicules.find(v => v.id === e.target.value);
        setEmpruntData(prevState => ({
            ...prevState,
            vehicule: selectedVehicule,
            nbPlaceDisponible: selectedVehicule ? selectedVehicule.nbPlace - 1 : null // Soustraire le conducteur
        }));
        setPassagers([]); // Réinitialiser les passagers lors du changement de véhicule
    };

    const validateField = (name, value) => {
        let error = '';
        if (!value) {
            error = 'Ce champ est requis';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSearchAddressChange = async (event, value) => {
        setSearchAddress(value);

        if (value.length >= 3) {
            try {
                const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(value)}&limit=5`);
                setAddressSuggestions(response.data.features);
            } catch (error) {
                console.error('Erreur lors de la recherche d\'adresse :', error);
            }
        } else {
            setAddressSuggestions([]);
        }
    };

    const handleSuggestionClick = (event, value) => {
        if (value) {
            const { properties, geometry } = value;

            const newAdresse = new Adresse(
                null,
                properties.housenumber || '',
                properties.street || '',
                '',
                properties.postcode || '',
                properties.city || '',
                geometry.coordinates[1],
                geometry.coordinates[0]
            );

            setEmpruntData(prevState => ({
                ...prevState,
                destination: newAdresse,
            }));

            setAddressSuggestions([]);
            setSearchAddress(properties.label);
            validateField('destination', newAdresse);
        }
    };

    const handlePassagersChange = (event, newValue) => {
        if (empruntData.vehicule) {
            const maxPassagers = empruntData.vehicule.nbPlace - 1; // -1 pour le conducteur
            const newPassagers = newValue.slice(0, maxPassagers);
            setPassagers(newPassagers);

            const placesDisponibles = Math.max(0, empruntData.vehicule.nbPlace - 1 - newPassagers.length);
            setEmpruntData(prevState => ({
                ...prevState,
                nbPlaceDisponible: placesDisponibles
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenConfirmModal(true);
    };

    const handleConfirmSubmit = async () => {
        setOpenConfirmModal(false);
        const emprunt = new Emprunt(
            empruntData.id,
            empruntData.vehicule,
            empruntData.entreprise,
            empruntData.nbPlaceDisponible,
            empruntData.utilisateur,
            empruntData.datePriseEnChargePrevue,
            empruntData.datePriseEnCharge,
            empruntData.dateRenduPrevue,
            empruntData.dateRendu,
            empruntData.lieuDePriseEnCharge,
            empruntData.lieuRendu,
            empruntData.kmPriseEnCharge,
            empruntData.kmRemise,
            empruntData.etat,
            empruntData.motif,
            empruntData.destination
        );
        try {
            const newEmprunt = await EmpruntService.addEmprunt(emprunt);
            console.log('Emprunt ajouté avec succès:', newEmprunt);

            if (newEmprunt && newEmprunt.id) {
                for (const passager of passagers) {
                    try {
                        await UtilisateurService.addPassagerToEmprunt(passager.id, newEmprunt.id);
                    } catch (passagerError) {
                        console.error('Erreur lors de l\'ajout du passager:', passagerError);
                        setErrors(prev => ({ ...prev, passagers: passagerError.message }));
                    }
                }
                const mailService = new MailService();
                mailService.sendCreationEmail(newEmprunt);
            }

            setOpenSuccessModal(true);
            setTimeout(() => {
                onEmpruntAdded();
            }, 2000);
        } catch (error) {
            console.error('Erreur lors de l\'ajout de l\'emprunt:', error);
            setErrors({
                serverError: error.message,
                detailedErrors: error.errors
            });
            setOpenErrorModal(true);
        }
    };

    const handleCancel = () => {
        setEmpruntData({
            id: null,
            vehicule: null,
            entreprise: { id: entrepriseId },
            nbPlaceDisponible: null,
            utilisateur: null,
            datePriseEnChargePrevue: null,
            datePriseEnCharge: null,
            dateRenduPrevue: null,
            dateRendu: null,
            lieuDePriseEnCharge: null,
            lieuRendu: null,
            kmPriseEnCharge: null,
            kmRemise: null,
            etat: 'EN_ATTENTE',
            motif: ''
        });
        setErrors({});
        setPassagers([]);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        handleCancel();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleCloseConfirmModal = () => {
        setOpenConfirmModal(false);
    };

    const handleVehiculeSearch = (event, newValue) => {
        if (typeof newValue === 'string') {
            const searchTerm = newValue.toLowerCase();
            const filtered = vehicules.filter(vehicule => 
                `${vehicule.marque} ${vehicule.modele} ${vehicule.immatriculation}`.toLowerCase().includes(searchTerm)
            );
            setFilteredVehicules(filtered);
        } else if (newValue && newValue.id) {
            handleVehiculeChange({ target: { value: newValue.id } });
        }
    };

    const handleConducteurSearch = (event, newValue) => {
        if (typeof newValue === 'string') {
            const searchTerm = newValue.toLowerCase();
            const filtered = conducteurs.filter(conducteur => 
                `${conducteur.nom} ${conducteur.prenom}`.toLowerCase().includes(searchTerm)
            );
            setFilteredConducteurs(filtered);
        } else if (newValue && newValue.id) {
            handleChange({
                target: {
                    name: 'utilisateur',
                    value: newValue
                }
            });
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <Container maxWidth="md">
                    <Box my={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Ajouter un emprunt
                        </Typography>
                        <Paper elevation={3} component="form">
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options={filteredVehicules}
                                            getOptionLabel={(option) => 
                                                `${option.marque} ${option.modele} - ${option.immatriculation} (${option.nbPlace} places)`
                                            }
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Véhicule" 
                                                    placeholder="Rechercher un véhicule"
                                                />
                                            )}
                                            onInputChange={(event, newInputValue) => {
                                                handleVehiculeSearch(event, newInputValue);
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    handleVehiculeChange({ target: { value: newValue.id } });
                                                }
                                            }}
                                            value={empruntData.vehicule}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                        />
                                        {empruntData.vehicule && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    mt: 1,
                                                    color: placesDisponibles === 0 ? 'error.main' : 'text.primary'
                                                }}
                                            >
                                                Places disponibles : {placesDisponibles} / {empruntData.vehicule.nbPlace}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            options={filteredConducteurs}
                                            getOptionLabel={(option) => `${option.nom} ${option.prenom}`}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Conducteur" 
                                                    placeholder="Rechercher un conducteur"
                                                />
                                            )}
                                            onInputChange={(event, newInputValue) => {
                                                handleConducteurSearch(event, newInputValue);
                                            }}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    handleChange({
                                                        target: {
                                                            name: 'utilisateur',
                                                            value: newValue
                                                        }
                                                    });
                                                }
                                            }}
                                            value={empruntData.utilisateur}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            freeSolo
                                            options={addressSuggestions}
                                            getOptionLabel={(option) => option.properties.label}
                                            inputValue={searchAddress}
                                            onInputChange={handleSearchAddressChange}
                                            onChange={(event, value) => handleSuggestionClick(event, value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Destination"
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de prise en charge prévue"
                                            value={empruntData.datePriseEnChargePrevue}
                                            onChange={(newValue) => handleDateChange('datePriseEnChargePrevue', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth required />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de rendu prévue"
                                            value={empruntData.dateRenduPrevue}
                                            onChange={(newValue) => handleDateChange('dateRenduPrevue', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth required />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Lieu de prise en charge</InputLabel>
                                            <Select
                                                name="lieuDePriseEnCharge"
                                                value={empruntData.lieuDePriseEnCharge ? empruntData.lieuDePriseEnCharge.id : ''}
                                                onChange={(e) => handleChange({
                                                    target: {
                                                        name: 'lieuDePriseEnCharge',
                                                        value: sites.find(s => s.id === e.target.value)
                                                    }
                                                })}
                                            >
                                                {sites.map((site) => (
                                                    <MenuItem key={site.id} value={site.id}>
                                                        {site.designation}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Lieu de rendu</InputLabel>
                                            <Select
                                                name="lieuRendu"
                                                value={empruntData.lieuRendu ? empruntData.lieuRendu.id : ''}
                                                onChange={(e) => handleChange({
                                                    target: {
                                                        name: 'lieuRendu',
                                                        value: sites.find(s => s.id === e.target.value)
                                                    }
                                                })}
                                            >
                                                {sites.map((site) => (
                                                    <MenuItem key={site.id} value={site.id}>
                                                        {site.designation}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="passagers"
                                            options={allUtilisateurs.filter(u => u.id !== empruntData.utilisateur?.id)}
                                            getOptionLabel={(option) => `${option.nom} ${option.prenom}`}
                                            value={passagers}
                                            onChange={handlePassagersChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Passagers"
                                                    placeholder="Sélectionner les passagers"
                                                />
                                            )}
                                            disabled={!empruntData.vehicule}
                                        />
                                        {empruntData.vehicule && placesDisponibles === 0 && (
                                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                                Le véhicule est complet. Aucune place disponible.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="motif"
                                            label="Motif"
                                            value={empruntData.motif}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>État</InputLabel>
                                            <Select
                                                name="etat"
                                                value={empruntData.etat}
                                                onChange={handleChange}
                                            >
                                                {ETATS.map((etat) => (
                                                    <MenuItem key={etat} value={etat}>
                                                        {etat}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justifyContent="flex-start">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    disabled={!isFormValid}
                                                >
                                                    Ajouter
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Annuler
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </LocalizationProvider>
            <Modal open={openSuccessModal} onClose={handleCloseSuccessModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Emprunt ajouté avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de l'ajout de l'emprunt
                    </Typography>
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
            <Modal open={openConfirmModal} onClose={handleCloseConfirmModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: 400,
                        width: '100%',
                    }}
                >
                    <Typography variant="h6" component="h2" gutterBottom>
                        Confirmation d'ajout d'emprunt
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Êtes-vous sûr de vouloir ajouter cet emprunt ?
                    </Typography>
                    {empruntData.vehicule && empruntData.vehicule.statut === 'INDISPONIBLE' && (
                        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
                            Attention : Le véhicule sélectionné est actuellement indisponible.
                        </Typography>
                    )}
                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseConfirmModal} sx={{ mr: 2 }}>
                            Annuler
                        </Button>
                        <Button 
                            variant="contained" 
                            color="primary"
                            onClick={handleConfirmSubmit}
                        >
                            Confirmer
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default AddEmprunt;