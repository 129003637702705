class Entreprise {
    constructor(id, siret, idAdresse, nom, raisonSociale, telephone, abonnement, nbAbonnement) {
        this.id = id;
        this.siret = siret;
        this.idAdresse = idAdresse;
        this.nom = nom;
        this.raisonSociale = raisonSociale;
        this.telephone = telephone;
        this.abonnement = abonnement;
        this.nbAbonnement = nbAbonnement;
    }

    toJSON() {
        return {
            id: this.id,
            siret: this.siret,
            idAdresse: this.idAdresse,
            nom: this.nom,
            raisonSociale: this.raisonSociale,
            telephone: this.telephone,
            abonnement: this.abonnement,
            nbAbonnement: this.nbAbonnement
        };
    }
}

export default Entreprise;