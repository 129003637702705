import React, { useEffect, useState } from 'react';
import VehiculeService from '../../../controleurs/services/Vehicule';
import Vehicule from '../../../models/types/Vehicule';
import { Statut } from '../../../models/types/Statut';
import SiteService from '../../../controleurs/services/Site';
import CleService from '../../../controleurs/services/Cle';
import Cle from '../../../models/types/Cle';

import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function AddVehicule({ entreprise }) {
    const [vehicule, setVehicule] = useState(new Vehicule('', entreprise, '', '', '', '', '', '', '', '', false, null, null));
    const [errors, setErrors] = useState({});
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [sites, setSites] = useState([]);
    const [cles, setCles] = useState([]);
    const [showCleFields, setShowCleFields] = useState(true);

    const validateForm = () => {
        const requiredFields = ['immatriculation', 'marque', 'modele', 'numAssurance', 'numCarteGrise', 'nbPlace', 'statut'];
        const isValid = requiredFields.every(field => vehicule[field] && !errors[field]);
        setIsFormValid(isValid);
    };

    useEffect(() => {
        validateForm();
    }, [vehicule, errors]);

    useEffect(() => {
        if (entreprise) {
            SiteService.getSitesByEntrepriseId(entreprise.id)
                .then(data => {
                    if (Array.isArray(data)) {
                        setSites(data);
                    } else {
                        console.log("Les données renvoyées ne sont pas un tableau");
                        setSites([]);
                    }
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des sites :', error);
                    setErrors({ ...errors, serverError: error.message });
                });
        }
    }, [entreprise.id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVehicule(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
    };
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleCleChange = (index, field, value) => {
        const updatedCles = [...cles];
        updatedCles[index][field] = value;

        if (field === 'principale') {
            if (value === false) {
                const otherPrincipale = updatedCles.some((cle, i) => i !== index && cle.principale);

                if (!otherPrincipale) {
                    return;
                }
            } else {
                updatedCles.forEach((cle, i) => {
                    if (i !== index) {
                        cle.principale = false;
                    }
                });
            }
        }

        setCles(updatedCles);
    };

    const addCle = () => {
        const newCle = { information: '', principale: cles.length === 0 };
        setCles([...cles, newCle]);
        setShowCleFields(true);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'immatriculation':
            case 'marque':
            case 'modele':
            case 'entreprise':
            case 'couleur':
                if (!/^[a-zA-Z0-9]*$/.test(value)) {
                    error = 'Seuls les caractères alphanumériques sont autorisés';
                }
                break;
            case 'numAssurance':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                }
                break;
            case 'numCarteGrise':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                }
                break;
            case 'nbPlace':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                } else {
                    const nbPlace = Number(value);
                    if (nbPlace < 1 || nbPlace > 100) {
                        error = 'Le nombre de places doit être compris entre 1 et 100';
                    }
                }
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        const vehiculeInstance = new Vehicule(
            vehicule.immatriculation,
            entreprise,
            vehicule.marque,
            vehicule.modele,
            vehicule.numAssurance,
            vehicule.numCarteGrise,
            vehicule.couleur,
            vehicule.nbPlace,
            { id: vehicule.localisation },
            vehicule.statut,
            vehicule.disponible,
            vehicule.image || null
        );
        const vehiculeJSON = JSON.stringify(vehiculeInstance);
        formData.append('vehicule', vehiculeJSON);

        if (selectedFile) {
            formData.append('file', selectedFile);
        }

        VehiculeService.addVehicule(formData)
            .then(response => {
                const vehiculeId = response.id;
                const cleInstances = cles.map(cle => new Cle(null, { id: vehiculeId }, cle.principale, cle.information));
                return CleService.addCles(cleInstances);
            })
            .then(() => {
                setOpenSuccessModal(true);
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout du véhicule :', error);
                setErrors({
                    serverError: error.message,
                    detailedErrors: error.errors
                });
                setOpenErrorModal(true);
            });
    };

    const handleCancel = () => {
        setVehicule(new Vehicule('', entreprise, '', '', '', '', '', '', '', '', false, null, null));
        setErrors({});
        setShowCleFields(false);
        setCles([]);
        setSelectedFile(null);
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        setVehicule(new Vehicule('', entreprise, '', '', '', '', '', '', '', '', false, null, null));
        setErrors({});
        setCles([]);
        setShowCleFields(false);
        setSelectedFile(null);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Ajouter un véhicule
                    </Typography>
                    <Paper elevation={3} component="form" encType="multipart/form-data">
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="immatriculation"
                                        label="Immatriculation"
                                        value={vehicule.immatriculation}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 9,
                                        }}
                                    />
                                    {errors.immatriculation && <FormHelperText error>{errors.immatriculation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="marque"
                                        label="Marque"
                                        value={vehicule.marque}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.marque && <FormHelperText error>{errors.marque}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="modele"
                                        label="Modèle"
                                        value={vehicule.modele}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.modele && <FormHelperText error>{errors.modele}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="numAssurance"
                                        label="Numéro d'assurance"
                                        value={vehicule.numAssurance}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                    {errors.numAssurance && <FormHelperText error>{errors.numAssurance}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="numCarteGrise"
                                        label="Numéro de carte grise"
                                        value={vehicule.numCarteGrise}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                    {errors.numCarteGrise && <FormHelperText error>{errors.numCarteGrise}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="couleur"
                                        label="Couleur"
                                        value={vehicule.couleur}
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.couleur && <FormHelperText error>{errors.couleur}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nbPlace"
                                        label="Nombre de places"
                                        type="number"
                                        value={vehicule.nbPlace}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            min: 1,
                                            max: 100,
                                        }}
                                    />
                                    {errors.nbPlace && <FormHelperText error>{errors.nbPlace}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Localisation</InputLabel>
                                        <Select
                                            name="localisation"
                                            value={vehicule.localisation}
                                            onChange={handleChange}
                                        >
                                            {sites.map((site) => (
                                                <MenuItem key={site.id} value={site.id}>
                                                    {site.designation}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Statut</InputLabel>
                                        <Select
                                            name="statut"
                                            value={vehicule.statut}
                                            onChange={handleChange}
                                        >
                                            {Object.values(Statut).map((statut) => (
                                                <MenuItem key={statut} value={statut}>
                                                    {statut}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="disponible"
                                                checked={!!vehicule.disponible}
                                                onChange={(e) => setVehicule(prevState => ({
                                                    ...prevState,
                                                    disponible: e.target.checked
                                                }))}
                                                color="primary"
                                            />
                                        }
                                        label="Publié"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {showCleFields && (
                                        <>
                                            <Typography variant="h6">Clés</Typography>
                                            {cles.map((cle, index) => (
                                                <Grid container key={index} spacing={2}>
                                                    <Grid item xs={12} sm={6}>
                                                        <TextField
                                                            name={`cle-${index}-information`}
                                                            label="Information"
                                                            value={cle.information}
                                                            onChange={(e) => handleCleChange(index, 'information', e.target.value)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={`cle-${index}-principale`}
                                                                    checked={!!cle.principale}
                                                                    onChange={(e) => handleCleChange(index, 'principale', e.target.checked)}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label="Principale"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </>
                                    )}
                                    <Button variant="contained" color="primary" onClick={addCle}>
                                        Ajouter une clé
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        name="imageFile"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={!isFormValid}
                                            >
                                                Ajouter
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={handleCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={openSuccessModal} onClose={handleCloseSuccessModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Véhicule ajouté avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de l'ajout du véhicule
                    </Typography>
                    <Typography variant="body1">{errors.serverError}</Typography>
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default AddVehicule;