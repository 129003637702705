import React, { useState, useEffect,useCallback } from 'react';
import SiteService from '../../../controleurs/services/Site';
import Site from '../../../models/types/Site';
import EntrepriseService from '../../../controleurs/services/Entreprise';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import Adresse from '../../../models/types/Adresse';
import axios from 'axios';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Modal,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Utilisateur from '../../../models/types/Utilisateur';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function AddSite({ entreprise, onClose, handleToggleListSites }) {
    const [site, setSite] = useState(new Site(null, entreprise, new Adresse(), false, '', '', '', '', null));
    const [errors, setErrors] = useState({});
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [selectedGestionnaire, setSelectedGestionnaire] = useState('');
    const [searchAddress, setSearchAddress] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);

    const [requiredFields, setRequiredFields] = useState({
        designation: false,
        adresse: false,
        horaireOuverture: false,
        procedure: false,
    });

    useEffect(() => {
        const fetchUtilisateurs = async () => {
            try {
                console.log(entreprise)
                const data = await UtilisateurService.findUtilisateursByEntrepriseId(entreprise.id);
                setUtilisateurs(data.filter(user => user.gestionnaire === true));
            } catch (error) {
                console.error('Erreur lors de la récupération des utilisateurs : ', error);
                setErrors({ ...errors, serverError: error.message });
                setOpenErrorModal(true);
            }
        };

        fetchUtilisateurs();
    }, [entreprise.id]);

    useEffect(() => {
        validateForm();
    }, [site, requiredFields]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSite(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
        setRequiredFields(prevState => ({
            ...prevState,
            [name]: value.trim() !== '',
        }));
        validateForm();
    };

    const validateForm = useCallback(() => {
        const isValid =
            requiredFields.designation &&
            requiredFields.horaireOuverture &&
            requiredFields.procedure &&
            requiredFields.adresse &&
            site.adresse.rue &&
            site.adresse.numero &&
            site.adresse.codePostal &&
            site.adresse.ville &&
            !errors.serverError && // Ajout de cette condition
            (!errors.detailedErrors || errors.detailedErrors.length === 0); // Ajout de cette condition
        setIsFormValid(isValid);
    }, [site, requiredFields, errors]);

    useEffect(() => {
        validateForm();
    }, [site, requiredFields, errors, validateForm]);

    const hasErrors = () => {
        return Object.values(errors).some(error => error !== '');
    };

    const validateField = (name, value) => {
        let error = '';
        if (value.trim() === '') {
            // Si le champ est vide, on ne définit pas d'erreur
            error = '';
        } else {
            switch (name) {
                case 'designation':
                case 'horaireOuverture':
                case 'procedure':
                case 'complementInformation':
                    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s:,'-]+$/.test(value)) {
                        error = 'Seuls les caractères alphanumériques, les espaces, les virgules, les apostrophes et les tirets sont autorisés';
                    }
                    break;
                case 'numero':
                case 'rue':
                case 'ville':
                    if (!/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s'-]+$/.test(value)) {
                        error = 'Seuls les caractères alphanumériques, les espaces, les apostrophes et les tirets sont autorisés';
                    }
                    break;
                case 'codePostal':
                    if (!/^[0-9]{5}$/.test(value)) {
                        error = 'Le code postal doit contenir exactement 5 chiffres';
                    }
                    break;
                default:
                    break;
            }
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const adresseInstance = new Adresse(
            null,
            site.adresse.numero,
            site.adresse.rue,
            site.adresse.complement,
            site.adresse.codePostal,
            site.adresse.ville,
            site.adresse.x,
            site.adresse.y
        );
        const utilisateurInstance = new Utilisateur(
            selectedGestionnaire ? selectedGestionnaire : null
        )
        const siteInstance = new Site(
            null,
            entreprise,
            adresseInstance,
            site.actif,
            site.horaireOuverture,
            site.procedure,
            site.complementInformation,
            site.designation,
            utilisateurInstance
        );
        SiteService.addSites([siteInstance])
            .then(response => {
                setShowSuccessModal(true);
                onClose();
                handleToggleListSites();
            })
            .catch(error => {
                console.error('Erreur lors de l\'ajout du site :', error);
                setErrors(prevErrors => ({
                    ...prevErrors,
                    serverError: error.message,
                    detailedErrors: error.errors
                }));
                setOpenErrorModal(true);
            });
    };

    const handleCancel = () => {
        onClose();
        handleToggleListSites();
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        onClose();
        handleToggleListSites();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        // Réinitialiser les erreurs serveur
        setErrors(prevErrors => {
            const { serverError, detailedErrors, ...fieldErrors } = prevErrors;
            return fieldErrors;
        });
    };
    const handleAdresseChange = (field, value) => {
        setSite(prevState => ({
            ...prevState,
            adresse: {
                ...prevState.adresse,
                [field]: value,
            },
        }));
        validateField(field, value);
        setRequiredFields(prevState => ({
            ...prevState,
            adresse: field === 'rue' || field === 'numero' || field === 'codePostal' || field === 'ville'
                ? value.trim() !== ''
                : prevState.adresse,
        }));
        validateForm();
    };
    const handleSearchAddressChange = async (event, value) => {
        setSearchAddress(value);

        if (value.length >= 3) {
            try {
                const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(value)}&limit=5`);
                setAddressSuggestions(response.data.features);
            } catch (error) {
                console.error('Erreur lors de la recherche d\'adresse :', error);
            }
        } else {
            setAddressSuggestions([]);
        }
    };
    const handleSuggestionClick = (event, value) => {
        if (value) {
            const { properties, geometry } = value;

            // Créer un nouvel objet adresse à partir des données sélectionnées
            const newAdresse = {
                numero: properties.housenumber || '',
                rue: properties.street || '',
                complement: '',
                codePostal: properties.postcode || '',
                ville: properties.city || '',
                x: geometry.coordinates[1],
                y: geometry.coordinates[0]
            };

            console.log(newAdresse);

            // Mettre à jour l'état du site en incluant la nouvelle adresse
            setSite(prevState => ({
                ...prevState,
                adresse: newAdresse,
            }));
            console.log(site);

            // Vider les suggestions d'adresse
            setAddressSuggestions([]);

            // Réinitialiser le champ de recherche d'adresse avec la valeur sélectionnée
            setSearchAddress(properties.label);

            setRequiredFields(prevState => ({
                ...prevState,
                adresse: true,
            }));
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Ajouter un site
                    </Typography>
                    <Paper elevation={3} component="form">
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="designation"
                                        label="Désignation"
                                        value={site.designation}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                    {errors.designation && <FormHelperText error>{errors.designation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Adresse
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                freeSolo
                                                options={addressSuggestions}
                                                getOptionLabel={(option) => option.properties.label}
                                                inputValue={searchAddress}
                                                onInputChange={handleSearchAddressChange}
                                                onChange={(event, value) => handleSuggestionClick(event, value)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Rechercher une adresse"
                                                        fullWidth
                                                        required
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>
                                        Autres informations
                                    </Typography>
                                    <TextField
                                        name="horaireOuverture"
                                        label="Horaires d'ouverture"
                                        value={site.horaireOuverture}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    {errors.horaireOuverture && <FormHelperText error>{errors.horaireOuverture}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="procedure"
                                        label="Procédure"
                                        value={site.procedure}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                    />
                                    {errors.procedure && <FormHelperText error>{errors.procedure}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="complementInformation"
                                        label="Complément d'information"
                                        value={site.complementInformation}
                                        onChange={handleChange}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        inputProps={{
                                            maxLength: 500,
                                        }}
                                    />
                                    {errors.complementInformation && <FormHelperText error>{errors.complementInformation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Gestionnaire</InputLabel>
                                        <Select
                                            value={selectedGestionnaire}
                                            onChange={(e) => {
                                                setSelectedGestionnaire(e.target.value);
                                                validateForm();
                                            }}
                                            label="Gestionnaire"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value="">Sélectionnez un gestionnaire</MenuItem>
                                            {utilisateurs.map((utilisateur) => (
                                                <MenuItem key={utilisateur.id} value={utilisateur.id}>
                                                    {`${utilisateur.nom} ${utilisateur.prenom}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="actif"
                                                checked={site.actif}
                                                onChange={(e) => {
                                                    setSite(prevState => ({
                                                        ...prevState,
                                                        actif: e.target.checked
                                                    }));
                                                    validateForm();
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Actif"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={!isFormValid || hasErrors()}
                                            >
                                                Ajouter
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={handleCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={showSuccessModal} onClose={() => { setShowSuccessModal(false); onClose(); handleToggleListSites(); }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Site ajouté avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80%',
                        maxHeight: '80%',
                        overflow: 'auto',
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de l'ajout du site :
                    </Typography>
                    {errors.serverError && (
                        <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
                            {errors.serverError}
                        </Typography>
                    )}
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} sx={{ color: theme.palette.error.main }} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default AddSite;