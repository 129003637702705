class Adresse {
    constructor(id, numero, rue, complement, codePostal, ville, x, y) {
        this.id = id;
        this.numero = numero;
        this.rue = rue;
        this.complement = complement;
        this.codePostal = codePostal;
        this.ville = ville;
        this.x = x;
        this.y = y;
    }

    toJSON() {
        return {
            id: this.id,
            numero: this.numero,
            rue: this.rue,
            complement: this.complement,
            codePostal: this.codePostal,
            ville: this.ville,
            x: this.x,
            y: this.y
        };
    }
}

export default Adresse;