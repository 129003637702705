import React, { useState, useEffect } from 'react';
import EntrepriseService from '../../../controleurs/services/Entreprise';
import Entreprise from '../../../models/types/Entreprise';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Box,
    Paper,
    List,
    ListItem,
    ListItemText,
    Modal,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

function UpdateEntreprise({ entrepriseId, onCancel, onSuccess }) {
    const [entreprise, setEntreprise] = useState(null);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    useEffect(() => {
        EntrepriseService.getEntrepriseById(entrepriseId)
            .then(data => {
                setEntreprise(data);
            })
            .catch(error => {
                console.error('Erreur lors du chargement de l\'entreprise :', error);
                setErrors({ ...errors, serverError: 'Erreur lors du chargement de l\'entreprise' });
            });
    }, [entrepriseId]);

    useEffect(() => {
        if (entreprise) {
            validateForm();
        }
    }, [entreprise]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEntreprise(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'nom':
            case 'raisonSociale':
                if (value.trim() === '') {
                    error = 'Ce champ est obligatoire';
                } else if (value.length > 100) {
                    error = 'Ne doit pas dépasser 100 caractères';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    error = 'Ne doit pas comporter de caractères spéciaux';
                }
                break;
            case 'siret':
                if (!/^\d{14}$/.test(value)) {
                    error = 'Le numéro SIRET doit contenir 14 chiffres';
                }
                break;
            case 'telephone':
                if (!/^\d{10}$/.test(value)) {
                    error = 'Le numéro de téléphone doit contenir 10 chiffres';
                }
                break;
            case 'abonnement':
                if (value.trim() === '') {
                    error = 'Ce champ est obligatoire';
                } else if (value.length > 15) {
                    error = 'Ne doit pas dépasser 15 caractères';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    error = 'Ne doit pas comporter de caractères spéciaux';
                }
                break;
            case 'nbAbonnement':
                if (isNaN(value) || value < 1 || value > 10000) {
                    error = 'Doit être un nombre entre 1 et 10000';
                }
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = () => {
        const isValid = 
            entreprise.nom.trim() !== '' &&
            entreprise.raisonSociale.trim() !== '' &&
            entreprise.siret.trim() !== '' &&
            entreprise.telephone.trim() !== '' &&
            entreprise.abonnement.trim() !== '' &&
            entreprise.nbAbonnement !== '' &&
            Object.values(errors).every(error => error === '');
        setIsFormValid(isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const entrepriseInstance = new Entreprise(
            entreprise.id,
            entreprise.siret,
            entreprise.idAdresse,
            entreprise.nom,
            entreprise.raisonSociale,
            entreprise.telephone,
            entreprise.abonnement,
            entreprise.nbAbonnement
        );

        EntrepriseService.updateEntreprise(entrepriseInstance)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                console.error('Erreur lors de la modification de l\'entreprise : ', error);
                setErrors({
                    serverError: error.message,
                    detailedErrors: error.errors
                });
                setOpenErrorModal(true);
            });
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    if (!entreprise) {
        return <Typography>Chargement...</Typography>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Modifier l'entreprise
                    </Typography>
                    <Paper elevation={3} component="form" onSubmit={handleSubmit}>
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nom"
                                        label="Nom"
                                        value={entreprise.nom}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.nom}
                                        helperText={errors.nom}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="raisonSociale"
                                        label="Raison sociale"
                                        value={entreprise.raisonSociale}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.raisonSociale}
                                        helperText={errors.raisonSociale}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="siret"
                                        label="SIRET"
                                        value={entreprise.siret}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.siret}
                                        helperText={errors.siret}
                                        inputProps={{ 
                                            maxLength: 14,
                                            pattern: "^[0-9]+$"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="telephone"
                                        label="Téléphone"
                                        value={entreprise.telephone}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.telephone}
                                        helperText={errors.telephone}
                                        inputProps={{ 
                                            maxLength: 10,
                                            pattern: "^[0-9]+$"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="abonnement"
                                        label="Abonnement"
                                        value={entreprise.abonnement}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.abonnement}
                                        helperText={errors.abonnement}
                                        inputProps={{ maxLength: 15 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nbAbonnement"
                                        label="Nombre d'abonnements"
                                        type="number"
                                        value={entreprise.nbAbonnement}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.nbAbonnement}
                                        helperText={errors.nbAbonnement}
                                        inputProps={{ 
                                            min: 1,
                                            max: 10000
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-start" mt={2}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!isFormValid}
                                            sx={{ mr: 2 }}
                                        >
                                            Modifier
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="cancel"
                                            onClick={onCancel}
                                        >
                                            Annuler
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la modification de l'entreprise
                    </Typography>
                    <Typography variant="body1">{errors.serverError}</Typography>
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default UpdateEntreprise;