import React, { useState } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Paper,
    Snackbar,
    Alert,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: email,
            });

            if (response.ok) {
                setSuccessMessage('Un e-mail de réinitialisation a été envoyé si l\'adresse existe dans notre système.');
                setEmail('');
            } else {
                const data = await response.text();
                setErrorMessage(data || 'Erreur lors de la demande de réinitialisation.');
            }
        } catch (error) {
            console.error('Erreur:', error);
            setErrorMessage('Une erreur est survenue.');
        }
    };

    const handleBackToLogin = () => {
        window.location.replace('/');
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4" gutterBottom>
                        Mot de passe oublié
                    </Typography>
                    <Paper elevation={3} sx={{ padding: 3, width: '100%' }}>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adresse e-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Envoyer
                            </Button>
                            <Button
                                fullWidth
                                variant="text"
                                onClick={handleBackToLogin}
                            >
                                Retour à la connexion
                            </Button>
                        </Box>
                    </Paper>
                </Box>
                {/* Snackbar pour les messages de succès */}
                <Snackbar
                    open={!!successMessage}
                    autoHideDuration={6000}
                    onClose={() => setSuccessMessage('')}
                >
                    <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                {/* Snackbar pour les messages d'erreur */}
                <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={() => setErrorMessage('')}
                >
                    <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default ForgotPassword;