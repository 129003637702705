export const templates = {
    create: {
        subject: 'Votre nouveau trajet avec Drivelink a été créé !',
        body: `Bonjour [nom],\n\nNous sommes ravis de vous accueillir à bord de Drivelink ! Votre trajet prévu le [date] a été créé avec succès. Nous vous souhaitons une excellente expérience avec nous.\n\nSi vous avez des questions, n'hésitez pas à nous contacter.\n\nCordialement,\nL'équipe Drivelink`,
    },
    update: {
        subject: 'Mise à jour de votre trajet avec Drivelink',
        body: `Bonjour [nom],\n\nVotre trajet a été mis à jour avec succès. Voici les nouveaux détails :\n\n[date]\n\nSi vous avez des questions ou des modifications supplémentaires à effectuer, n'hésitez pas à nous contacter.\n\nCordialement,\nL'équipe Drivelink`,
    },
    delete: {
        subject: 'Annulation de votre trajet avec Drivelink',
        body: `Bonjour [nom],\n\nNous sommes désolés de vous informer que votre trajet du [date] a été annulé. Nous comprenons que cela puisse être décevant et nous nous excusons pour tout inconvénient que cela pourrait causer.\n\nN'hésitez pas à nous contacter si vous avez des questions ou si vous souhaitez programmer un autre trajet.\n\nCordialement,\nL'équipe Drivelink`,
    },
};

export const getTemplateContent = (templateName) => {
    return templates[templateName] || { subject: '', body: '' };
};
