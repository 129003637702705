import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginService from '../../../controleurs/services/Login';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Paper,
    CircularProgress,
    Snackbar,
    Alert,
    FormControlLabel,
    Checkbox, 
    Link
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const Login = ({ setToken }) => {
    const [email, setEmail] = useState('');
    const [motDePasse, setMotDePasse] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        try {
            const response = await LoginService.newLogin({
                email,
                motDePasse
            }, rememberMe); 
            setToken(response, rememberMe);
            window.location.reload();
        } catch (err) {
            setError('Échec de la connexion. Veuillez vérifier vos identifiants.');
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = () => {
        window.location.href = '/forgot-password'
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4" gutterBottom>
                        Connexion
                    </Typography>
                    <Paper elevation={3} sx={{ padding: 3, width: '100%' }}>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adresse e-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={motDePasse}
                                onChange={(e) => setMotDePasse(e.target.value)}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={rememberMe}
                                        onChange={(e) => setRememberMe(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Rester connecté"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Se connecter'}
                            </Button>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    }}
                            >
                                <Link
                                component="button"
                                variant="body2"
                                onClick={handleForgotPassword}
                                >
                                    Mot de passe oublié ?
                                </Link>
                            </Box>
                        </Box>
                    </Paper>
                </Box>
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default Login;