import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VehiculeService from '../../../controleurs/services/Vehicule';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { blue, red } from '@mui/material/colors';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Search as SearchIcon, Add as AddIcon } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';
import { Statut, getStatutText, getStatutKey } from '../../../models/types/Statut';
import {
    Box,
    CircularProgress,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Button,
    TablePagination,
    InputAdornment,
} from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const SortableTableCell = styled(TableCell)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTableSortLabel-root': {
        display: 'inline-flex',
        alignItems: 'center',
    },
    '& .MuiTableSortLabel-icon': {
        marginLeft: theme.spacing(1),
    },
    color: 'white', // Ajout de cette ligne pour rendre le texte blanc
}));

const SortArrowIcon = ({ direction }) => {
    return (
        <Box
            component={direction === 'asc' ? KeyboardArrowUp : KeyboardArrowDown}
            sx={{
                ml: 1,
                width: 16,
                height: 16,
                flexShrink: 0,
            }}
        />
    );
};

function ListeVehicules({ entrepriseId, onVehiculeClick, onVehiculeUpdate, onAddVehicule }) {
    const [vehicules, setVehicules] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('immatriculation');
    const [order, setOrder] = useState('asc');
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [showResetIcon, setShowResetIcon] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        VehiculeService.getVehiculesByEntrepriseId(entrepriseId)
            .then((data) => {
                // Sort the data by 'immatriculation' in ascending order when it's first fetched
                const sortedData = data.sort((a, b) => a.immatriculation.localeCompare(b.immatriculation));
                setVehicules(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching vehicules:', error);
                setLoading(false);
            });
    }, [entrepriseId]);

    const StyledTableRow = styled(TableRow)(({ theme, statut }) => ({
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        ...(statut === Statut.INDISPONIBLE && {
            backgroundColor: 'rgba(255, 165, 0, 0.2)',
        }),
    }));

    const handleRowClick = (vehiculeId) => {
        onVehiculeClick(vehiculeId);
    };

    const handleDeleteClick = (event, vehiculeId) => {
        event.stopPropagation();

        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce véhicule ?')) {
            VehiculeService.deleteVehicule(vehiculeId)
                .then(() => {
                    setVehicules(vehicules.filter((vehicule) => vehicule.id !== vehiculeId));
                })
                .catch((error) => {
                    console.error('Erreur lors de la suppression du véhicule :', error);
                });
        }
    };

    const handleSort = (column) => {
        if (orderBy === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setOrder('asc');
        }
    };

    const sortedVehicules = (searchResults.length > 0 ? searchResults : vehicules).sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        if (orderBy === 'localisation.designation') {
            aValue = a.localisation.designation;
            bValue = b.localisation.designation;
        }

        if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleEditClick = (event, vehiculeId) => {
        event.stopPropagation();
        onVehiculeUpdate(vehiculeId);
    };

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchCriteria(searchValue);
        setShowResetIcon(searchValue.trim() !== '');
        if (searchValue.trim() !== '') {
            VehiculeService.searchVehicules(searchValue)
                .then((data) => {
                    setSearchResults(data);
                    setPage(0);
                })
                .catch((error) => {
                    console.error('Erreur lors de la recherche de véhicules :', error);
                });
        } else {
            setSearchResults([]);
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleAddClick = () => {
        if (onAddVehicule) {
            onAddVehicule();
        } else {
            console.warn("La fonction onAddVehicule n'est pas définie");
        }
    };

    const paginatedVehicules = searchResults.length > 0 ? searchResults : sortedVehicules;
    const displayedVehicules = sortedVehicules.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Liste des véhicules
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                    <TextField
                        label="Rechercher"
                        value={searchCriteria}
                        onChange={handleSearch}
                        variant="outlined"
                        size="small"
                        sx={{ width: 400 }}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {showResetIcon && (
                                        <IconButton
                                            aria-label="reset"
                                            onClick={() => {
                                                setSearchCriteria('');
                                                setSearchResults([]);
                                                setShowResetIcon(false);
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        aria-label="search"
                                        edge="end"
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            sx={{ ml: 1 }}
                            onClick={handleAddClick}
                        >
                            Ajouter
                        </Button>
                    </Box>
                    <Paper elevation={3}>
                        {loading ? (
                            <Box display="flex" justifyContent="center" py={4}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                                                <TableCell align="center" sx={{ color: 'white' }}></TableCell>
                                                <SortableTableCell align="center" onClick={() => handleSort('immatriculation')}>
                                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        Immatriculation
                                                        {orderBy === 'immatriculation' && <SortArrowIcon direction={order} />}
                                                    </Box>
                                                </SortableTableCell>
                                                {!isMobile && (
                                                    <>
                                                        <SortableTableCell align="center" onClick={() => handleSort('marque')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Marque
                                                                {orderBy === 'marque' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('modele')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Modèle
                                                                {orderBy === 'modele' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('couleur')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Couleur
                                                                {orderBy === 'couleur' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('nbPlace')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Nombre de places
                                                                {orderBy === 'nbPlace' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('statut')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Statut
                                                                {orderBy === 'statut' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                        <SortableTableCell align="center" onClick={() => handleSort('disponible')}>
                                                            <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                                Publié
                                                                {orderBy === 'disponible' && <SortArrowIcon direction={order} />}
                                                            </Box>
                                                        </SortableTableCell>
                                                    </>
                                                )}
                                                <SortableTableCell align="center" onClick={() => handleSort('localisation.designation')}>
                                                    <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                                                        Localisation
                                                        {orderBy === 'localisation.designation' && <SortArrowIcon direction={order} />}
                                                    </Box>
                                                </SortableTableCell>
                                                <TableCell align="center"></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayedVehicules.map((vehicule) => (
                                                <StyledTableRow
                                                    key={vehicule.id}
                                                    onClick={() => handleRowClick(vehicule.id)}
                                                    style={{ cursor: 'pointer' }}
                                                    statut={getStatutKey(vehicule.statut)}
                                                >
                                                    <TableCell align="center">
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                bgcolor: theme.palette.primary.main,
                                                                color: 'white',
                                                                width: 40,
                                                                height: 40,
                                                                borderRadius: '50%',
                                                            }}
                                                        >
                                                            <Typography variant="body2">{vehicule.id}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell align="center">{vehicule.immatriculation}</TableCell>
                                                    {!isMobile && (
                                                        <>
                                                            <TableCell align="center">{vehicule.marque}</TableCell>
                                                            <TableCell align="center">{vehicule.modele}</TableCell>
                                                            <TableCell align="center">{vehicule.couleur}</TableCell>
                                                            <TableCell align="center">{vehicule.nbPlace}</TableCell>
                                                            <TableCell align="center">{vehicule.statut}</TableCell>
                                                            <TableCell align="center">
                                                                {vehicule.disponible ? 'Oui' : 'Non'}
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    <TableCell align="center">{vehicule.localisation.designation}</TableCell>
                                                    <TableCell align="center">
                                                        <Box display="flex" justifyContent="center" alignItems="center">
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => handleEditClick(event, vehicule.id)}
                                                                sx={{ marginRight: '0px' }}
                                                            >
                                                                <EditIcon style={{ color: blue[500] }} />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={(event) => handleDeleteClick(event, vehicule.id)}
                                                            >
                                                                <DeleteIcon style={{ color: red[500] }} />
                                                            </IconButton>
                                                        </Box>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[20]}
                                    component="div"
                                    count={paginatedVehicules.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </>
                        )}
                    </Paper>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default ListeVehicules;