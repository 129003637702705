import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DirectionsCar as DirectionsCarIcon } from '@mui/icons-material';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Card,
    CardContent,
    CardActions,
    Grid,
    useMediaQuery,
    IconButton,
    Autocomplete,
    Paper,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Search as SearchIcon, Today as TodayIcon, History as HistoryIcon, Close as CloseIcon } from '@mui/icons-material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const EmpruntCard = ({ emprunt, isCurrentEmprunt, isSearchResult, onClick }) => {
    const [page, setPage] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const totalPages = 2;

    const handleNextPage = (e) => {
        e.stopPropagation(); // Empêche le déclenchement de l'événement onClick de la carte
        setPage((prevPage) => (prevPage + 1) % totalPages);
    };

    const handlePrevPage = (e) => {
        e.stopPropagation(); // Empêche le déclenchement de l'événement onClick de la carte
        setPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
    };

    return (
        <Card
            sx={{
                width: 250,
                height: 250,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.primary.main + '10',
                borderRadius: 4,
                '&:hover': {
                    '& .navigation-arrows': {
                        opacity: 1,
                    },
                },
                cursor: 'pointer',
            }}
            onClick={() => onClick(emprunt)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardContent sx={{ flexGrow: 1, position: 'relative', overflow: 'hidden', p: 0 }}>
                <Box
                    sx={{
                        display: 'flex',
                        transition: 'transform 0.3s ease-in-out',
                        transform: `translateX(-${page * 100}%)`,
                        width: '200%',
                        height: '100%',
                    }}
                >
                    <Box sx={{ width: '50%', padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                            Détails de l'emprunt
                        </Typography>
                        <Typography>Date de début: {emprunt.dateDebut}</Typography>
                        <Typography>Date de fin: {emprunt.dateFin}</Typography>
                        <Typography>Véhicule: {emprunt.vehicule}</Typography>
                        <Typography>Destination: {emprunt.destination}</Typography>
                    </Box>
                    <Box sx={{ width: '50%', padding: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={`/api/placeholder/250/250?text=${emprunt.vehicule}`}
                            alt={emprunt.vehicule}
                            style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                    </Box>
                </Box>
                <Box
                    className="navigation-arrows"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                    }}
                >
                    {page > 0 && (
                        <IconButton
                            onClick={handlePrevPage}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                width: 30,
                                height: 30,
                            }}
                        >
                            <ChevronLeft fontSize="small" />
                        </IconButton>
                    )}
                    {page < totalPages - 1 && (
                        <IconButton
                            onClick={handleNextPage}
                            sx={{
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                width: 30,
                                height: 30,
                                ml: 'auto',
                            }}
                        >
                            <ChevronRight fontSize="small" />
                        </IconButton>
                    )}
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 8,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        display: 'flex',
                        gap: 1,
                    }}
                >
                    {[...Array(totalPages)].map((_, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: 8,
                                height: 8,
                                borderRadius: '50%',
                                backgroundColor: index === page ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.2)',
                            }}
                        />
                    ))}
                </Box>
            </CardContent>
            {(isCurrentEmprunt || isSearchResult) && (
                <CardActions sx={{ justifyContent: 'center', p: 0 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                        onClick={(e) => {
                            e.stopPropagation(); // Empêche le déclenchement de l'événement onClick de la carte
   
                        }}
                    >
                        {isSearchResult ? "Embarquer" : "Modifier"}
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

const VehiculeLibreCard = ({ vehicule, onClick }) => {
    return (
      <Card 
        sx={{ 
          width: 250,
          height: 250, 
          display: 'flex', 
          flexDirection: 'column',
          backgroundColor: theme.palette.primary.main + '10',
          borderRadius: 4,
          cursor: 'pointer',
          position: 'relative', // Ajouté pour positionner l'icône
        }}
        onClick={() => onClick(vehicule)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '50%',
            padding: 1,
          }}
        >
          <DirectionsCarIcon sx={{ color: 'green', fontSize: 32 }} />
        </Box>
        <CardContent sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center', 
          textAlign: 'center',
          pt: 5, // Ajouté pour laisser de l'espace pour l'icône
        }}>
          <Typography variant="h6" gutterBottom>
            Véhicule Libre
          </Typography>
          <Typography>Modèle: {vehicule.modele}</Typography>
          <Typography>Immatriculation: {vehicule.immatriculation}</Typography>
          <Typography>Type: {vehicule.type}</Typography>
          <Typography>Site actuel: {vehicule.site}</Typography>
        </CardContent>
        <Button 
          variant="contained" 
          color="primary" 
          fullWidth
          sx={{ 
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            mt: 'auto', 
          }}
          onClick={(e) => {
            e.stopPropagation(); 
          }}
        >
          Conduire
        </Button>
      </Card>
    );
};

const AddEmpruntCard = ({ onClick }) => (
    <Card
        sx={{
            width: 250,
            height: 250,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.palette.primary.main + '10',
            borderRadius: 4,
            cursor: 'pointer',
        }}
        onClick={onClick}
    >
        <IconButton
            sx={{
                width: 80,
                height: 80,
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.dark,
                },
            }}
        >
            <AddIcon sx={{ fontSize: 40, color: 'white' }} />
        </IconButton>
        <Typography variant="h6" sx={{ mt: 2 }}>
            Ajouter un nouvel emprunt
        </Typography>
    </Card>
);

const DetailDialog = ({ open, onClose, item }) => {
    if (!item) {
        return null; // Ne rien rendre si aucun élément n'est sélectionné
    }

    const isEmprunt = 'vehicule' in item;
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Détails {item.vehicule ? "de l'emprunt" : "du véhicule"}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {item.vehicule ? (
                    // Détails de l'emprunt
                    <>
                        <Typography variant="h6">Emprunt</Typography>
                        <Typography>Véhicule: {item.vehicule}</Typography>
                        <Typography>Date de début: {item.dateDebut}</Typography>
                        <Typography>Date de fin: {item.dateFin}</Typography>
                        <Typography>Destination: {item.destination}</Typography>
                        <Typography>Site de départ: {item.fromSite}</Typography>
                        <Typography>Site d'arrivée: {item.toSite}</Typography>
                    </>
                ) : (
                    // Détails du véhicule libre
                    <>
                        <Typography variant="h6">Véhicule Libre</Typography>
                        <Typography>Modèle: {item.modele}</Typography>
                        <Typography>Immatriculation: {item.immatriculation}</Typography>
                        <Typography>Type: {item.type}</Typography>
                        <Typography>Site actuel: {item.site}</Typography>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
};


const TunnelEmprunt = ({ entrepriseId }) => {
    const [searchParams, setSearchParams] = useState({
        fromSite: null,
        toSite: null,
        startDate: null,
        endDate: null,
        destination: '',
        passengers: [],
    });
    const [emprunts, setEmprunts] = useState([]);
    const [pastEmprunts, setPastEmprunts] = useState([]);
    const [currentEmprunts, setCurrentEmprunts] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [activeView, setActiveView] = useState('all');
    const [vehiculesLibres, setVehiculesLibres] = useState([]);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [selectedItem, setSelectedItem] = useState(null);
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);

    const sites = ['Site A', 'Site B', 'Site C', 'Site D', 'Site E'];
    const passengers = ['John Doe', 'Jane Smith', 'Bob Johnson', 'Alice Brown', 'Charlie Davis'];

    useEffect(() => {
        // Simuler le chargement des données d'emprunt
        const mockCurrentEmprunts = [
            { id: 1, dateDebut: '2024-09-30', dateFin: '2024-10-02', vehicule: 'Renault Clio', destination: 'Paris', fromSite: 'Site A', toSite: 'Site B' },
            { id: 2, dateDebut: '2024-10-05', dateFin: '2024-10-07', vehicule: 'Peugeot 308', destination: 'Lyon', fromSite: 'Site B', toSite: 'Site C' },
            { id: 3, dateDebut: '2024-10-10', dateFin: '2024-10-12', vehicule: 'Citroën C3', destination: 'Marseille', fromSite: 'Site C', toSite: 'Site D' },
        ];
        setCurrentEmprunts(mockCurrentEmprunts);

        const mockPastEmprunts = [
            { id: 4, dateDebut: '2024-09-01', dateFin: '2024-09-03', vehicule: 'Renault Megane', destination: 'Nantes', fromSite: 'Site B', toSite: 'Site D' },
            { id: 5, dateDebut: '2024-09-10', dateFin: '2024-09-12', vehicule: 'Peugeot 208', destination: 'Toulouse', fromSite: 'Site C', toSite: 'Site E' },
            { id: 6, dateDebut: '2024-09-15', dateFin: '2024-09-17', vehicule: 'Citroën C4', destination: 'Nice', fromSite: 'Site D', toSite: 'Site A' },
        ];
        setPastEmprunts(mockPastEmprunts);

        setEmprunts([...mockCurrentEmprunts, ...mockPastEmprunts]);

        // Simuler le chargement des véhicules libres
        const mockVehiculesLibres = [
            { id: 7, modele: 'Renault Zoe', immatriculation: 'AB-123-CD', type: 'Électrique', site: 'Site A' },
            { id: 8, modele: 'Peugeot e-208', immatriculation: 'EF-456-GH', type: 'Électrique', site: 'Site B' },
            { id: 9, modele: 'Citroën C5 Aircross', immatriculation: 'IJ-789-KL', type: 'Hybride', site: 'Site C' },
        ];
        setVehiculesLibres(mockVehiculesLibres);
    }, [entrepriseId]);

    const handleSearch = () => {
        const filteredResults = emprunts.filter(emprunt =>
            (!searchParams.fromSite || emprunt.fromSite === searchParams.fromSite) &&
            (!searchParams.toSite || emprunt.toSite === searchParams.toSite) &&
            (!searchParams.destination || emprunt.destination.includes(searchParams.destination))
        );
        setSearchResults(filteredResults);
        setShowSearchResults(true);
        setActiveView('search');
    };

    const handleViewChange = (view) => {
        setActiveView(view);
        setShowSearchResults(false);
    };
    const handleAddNewEmprunt = () => {
        // Logique pour ajouter un nouvel emprunt
        console.log("Ajouter un nouvel emprunt");
    };

    const handleCardClick = (item) => {
        setSelectedItem(item);
        setDetailDialogOpen(true);
    };


    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container maxWidth="lg">
                    <Box my={4}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <Chip
                                icon={<TodayIcon />}
                                label="Emprunts en cours"
                                onClick={() => handleViewChange('current')}
                                color={activeView === 'current' ? 'primary' : 'default'}
                                clickable
                                sx={{ mr: 1 }}
                            />
                            <Chip
                                icon={<HistoryIcon />}
                                label="Emprunts passés"
                                onClick={() => handleViewChange('past')}
                                color={activeView === 'past' ? 'primary' : 'default'}
                                clickable
                            />
                        </Box>

                        <Paper elevation={3} sx={{ p: 3, mb: 4, borderRadius: 4 }}>
                            <Grid container spacing={2} alignItems="flex-end">
                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        options={sites}
                                        renderInput={(params) => <TextField {...params} label="Site de départ" />}
                                        value={searchParams.fromSite}
                                        onChange={(_, newValue) => setSearchParams({ ...searchParams, fromSite: newValue })}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Autocomplete
                                        options={sites}
                                        renderInput={(params) => <TextField {...params} label="Site d'arrivée" />}
                                        value={searchParams.toSite}
                                        onChange={(_, newValue) => setSearchParams({ ...searchParams, toSite: newValue })}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <DateTimePicker
                                        label="Date/heure de départ"
                                        value={searchParams.startDate}
                                        onChange={(newValue) => setSearchParams({ ...searchParams, startDate: newValue })}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <DateTimePicker
                                        label="Date/heure d'arrivée"
                                        value={searchParams.endDate}
                                        onChange={(newValue) => setSearchParams({ ...searchParams, endDate: newValue })}
                                        renderInput={(params) => <TextField {...params} fullWidth />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={2}>
                                    <TextField
                                        label="Destination"
                                        value={searchParams.destination}
                                        onChange={(e) => setSearchParams({ ...searchParams, destination: e.target.value })}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={1}>
                                    <Autocomplete
                                        multiple
                                        options={passengers}
                                        renderInput={(params) => <TextField {...params} label="Passagers" />}
                                        value={searchParams.passengers}
                                        onChange={(_, newValue) => setSearchParams({ ...searchParams, passengers: newValue })}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <IconButton
                                        color="primary"
                                        onClick={handleSearch}
                                        sx={{
                                            backgroundColor: theme.palette.primary.main,
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.dark,
                                            },
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Paper>

                        {showSearchResults ? (
                            <>
                                <Typography variant="h4" gutterBottom>
                                    Résultats de recherche
                                </Typography>
                                <Grid container spacing={3}>
                                    {searchResults.map((emprunt) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={emprunt.id}>
                                            <EmpruntCard
                                                emprunt={emprunt}
                                                isCurrentEmprunt={false}
                                                isSearchResult={true}
                                                onClick={() => handleCardClick(emprunt)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>

                                <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                                    Véhicules libres
                                </Typography>
                                <Grid container spacing={3}>
                                    {vehiculesLibres.map((vehicule) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={vehicule.id}>
                                            <VehiculeLibreCard
                                                vehicule={vehicule}
                                                onClick={() => handleCardClick(vehicule)}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </>
                        ) : (
                            <>
                                {(activeView === 'all' || activeView === 'current') && (
                                    <>
                                        <Typography variant="h4" gutterBottom>
                                            Emprunts en cours
                                        </Typography>
                                        <Grid container spacing={3} sx={{ mb: 4 }}>
                                            {currentEmprunts.map((emprunt) => (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={emprunt.id}>
                                                    <EmpruntCard emprunt={emprunt} isCurrentEmprunt={true} isSearchResult={false} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}

                                {(activeView === 'all' || activeView === 'past') && (
                                    <>
                                        <Typography variant="h4" gutterBottom>
                                            Emprunts passés
                                        </Typography>
                                        <Grid container spacing={3}>
                                            {pastEmprunts.map((emprunt) => (
                                                <Grid item xs={12} sm={6} md={4} lg={3} key={emprunt.id}>
                                                    <EmpruntCard emprunt={emprunt} isCurrentEmprunt={false} isSearchResult={false} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </Container>
                <DetailDialog
                    open={detailDialogOpen}
                    onClose={() => setDetailDialogOpen(false)}
                    item={selectedItem}
                />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default TunnelEmprunt;