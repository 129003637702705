class Vehicule {
    constructor(immatriculation, entreprise,marque, modele, numAssurance, numCarteGrise, couleur, nbPlace, localisation, statut, disponible, image, id) {
        this.immatriculation = immatriculation;
        this.entreprise = entreprise;
        this.marque = marque;
        this.modele = modele;
        this.numAssurance = numAssurance;
        this.numCarteGrise = numCarteGrise;
        this.couleur = couleur;
        this.nbPlace = nbPlace;
        this.localisation = localisation;
        this.statut = statut;
        this.disponible = disponible;
        this.image = image;
        this.id=id;
    }
    toJSON() {
        return {
            immatriculation: this.immatriculation,
            entreprise: {id: this.entreprise.id},
            marque: this.marque,
            modele: this.modele,
            numAssurance: this.numAssurance,
            numCarteGrise: this.numCarteGrise,
            couleur: this.couleur,
            nbPlace: this.nbPlace,
            localisation:  {id: this.localisation.id},
            statut: this.statut,
            disponible: this.disponible,
            image: this.image,
            id: this.id
        };
    }
}

export default Vehicule;