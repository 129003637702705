import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Paper,
    Snackbar,
    Alert,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [tokenValid, setTokenValid] = useState(true);

    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            setErrorMessage('Token manquant ou invalide.');
            setTokenValid(false);
        }
    }, [token]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setErrorMessage('Les mots de passe ne correspondent pas.');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token, newPassword }),
            });

            const data = await response.text();

            if (response.ok) {
                setSuccessMessage('Mot de passe réinitialisé avec succès.');
                setNewPassword('');
                setConfirmPassword('');
                setTimeout(() => {
                    navigate('/'); 
                }, 3000);
            } else {
                setErrorMessage(data || 'Erreur lors de la réinitialisation du mot de passe.');
            }
        } catch (error) {
            console.error('Erreur:', error);
            setErrorMessage('Une erreur est survenue.');
        }
    };

    const handleBackToLogin = () => {
        navigate('/'); 
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h4" gutterBottom>
                        Réinitialiser le mot de passe
                    </Typography>
                    <Paper elevation={3} sx={{ padding: 3, width: '100%' }}>
                        {tokenValid ? (
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="newPassword"
                                    label="Nouveau mot de passe"
                                    type="password"
                                    id="newPassword"
                                    autoComplete="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirmer le mot de passe"
                                    type="password"
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Réinitialiser
                                </Button>
                                <Button
                                    fullWidth
                                    variant="text"
                                    onClick={handleBackToLogin}
                                >
                                    Retour à la connexion
                                </Button>
                            </Box>
                        ) : (
                            <Box sx={{ mt: 1 }}>
                                <Typography variant="body1" color="error">
                                    Token manquant ou invalide. Veuillez demander une nouvelle réinitialisation.
                                </Typography>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                    onClick={() => history.push('/forgot-password')}
                                >
                                    Demander une nouvelle réinitialisation
                                </Button>
                            </Box>
                        )}
                    </Paper>
                </Box>
                {/* Snackbar pour les messages de succès */}
                <Snackbar
                    open={!!successMessage}
                    autoHideDuration={6000}
                    onClose={() => setSuccessMessage('')}
                >
                    <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
                {/* Snackbar pour les messages d'erreur */}
                <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={6000}
                    onClose={() => setErrorMessage('')}
                >
                    <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default ResetPassword;
