import React from 'react';

const About = () => {
    return (
        <div>
            <h1>À propos</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod eu lorem et ultricies. In porta lorem at dui semper porttitor. Nullam quis cursus dui. Cras tincidunt vehicula tellus eu facilisis. Donec nisi turpis, iaculis et arcu a, aliquet ultrices nisl.</p>
        </div>
    );
};

export default About;