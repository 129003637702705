import React, { useState, useEffect } from 'react';
import UtilisateurService from '../../../../controleurs/services/Utilisateur';
import Utilisateur from '../../../../models/types/Utilisateur';
import Adresse from '../../../../models/types/Adresse';
import axios from 'axios';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    Modal,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function UpdateUtilisateur({ id, onCancel, onSuccess }) {
    const [utilisateur, setUtilisateur] = useState(null);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    useEffect(() => {
        UtilisateurService.findByIdUtilisateur(id)
            .then(data => {
                setUtilisateur(data);
            })
            .catch(error => {
                console.error('Erreur lors du chargement de l\'utilisateur :', error);
                setErrors({ ...errors, serverError: 'Erreur lors du chargement de l\'utilisateur' });
            });
    }, [id]);

    useEffect(() => {
        if (utilisateur) {
            validateForm();
        }
    }, [utilisateur]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUtilisateur(prevState => {
            const newState = {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            };

            if (name === 'gestionnaire') {
                newState.idRole = checked ? 'gestionnaire' : 'utilisateur';
            }
            return newState;
        });
        validateField(name, type === 'checkbox' ? checked : value);
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'nom':
            case 'prenom':
                if (value.trim() === '') {
                    error = 'Ce champ est obligatoire';
                } else if (value.length > 50) {
                    error = 'Ne doit pas dépasser 50 caractères';
                } else if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/.test(value)) {
                    error = 'Seuls les caractères alphabétiques, les espaces et les tirets sont autorisés';
                }
                break;
            case 'email':
                if (value.trim() === '') {
                    error = 'L\'adresse e-mail est obligatoire';
                } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                    error = 'Adresse email invalide';
                } else if (value.length > 50) {
                    error = 'L\'email ne doit pas dépasser 50 caractères';
                }
                break;
            case 'telephone':
                if (value.trim() === '') {
                    error = 'Le numéro de téléphone est obligatoire';
                } else if (!/^[0-9]{10,20}$/.test(value)) {
                    error = 'Le numéro de téléphone doit contenir entre 10 et 20 chiffres';
                }
                break;
            case 'poste':
                if (value.trim() === '') {
                    error = 'Le poste est obligatoire';
                } else if (value.length > 100) {
                    error = 'Le poste ne doit pas dépasser 100 caractères';
                } else if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/.test(value)) {
                    error = 'Seuls les caractères alphabétiques, les espaces et les tirets sont autorisés';
                }
                break;
            case 'matricule':
                if (!/^\d{1,10}$/.test(value)) {
                    error = 'Le matricule doit être un nombre entier d\'au plus 10 chiffres';
                }
                break;
            case 'dateDeNaissance':
                if (value.trim() === '') {
                    error = 'La date de naissance est obligatoire';
                } else {
                    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
                    if (!dateRegex.test(value)) {
                        error = 'La date de naissance doit être au format JJ/MM/AAAA';
                    }
                }
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = () => {
        const isValid =
            utilisateur.nom.trim() !== '' &&
            utilisateur.prenom.trim() !== '' &&
            utilisateur.email.trim() !== '' &&
            utilisateur.dateDeNaissance.trim() !== '' &&
            utilisateur.poste.trim() !== '' &&
            utilisateur.telephone.trim() !== '' && 
            Object.values(errors).every(error => error === '');
        setIsFormValid(isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const utilisateurInstance = new Utilisateur(
            utilisateur.id,
            utilisateur.nom,
            utilisateur.prenom,
            utilisateur.dateDeNaissance,
            utilisateur.adresse,
            utilisateur.email,
            utilisateur.telephone,
            utilisateur.motDePasse,
            utilisateur.idEntreprise,
            utilisateur.poste,
            utilisateur.matricule,
            utilisateur.gestionnaire,
            utilisateur.idRole
        );

        UtilisateurService.updateUtilisateur(utilisateur.id, utilisateurInstance)
            .then(() => {
                setShowSuccessModal(true);
            })
            .catch(error => {
                console.error('Erreur lors de la modification de l\'utilisateur : ', error);
                if (error.message) {
                    setErrors({ serverError: error.message });
                } else if (error.errors && Array.isArray(error.errors)) {
                    setErrors({ serverErrors: error.errors });
                } else {
                    setErrors({ serverError: 'Une erreur inattendue est survenue' });
                }
                setOpenErrorModal(true);
            });
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        onSuccess();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    if (!utilisateur) {
        return <Typography>Chargement...</Typography>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Modifier l'utilisateur
                    </Typography>
                    <Paper elevation={3} component="form" onSubmit={handleSubmit}>
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nom"
                                        label="Nom"
                                        value={utilisateur.nom}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.nom}
                                        helperText={errors.nom}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="prenom"
                                        label="Prénom"
                                        value={utilisateur.prenom}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.prenom}
                                        helperText={errors.prenom}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="dateDeNaissance"
                                        label="Date de Naissance (JJ/MM/AAAA)"
                                        value={utilisateur.dateDeNaissance}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.dateDeNaissance}
                                        helperText={errors.dateDeNaissance}
                                        inputProps={{
                                            pattern: "^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={utilisateur.email}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="telephone"
                                        label="Téléphone"
                                        value={utilisateur.telephone}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.telephone}
                                        helperText={errors.telephone}
                                        inputProps={{ pattern: "^[0-9]{10,20}$" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="poste"
                                        label="Poste"
                                        value={utilisateur.poste}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.poste}
                                        helperText={errors.poste}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="matricule"
                                        label="Matricule"
                                        value={utilisateur.matricule}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.matricule}
                                        helperText={errors.matricule}
                                        inputProps={{
                                            pattern: "^\\d{1,10}$",
                                            inputMode: "numeric"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="gestionnaire"
                                                checked={utilisateur.gestionnaire}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Gestionnaire"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!isFormValid}
                                            >
                                                Modifier
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={onCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Utilisateur modifié avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxWidth: '80%',
                    maxHeight: '80%',
                    overflow: 'auto',
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la modification de l'utilisateur :
                    </Typography>
                    {errors.serverError && (
                        <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
                            {errors.serverError}
                        </Typography>
                    )}
                    {errors.serverErrors && errors.serverErrors.length > 0 && (
                        <List>
                            {errors.serverErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} sx={{ color: theme.palette.error.main }} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default UpdateUtilisateur;