class LoginService {

    newLogin = async (credentials, rememberMe = false) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Échec de la connexion');
            }

            const data = await response.json();
            const { token, refreshToken, expiresIn } = data;

            // Déterminer où stocker les tokens
            const storage = rememberMe ? localStorage : sessionStorage;
            storage.setItem('token', JSON.stringify({ token, expiresIn }));
            storage.setItem('refreshToken', refreshToken);

            return data; // Retourner les données pour une utilisation supplémentaire si nécessaire

        } catch (error) {
            console.error('Erreur lors du login:', error);
            throw error; // Relancer l'erreur pour que le composant appelant puisse la gérer
        }
    }

    register = async (formData) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Échec de l\'inscription');
            }

            const data = await response.json();
            return data; // Retourner les données de l'utilisateur inscrit

        } catch (error) {
            console.error('Erreur lors de l\'inscription:', error);
            throw error; // Relancer l'erreur pour que le composant appelant puisse la gérer
        }
    }

    logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('refreshToken');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.replace('/');
    }
}

export default new LoginService();