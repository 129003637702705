import axiosJwt from '../middlewares/axiosJwt';
import Utilisateur from '../../models/types/Utilisateur'
class UtilisateurService {

    API_URL = `${process.env.REACT_APP_API_URL}/utilisateurs`;

    getUtilisateurs = (utilisateurs,setUtilisateurs) => {
        return axiosJwt.get(this.API_URL, {
            headers: {'Accept': 'application/json'}
        })
            .then(response => response.json())
            .then(json => {
                setUtilisateurs(json._embedded.utilisateurs);
        })
            .catch(error => {
                console.error('Erreur lors de la récupération des utilisateurs : ', error);
            });
    };

    addUtilisateur = async (utilisateur) => {
        try {
            const response = await axiosJwt.post(`${this.API_URL}/add`, utilisateur);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    };

    deleteUtilisateur = async (id) => {
        try {
            const response = await axiosJwt.delete(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                throw error.response.data;  // Propager l'erreur structurée du backend
            } else {
                throw { 
                    type: "Erreur inattendue",
                    mainMessage: "Une erreur est survenue lors de la suppression de l'utilisateur",
                    errors: [error.message] 
                };
            }
        }
    };

    updateUtilisateur = async (id, utilisateur) => {
        try {
            console.log('Utilisateur à mettre à jour:', utilisateur);
            const response = await axiosJwt.put(`${this.API_URL}/${id}`, utilisateur, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('Réponse du serveur:', response.data);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    };

    findByIdUtilisateur = async (id) => {
        try {
            const response = await axiosJwt.get(`${this.API_URL}/${id}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    }

    findUtilisateursByEntrepriseId = async (id) => {
        try {
            const response = await axiosJwt.get(`${this.API_URL}/entreprise/${id}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    };

    async getPassagers(empruntId) {
        try {
            const response = await axiosJwt.get(`${this.API_URL}/passagers/${empruntId}`);
            console.log("Réponse complète des passagers:", response);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    }

    addPassagerToEmprunt = async (idUtilisateur, idEmprunt) => {
        try {
            const response = await axiosJwt.post(`${this.API_URL}/passagers?idUtilisateur=${idUtilisateur}&idEmprunt=${idEmprunt}`);
            return response.data;
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    };


    deletePassagerFromEmprunt = async (idUtilisateur, idEmprunt) => {
        try {
            const response = await axiosJwt.delete(`${this.API_URL}/passagers/${idUtilisateur}/${idEmprunt}`);
            
            // Vérifiez si la requête a réussi (status 200-299)
            if (response.status < 200 || response.status >= 300) {
                throw new Error('Erreur lors de la suppression du passager de l\'emprunt');
            }
            
            return response.data; // Retourne les données de la réponse
        } catch (error) {
            if (error.response && error.response.data) {
                console.error('Erreur détaillée:', error.response.data);
                throw error.response.data;
            } else {
                console.error('Erreur inattendue:', error);
                throw new Error('Une erreur inattendue est survenue');
            }
        }
    };
}

export default new UtilisateurService;