class Cle {
    constructor(id, vehicule, principale, information) {
        this.id = id;
        this.vehicule = vehicule;
        this.principale = principale;
        this.information = information
    }
    toJSON() {
        return {
            id: this.id,
            vehicule: this.vehicule,
            principale: this.principale,
            information: this.information
        };
    }
}

export default Cle;