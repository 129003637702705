import React, { useState, useEffect, useCallback } from 'react';
import UtilisateurService from '../../../../controleurs/services/Utilisateur';
import Utilisateur from '../../../../models/types/Utilisateur';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    Modal,
    FormHelperText,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function CreateUtilisateur({ utilisateur: utilisateurInitial, onCancel, idEntreprise, onSuccess }) {
    const initialState = {
        id: '',
        nom: '',
        prenom: '',
        dateDeNaissance: '',
        email: '',
        telephone: '',
        motDePasse: '',
        confirmMotDePasse: '',
        poste: '',
        matricule: '',
        gestionnaire: false,
        idRole: 'utilisateur'
    };
    const [utilisateur, setUtilisateur] = useState(utilisateurInitial || { ...initialState, idEntreprise });
    const [isEditMode] = useState(!!utilisateurInitial);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    useEffect(() => {
        validateForm();
    }, [utilisateur]);
    

    const clearErrorIfEmpty = (name, value) => {
        if (value.trim() === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: ''
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUtilisateur(prevState => {
            const newState = {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value
            };

            if (name === 'gestionnaire') {
                newState.idRole = checked ? 'gestionnaire' : 'utilisateur';
            }
            return newState;
        });
        if (type !== 'checkbox') {
            clearErrorIfEmpty(name, value);
        }
        validateField(name, type === 'checkbox' ? checked : value);
    };

    const resetForm = useCallback(() => {
        setUtilisateur({ ...initialState, idEntreprise });
        setErrors({});
        setIsFormValid(false);
    }, [idEntreprise]);

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'nom':
                case 'prenom':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (value.length > 50) {
                        error = 'Ne doit pas dépasser 50 caractères';
                    } else if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/.test(value)) {
                        error = 'Seuls les caractères alphabétiques, les espaces et les tirets sont autorisés';
                    }
                    break;
                case 'email':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        error = 'Adresse email invalide';
                    } else if (value.length > 50) {
                        error = 'L\'email ne doit pas dépasser 50 caractères';
                    }
                    break;
                case 'telephone':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (!/^[0-9]{10,20}$/.test(value)) {
                        error = 'Le numéro de téléphone doit contenir entre 10 et 20 chiffres';
                    }
                    break;
                case 'motDePasse':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (value.length < 8) {
                        error = 'Le mot de passe doit comporter au moins 8 caractères';
                    }
                    break;
                case 'confirmMotDePasse':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (value !== utilisateur.motDePasse) {
                        error = 'Les mots de passe ne correspondent pas';
                    }
                    break;
                case 'poste':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (value.length > 100) {
                        error = 'Le poste ne doit pas dépasser 100 caractères';
                    } else if (!/^[A-Za-zÀ-ÖØ-öø-ÿ\s-]+$/.test(value)) {
                        error = 'Seuls les caractères alphabétiques, les espaces et les tirets sont autorisés';
                    }
                    break;
                case 'matricule':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else if (!/^\d{1,10}$/.test(value)) {
                        error = 'Le matricule doit être un nombre entier d\'au plus 10 chiffres';
                    }
                    break;
                case 'dateDeNaissance':
                    if (value.trim() === '') {
                        error = '';  // Pas d'erreur si le champ est vide
                    } else {
                        const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
                        if (!dateRegex.test(value)) {
                            error = 'La date de naissance doit être au format JJ/MM/AAAA';
                        }
                    }
                    break;
                default:
                    break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const validateForm = useCallback(() => {
        const isValid =
            utilisateur.nom.trim() !== '' &&
            utilisateur.prenom.trim() !== '' &&
            utilisateur.email.trim() !== '' &&
            utilisateur.motDePasse.trim() !== '' &&
            utilisateur.motDePasse === utilisateur.confirmMotDePasse &&
            utilisateur.dateDeNaissance.trim() !== '' &&
            utilisateur.poste.trim() !== '' &&
            utilisateur.telephone.trim() !== '' && 
            Object.values(errors).every(error => error === '') &&
            !errors.serverError && // Ajout de cette condition
            (!errors.serverErrors || errors.serverErrors.length === 0); // Ajout de cette condition
        setIsFormValid(isValid);
    }, [utilisateur, errors]);

    useEffect(() => {
        validateForm();
    }, [utilisateur, errors, validateForm]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const utilisateurInstance = new Utilisateur(
            utilisateur.id,
            utilisateur.nom,
            utilisateur.prenom,
            utilisateur.dateDeNaissance,
            null, // Adresse supprimée
            utilisateur.email,
            utilisateur.telephone,
            utilisateur.motDePasse,
            { id: idEntreprise },
            utilisateur.poste,
            utilisateur.matricule,
            utilisateur.gestionnaire,
            utilisateur.idRole
        );
        if (!isEditMode) {
            UtilisateurService.addUtilisateur(utilisateurInstance)
                .then(() => {
                    setShowSuccessModal(true);
                })
                .catch(error => {
                    console.error('Erreur lors de l\'ajout de l\'utilisateur:', error);
                    if (error.message) {
                        setErrors({ serverError: error.message });
                    } else {
                        setErrors({ serverError: 'Une erreur inattendue est survenue' });
                    }
                    if (error.errors && Array.isArray(error.errors)) {
                        setErrors(prevErrors => ({ ...prevErrors, serverErrors: error.errors }));
                    }
                    setOpenErrorModal(true);
                });
        } else {
            UtilisateurService.updateUtilisateur(utilisateurInstance.id, utilisateurInstance)
                .then(() => {
                    setShowSuccessModal(true);
                })
                .catch(error => {
                    console.error('Erreur lors de la modification de l\'utilisateur : ', error);
                    setErrors({ ...errors, serverError: error.message });
                    setOpenErrorModal(true);
                });
        }
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        onSuccess();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
        // Réinitialiser les erreurs serveur
        setErrors(prevErrors => {
            const { serverError, serverErrors, ...fieldErrors } = prevErrors;
            return fieldErrors;
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        {isEditMode ? 'Modifier un utilisateur' : 'Ajouter un utilisateur'}
                    </Typography>
                    <Paper elevation={3} component="form" onSubmit={handleSubmit}>
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nom"
                                        label="Nom"
                                        value={utilisateur.nom}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.nom}
                                        helperText={errors.nom}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="prenom"
                                        label="Prénom"
                                        value={utilisateur.prenom}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.prenom}
                                        helperText={errors.prenom}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="dateDeNaissance"
                                        label="Date de Naissance (JJ/MM/AAAA)"
                                        value={utilisateur.dateDeNaissance}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.dateDeNaissance}
                                        helperText={errors.dateDeNaissance}
                                        inputProps={{
                                            pattern: "^(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        value={utilisateur.email}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        inputProps={{ maxLength: 50 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="motDePasse"
                                        label="Mot de passe"
                                        type="password"
                                        value={utilisateur.motDePasse}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.motDePasse}
                                        helperText={errors.motDePasse}
                                        inputProps={{ minLength: 8 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="confirmMotDePasse"
                                        label="Confirmer le mot de passe"
                                        type="password"
                                        value={utilisateur.confirmMotDePasse}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.confirmMotDePasse}
                                        helperText={errors.confirmMotDePasse}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="telephone"
                                        label="Téléphone"
                                        value={utilisateur.telephone}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.telephone}
                                        helperText={errors.telephone}
                                        inputProps={{ pattern: "^[0-9]{10,20}$" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="poste"
                                        label="Poste"
                                        value={utilisateur.poste}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        error={!!errors.poste}
                                        helperText={errors.poste}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="matricule"
                                        label="Matricule"
                                        value={utilisateur.matricule}
                                        onChange={handleChange}
                                        fullWidth
                                        error={!!errors.matricule}
                                        helperText={errors.matricule}
                                        inputProps={{
                                            pattern: "^\\d{1,10}$",
                                            inputMode: "numeric"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="gestionnaire"
                                                checked={utilisateur.gestionnaire}
                                                onChange={handleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Gestionnaire"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={!isFormValid}
                                            >
                                                {isEditMode ? 'Modifier' : 'Valider'}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={resetForm}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        {isEditMode ? 'Utilisateur modifié avec succès !' : 'Utilisateur ajouté avec succès !'}
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    maxWidth: '80%',
                    maxHeight: '80%',
                    overflow: 'auto',
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de l'ajout de l'utilisateur
                    </Typography>
                    {errors.serverError && (
                        <Typography variant="body1" sx={{ color: theme.palette.error.main }}>
                        </Typography>
                    )}
                    {errors.serverErrors && errors.serverErrors.length > 0 && (
                        <List>
                            {errors.serverErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} sx={{ color: theme.palette.error.main }} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default CreateUtilisateur;