class Utilisateur {
    constructor(id,nom, prenom, dateDeNaissance, adresse, email, telephone, motDePasse, idEntreprise, poste, matricule, gestionnaire, idRole) {
        this.id = id;
        this.nom = nom;
        this.prenom = prenom;
        this.dateDeNaissance = dateDeNaissance;
        this.adresse = adresse;
        this.email = email;
        this.telephone = telephone;
        this.motDePasse = motDePasse;
        this.idEntreprise = idEntreprise;
        this.poste = poste;
        this.matricule = matricule;
        this.gestionnaire = gestionnaire;
        this.idRole = idRole;
    }
    toJSON() {
        return {
            id: this.id,
            nom: this.nom,
            prenom: this.prenom,
            dateDeNaissance: this.dateDeNaissance,
            adresse: this.adresse,
            email: this.email,
            telephone: this.telephone,
            motDePasse: this.motDePasse,
            idEntreprise: this.idEntreprise,
            poste: this.poste,
            matricule: this.matricule,
            gestionnaire: this.gestionnaire,
            idRole: this.idRole
        };
    }
}

export default Utilisateur;