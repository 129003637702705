import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token') || sessionStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken, rememberMe = false) => {
    const storage = rememberMe ? localStorage : sessionStorage;

    storage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
    if (rememberMe) {
      sessionStorage.removeItem('token');
    } else {
      localStorage.removeItem('token');
    }
  };

  return {
    setToken: saveToken,
    token,
  };
}
