import React, { useEffect, useState } from 'react';
import EntrepriseService from '../../../controleurs/services/Entreprise';
import { Container, Grid, Typography, Box, Paper, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

function EntrepriseDetails({ entrepriseId, onEdit  }) {
  const [entreprise, setEntreprise] = useState(null);

  useEffect(() => {
    EntrepriseService.getEntrepriseById(entrepriseId)
      .then(data => {
        setEntreprise(data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération de l\'entreprise :', error);
      });
  }, [entrepriseId]);

  if (!entreprise) {
    return <div>Chargement...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
        <Container maxWidth="md">
            <Box my={4}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Détails de l'entreprise
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => onEdit(entrepriseId)}
                        sx={{ ml: 2 }}
                    >
                        Modifier
                    </Button>
                </Typography>
                <Paper elevation={3}>
                    <Box p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">Nom:</Typography>
                                <Typography variant="body1">{entreprise.nom}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">Raison sociale:</Typography>
                                <Typography variant="body1">{entreprise.raisonSociale}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">SIRET:</Typography>
                                <Typography variant="body1">{entreprise.siret}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">Téléphone:</Typography>
                                <Typography variant="body1">{entreprise.telephone}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">Abonnement:</Typography>
                                <Typography variant="body1">{entreprise.abonnement}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" fontWeight="bold">Nombre d'abonnements:</Typography>
                                <Typography variant="body1">{entreprise.nbAbonnement}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
        </Container>
    </ThemeProvider>
);
}

export default EntrepriseDetails;