class Passager {
    constructor(id, idUtilisateur, idEmprunt) {
        this.id = id;
        this.idUtilisateur = idUtilisateur;
        this.idEmprunt = idEmprunt;
    }

    toJSON() {
        return {
            id: this.id,
            idUtilisateur: this.idUtilisateur,
            idEmprunt: this.idEmprunt
        };
    }
}

export default Passager;