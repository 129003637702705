import Passager from '../../models/types/Passager';

class PassagerService {
    API_URL = `${process.env.REACT_APP_API_URL}/passagers`;

    getPassagersForEmprunt = async (idEmprunt) => {
        try {
            const response = await fetch(`${this.API_URL}/emprunt/${idEmprunt}`, {
                headers: {'Accept': 'application/json'}
            });
            const data = await response.json();
            return data.map(item => new Passager(item.id, item.idUtilisateur, item.idEmprunt));
        } catch (error) {
            console.error('Erreur lors de la récupération des passagers pour l\'emprunt : ', error);
            throw error;
        }
    };

    getEmpruntsForPassager = async (idUtilisateur) => {
        try {
            const response = await fetch(`${this.API_URL}/utilisateur/${idUtilisateur}`, {
                headers: {'Accept': 'application/json'}
            });
            const data = await response.json();
            return data.map(item => new Passager(item.id, item.idUtilisateur, item.idEmprunt));
        } catch (error) {
            console.error('Erreur lors de la récupération des emprunts pour le passager : ', error);
            throw error;
        }
    };

    addPassagerToEmprunt = async (idEmprunt, idUtilisateur) => {
        try {
            console.log(`Ajout du passager: Emprunt ID ${idEmprunt}, Utilisateur ID ${idUtilisateur}`);
            const response = await fetch(`${this.API_URL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ idEmprunt, idUtilisateur })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Erreur lors de l\'ajout du passager');
            }

            const data = await response.json();
            console.log('Passager ajouté avec succès:', data);
            return data;
        } catch (error) {
            console.error('Erreur lors de l\'ajout du passager à l\'emprunt:', error);
            throw error;
        }
    };

    removePassagerFromEmprunt = async (idEmprunt, idUtilisateur) => {
        try {
            const response = await fetch(`${this.API_URL}?idEmprunt=${idEmprunt}&idUtilisateur=${idUtilisateur}`, {
                method: 'DELETE',
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la suppression du passager de l\'emprunt');
            }
            return await response.text();
        } catch (error) {
            console.error('Erreur lors de la suppression du passager de l\'emprunt : ', error);
            throw error;
        }
    };
    getPassagers = (empruntId) => {
        return fetch(`${this.API_URL}/passagers/${empruntId}`, {
            headers: {'Accept': 'application/json'}
        })
            .then(response => response.json())
            .then(data => data.map(item => new Passager(item.id, item.idUtilisateur, item.idEmprunt)))
            .catch(error => {
                console.error(`Erreur lors de la récupération des passagers pour l'emprunt ${empruntId} : `, error);
                throw error;
            });
    };

    addPassagerToEmprunt = (idUtilisateur, idEmprunt) => {
        const passager = new Passager(null, idUtilisateur, idEmprunt);
        return fetch(`${this.API_URL}/passagers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(passager.toJSON())
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de l\'ajout du passager à l\'emprunt');
                }
                return response.json();
            })
            .then(data => new Passager(data.id, data.idUtilisateur, data.idEmprunt))
            .catch(error => {
                console.error('Erreur lors de l\'ajout du passager à l\'emprunt : ', error);
                throw error;
            });
    };

    deletePassagerFromEmprunt = (idUtilisateur, idEmprunt) => {
        return fetch(`${this.API_URL}/passagers/${idUtilisateur}/${idEmprunt}`, {
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la suppression du passager de l\'emprunt');
                }
                return response.text();
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du passager de l\'emprunt : ', error);
                throw error;
            });
    };
}

export default new PassagerService();