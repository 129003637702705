import Vehicule from '../../models/types/Vehicule';
import Entreprise from '../../models/types/Entreprise';
import Site from '../../models/types/Site';
import Adresse from '../../models/types/Adresse';
import axiosJwt from '../middlewares/axiosJwt';


const API_URL = `${process.env.REACT_APP_API_URL}/vehicules`;

class VehiculeService {
    async addVehicule(formData) {
        try{
            const response = await axiosJwt.post(`${API_URL}/ajouter`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
        });
        return new Vehicule(
            response.data.immatriculation,
            response.data.entreprise,
            response.data.marque,
            response.data.modele,
            response.data.numAssurance,
            response.data.numCarteGrise,
            response.data.couleur,
            response.data.nbPlace,
            response.data.localisation,
            response.data.statut,
            response.data.disponible,
            response.data.image,
            response.data.id); 
        }catch (error) {
            this.handleError(error);
        }
    }

    getAllVehicules() {
        return axiosJwt.get(`${API_URL}/all`)
            .then(response => response.data.map(vehiculeData => {
                const entreprise = new Entreprise(
                    vehiculeData.entreprise.id,
                    vehiculeData.entreprise.siret,
                    vehiculeData.entreprise.idAdresse,
                    vehiculeData.entreprise.nom,
                    vehiculeData.entreprise.raisonSociale,
                    vehiculeData.entreprise.telephone,
                    vehiculeData.entreprise.abonnement,
                    vehiculeData.entreprise.nbAbonnement
                );

                const site = new Site(
                    vehiculeData.localisation.id,
                    vehiculeData.localisation.idAdresse,
                    vehiculeData.localisation.nom
                );

                const adresse = new Adresse(
                    vehiculeData.localisation.adresse.id,
                    vehiculeData.localisation.adresse.numero,
                    vehiculeData.localisation.adresse.rue,
                    vehiculeData.localisation.adresse.complement,
                    vehiculeData.localisation.adresse.codePostal,
                    vehiculeData.localisation.adresse.ville,
                    vehiculeData.localisation.adresse.x,
                    vehiculeData.localisation.adresse.y
                );

                site.adresse = adresse;

                return new Vehicule(
                    vehiculeData.immatriculation,
                    entreprise,
                    vehiculeData.marque,
                    vehiculeData.modele,
                    vehiculeData.numAssurance,
                    vehiculeData.numCarteGrise,
                    vehiculeData.couleur,
                    vehiculeData.nbPlace,
                    site,
                    vehiculeData.statut,
                    vehiculeData.disponible,
                    vehiculeData.image,
                    vehiculeData.id
                );
            }));
    }

    async getVehiculesByEntrepriseId(entrepriseId) {
        try{
            const response = await axiosJwt.get(`${API_URL}/entreprise/${entrepriseId}`);
            return response.data.map(vehiculeData => {
                const entrepriseData = vehiculeData.entreprise;
                const adresseEntrepriseData = entrepriseData.idAdresse;
                const adresseEntreprise = new Adresse(
                    adresseEntrepriseData.id,
                    adresseEntrepriseData.numero,
                    adresseEntrepriseData.rue,
                    adresseEntrepriseData.complement || '',
                    adresseEntrepriseData.codePostal,
                    adresseEntrepriseData.ville,
                    adresseEntrepriseData.x,
                    adresseEntrepriseData.y
                );
                const entreprise = new Entreprise(
                    entrepriseData.id,
                    entrepriseData.siret,
                    adresseEntreprise,
                    entrepriseData.nom,
                    entrepriseData.raisonSociale,
                    entrepriseData.telephone,
                    entrepriseData.abonnement,
                    entrepriseData.nbAbonnement
                );
                const localisationData = vehiculeData.localisation;
                const adresseLocalisationData = localisationData.adresse;
                const adresseLocalisation = new Adresse(
                    adresseLocalisationData.id,
                    adresseLocalisationData.numero,
                    adresseLocalisationData.rue,
                    adresseLocalisationData.complement || '',
                    adresseLocalisationData.codePostal,
                    adresseLocalisationData.ville,
                    adresseLocalisationData.x,
                    adresseLocalisationData.y
                );
                const site = new Site(
                    localisationData.id,
                    entreprise,
                    adresseLocalisation,
                    localisationData.actif,
                    localisationData.horaireOuverture,
                    localisationData.procedure,
                    localisationData.complementInformation,
                    localisationData.designation
                );
                return new Vehicule(
                    vehiculeData.immatriculation,
                    entreprise,
                    vehiculeData.marque,
                    vehiculeData.modele,
                    vehiculeData.numAssurance,
                    vehiculeData.numCarteGrise,
                    vehiculeData.couleur,
                    vehiculeData.nbPlace,
                    site,
                    vehiculeData.statut,
                    vehiculeData.disponible,
                    vehiculeData.image,
                    vehiculeData.id
                );
            });
        }catch (error) {
            this.handleError(error);
        }
    }

    async getVehiculeById(id) {
        try{
            const response = await axiosJwt.get(`${API_URL}/${id}`);
            const data = response.data;
            const entrepriseData = response.data.entreprise;
            const adresseEntrepriseData = entrepriseData.idAdresse;
            const adresseEntreprise = new Adresse(
                adresseEntrepriseData.id,
                adresseEntrepriseData.numero,
                adresseEntrepriseData.rue,
                adresseEntrepriseData.complement || '',
                adresseEntrepriseData.codePostal,
                adresseEntrepriseData.ville,
                adresseEntrepriseData.x,
                adresseEntrepriseData.y
            );
            const entreprise = new Entreprise(
                entrepriseData.id,
                entrepriseData.siret,
                adresseEntreprise,
                entrepriseData.nom,
                entrepriseData.raisonSociale,
                entrepriseData.telephone,
                entrepriseData.abonnement,
                entrepriseData.nbAbonnement
            );
            const localisationData = data.localisation;
            const adresseLocalisationData = localisationData.adresse;
            const adresseLocalisation = new Adresse(
                adresseLocalisationData.id,
                adresseLocalisationData.numero,
                adresseLocalisationData.rue,
                adresseLocalisationData.complement || '',
                adresseLocalisationData.codePostal,
                adresseLocalisationData.ville,
                adresseLocalisationData.x,
                adresseLocalisationData.y
            );
            const site = new Site(
                localisationData.id,
                entreprise,
                adresseLocalisation,
                localisationData.actif,
                localisationData.horaireOuverture,
                localisationData.procedure,
                localisationData.complementInformation,
                localisationData.designation
            );
            return new Vehicule(
                data.immatriculation,
                entreprise,
                data.marque,
                data.modele,
                data.numAssurance,
                data.numCarteGrise,
                data.couleur,
                data.nbPlace,
                site,
                data.statut,
                data.disponible,
                data.image,
                data.id
            );
        }catch (error) {
            this.handleError(error);
        }  
    }

    filterVehicules(vehiculeToFilter) {
        return axiosJwt.post(`${API_URL}/filter`, vehiculeToFilter.toJSON());
    }

    async searchVehicules(searchCriteria) {
        try{
        const response = await axiosJwt.get(`${API_URL}/search?searchCriteria=${searchCriteria}`);
        return response.data.map(vehiculeData => new Vehicule(
            vehiculeData.immatriculation,
            vehiculeData.entreprise,
            vehiculeData.marque,
            vehiculeData.modele,
            vehiculeData.numAssurance,
            vehiculeData.numCarteGrise,
            vehiculeData.couleur,
            vehiculeData.nbPlace,
            vehiculeData.localisation,
            vehiculeData.statut,
            vehiculeData.disponible,
            vehiculeData.image,
            vehiculeData.id
        ));  
        }catch (error) {
            this.handleError(error);
        }  
    }

    deleteVehicule(id) {
        try{
          return axiosJwt.delete(`${API_URL}/${id}`);  
        }catch (error) {
            this.handleError(error);
        }  
    }

    deleteVehicules(ids) {
        return axiosJwt.delete(`${API_URL}/all`, { data: ids });
    }

    updateVehicule(id, vehiculeData) {
        try {
            return axiosJwt.put(`${API_URL}/modifier/${id}`, vehiculeData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } catch (error) {
            this.handleError(error);
        }
    }
    getVehiculeImage(fileName) {
        try{
            return axiosJwt.get(`${API_URL}/image/${fileName}`, {
                responseType: 'arraybuffer'
            });
        }catch (error) {
            this.handleError(error);
        }    
    }
    handleError(error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            throw {
                message: errorData.mainMessage || 'Une erreur est survenue',
                errors: errorData.errors || [],
                type: errorData.type || 'Erreur',
                exceptionType: errorData.exceptionType,
                exceptionMessage: errorData.exceptionMessage
            };
        } else {
            throw new Error('Une erreur inattendue est survenue');
        }
    }
}

export default new VehiculeService();