class Mail{
    constructor(to, subject, text){
        this.to = Array.isArray(to) ? to : [to];
        this.subject = subject;
        this.text = text;
    }

    toJSON() {
        return {
            to: this.to,
            subject: this.subject,
            text: this.text,
        };
    }

}

export default Mail;