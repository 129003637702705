import React, { useState, useEffect } from 'react';
import SiteService from '../../../controleurs/services/Site';
import Site from '../../../models/types/Site';
import EntrepriseService from '../../../controleurs/services/Entreprise';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Modal,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function UpdateSite({ siteId, onClose, handleToggleListSites, entreprise }) {
    const [site, setSite] = useState(null);
    const [errors, setErrors] = useState({});
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formModified, setFormModified] = useState(false);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [selectedGestionnaire, setSelectedGestionnaire] = useState('');

    const [requiredFields, setRequiredFields] = useState({
        designation: false,
        adresse: false,
        horaireOuverture: false,
        procedure: false,
    });

    useEffect(() => {
        SiteService.getSiteById(siteId)
            .then(response => {
                const siteData = new Site(
                    response.id,
                    response.entreprise,
                    response.adresse,
                    response.actif,
                    response.horaireOuverture,
                    response.procedure,
                    response.complementInformation,
                    response.designation,
                    response.utilisateur
                );
                setSite(siteData);
                setRequiredFields({
                    designation: response.designation.trim() !== '',
                    adresse: response.adresse !== null,
                    horaireOuverture: response.horaireOuverture.trim() !== '',
                    procedure: response.procedure.trim() !== '',
                });
    
                // Charger les utilisateurs avec l'entreprise du site
                if (siteData.entreprise && siteData.entreprise.id) {
                    UtilisateurService.findUtilisateursByEntrepriseId(siteData.entreprise.id)
                        .then(utilisateurs => {
                            const gestionnaires = utilisateurs.filter(user => user.gestionnaire === true);
                            setUtilisateurs(gestionnaires);
                        })
                        .catch(error => {
                            console.error('Erreur lors de la récupération des utilisateurs : ', error);
                            setErrors(prevErrors => ({
                                ...prevErrors,
                                utilisateurs: 'Erreur lors de la récupération des utilisateurs'
                            }));
                        });
                }
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du site : ', error);
                setErrors({ ...errors, serverError: error.message });
                setOpenErrorModal(true);
            });
    }, [siteId]);


    useEffect(() => {
        const fetchUtilisateurs = async () => {
            if (!entreprise || !entreprise.id) {
                console.error('Entreprise or entreprise.id is undefined');
                return;
            }
            try {
                const data = await UtilisateurService.findUtilisateursByEntrepriseId(entreprise.id);
                setUtilisateurs(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des utilisateurs : ', error);
                setErrors(prevErrors => ({
                    ...prevErrors,
                    utilisateurs: 'Erreur lors de la récupération des utilisateurs'
                }));
            }
        };
    
        fetchUtilisateurs();
    }, [entreprise]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setSite(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
        setRequiredFields(prevState => ({
            ...prevState,
            [name]: value.trim() !== '',
        }));
        setFormModified(true);
        validateForm();
    };

    const validateForm = () => {
        if (site) {
            const isValid = Object.values(requiredFields).every(value => value) || formModified;
            setIsFormValid(isValid);
        }
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'designation':
            case 'horaireOuverture':
            case 'procedure':
            case 'complementInformation':
                if (!/^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s:,'-]+$/.test(value)) {
                    error = 'Seuls les caractères alphanumériques, les espaces, les virgules, les apostrophes et les tirets sont autorisés';
                }
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const siteInstance = new Site(
            site.id,
            site.entreprise,
            site.adresse,
            site.actif,
            site.horaireOuverture,
            site.procedure,
            site.complementInformation,
            site.designation,
            site.utilisateur
        );
        SiteService.updateSite(siteInstance)
            .then(response => {
                console.log('Réponse du serveur :', response.data);
                setShowSuccessModal(true);
            })
            .catch(error => {
                console.error('Erreur lors de la mise à jour du site :', error);
                setErrors({
                    serverError: error.message,
                    detailedErrors: error.errors
                });
                setOpenErrorModal(true);
            });
    };

    const handleCancel = () => {
        onClose();
        handleToggleListSites();
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        onClose();
        handleToggleListSites();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    if (!site) {
        return <div>Chargement...</div>;
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Modifier un site
                    </Typography>
                    <Paper elevation={3} component="form">
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        name="designation"
                                        label="Désignation"
                                        value={site.designation}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                    {errors.designation && <FormHelperText error>{errors.designation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>Gestionnaire</InputLabel>
                                        <Select
                                            value={site.utilisateur ? `${site.utilisateur.nom} ${site.utilisateur.prenom}` : ''}
                                            onChange={(e) => {
                                                setSelectedGestionnaire(e.target.value);
                                                const selectedUtilisateur = utilisateurs.find(utilisateur => `${utilisateur.nom} ${utilisateur.prenom}` === e.target.value);
                                                setSite(prevState => ({
                                                    ...prevState,
                                                    utilisateur: selectedUtilisateur
                                                }));
                                                setFormModified(true);
                                                validateForm();
                                            }}
                                            label="Gestionnaire"
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 200,
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        >
                                            {utilisateurs.map((utilisateur) => (
                                                <MenuItem key={utilisateur.id} value={`${utilisateur.nom} ${utilisateur.prenom}`}>
                                                    {`${utilisateur.nom} ${utilisateur.prenom}`}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="horaireOuverture"
                                        label="Horaires d'ouverture"
                                        value={site.horaireOuverture}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                    {errors.horaireOuverture && <FormHelperText error>{errors.horaireOuverture}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="procedure"
                                        label="Procédure"
                                        value={site.procedure}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 200,
                                        }}
                                    />
                                    {errors.procedure && <FormHelperText error>{errors.procedure}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="complementInformation"
                                        label="Complément d'information"
                                        value={site.complementInformation}
                                        onChange={handleChange}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        inputProps={{
                                            maxLength: 500,
                                        }}
                                    />
                                    {errors.complementInformation && <FormHelperText error>{errors.complementInformation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="actif"
                                                checked={site.actif}
                                                onChange={(e) => {
                                                    setSite(prevState => ({
                                                        ...prevState,
                                                        actif: e.target.checked
                                                    }));
                                                    setFormModified(true);
                                                    validateForm();
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Actif"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                                disabled={!isFormValid}
                                            >
                                                Modifier
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={handleCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={showSuccessModal} onClose={() => { setShowSuccessModal(false); onClose(); handleToggleListSites(); }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Site modifié avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la modification du site :
                    </Typography>
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default UpdateSite;