import {jwtDecode} from 'jwt-decode'; 
import axios from 'axios';

// Création d'une instance Axios
const axiosJWT = axios.create();

// Variable pour éviter les requêtes de rafraîchissement multiples
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Intercepteur de requête
axiosJWT.interceptors.request.use(
  async (config) => {
    const tokenString = sessionStorage.getItem('token');
    const refreshToken = sessionStorage.getItem('refreshToken');
    let currentDate = new Date();

    if (tokenString) {
      const jsonToken = JSON.parse(tokenString);
      const userToken = jsonToken.token;
      const decodedToken = jwtDecode(userToken);

      // Vérifier si le token est expiré
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        if (refreshToken) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const response = await axios.post('/auth/refresh-token', { refreshToken });
              const { token: newToken, refreshToken: newRefreshToken, expiresIn } = response.data;

              // Mettre à jour les tokens dans le stockage
              sessionStorage.setItem('token', JSON.stringify({ token: newToken, expiresIn }));
              sessionStorage.setItem('refreshToken', newRefreshToken);

              // Mettre à jour l'en-tête Authorization
              config.headers["Authorization"] = "Bearer " + newToken;

              processQueue(null, newToken);
            } catch (err) {
              processQueue(err, null);
              // Supprimer les tokens et rediriger vers la page de connexion
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('refreshToken');
              window.location.replace('/');
              return Promise.reject(err);
            } finally {
              isRefreshing = false;
            }
          }

          return new Promise(function(resolve, reject) {
            failedQueue.push({
              resolve: (token) => {
                config.headers["Authorization"] = "Bearer " + token;
                resolve(config);
              },
              reject: (err) => {
                reject(err);
              }
            });
          });
        } else {
          // Aucun refresh token disponible, déconnecter l'utilisateur
          sessionStorage.removeItem('token');
          window.location.replace('/');
        }
      } else {
        // Token valide, ajouter l'en-tête Authorization
        config.headers["Authorization"] = "Bearer " + userToken;
      }
    }

    return config;
  },
  (error) => {
    console.log('Erreur lors de la requête', error);
    return Promise.reject(error);
  }
);

// Intercepteur de réponse pour gérer les erreurs 401 (Non autorisé)
axiosJWT.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    // Si la réponse est 401 et que la requête n'a pas déjà été réessayée
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = sessionStorage.getItem('refreshToken');

      if (refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          return axios.post('/auth/refresh-token', { refreshToken })
            .then((response) => {
              const { token: newToken, refreshToken: newRefreshToken, expiresIn } = response.data;

              // Mettre à jour les tokens dans le stockage
              sessionStorage.setItem('token', JSON.stringify({ token: newToken, expiresIn }));
              sessionStorage.setItem('refreshToken', newRefreshToken);

              // Mettre à jour l'en-tête Authorization pour les requêtes futures
              axiosJWT.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;

              processQueue(null, newToken);

              // Réessayer la requête originale avec le nouveau token
              originalRequest.headers['Authorization'] = 'Bearer ' + newToken;
              return axiosJWT(originalRequest);
            })
            .catch((err) => {
              processQueue(err, null);
              // Supprimer les tokens et rediriger vers la page de connexion
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('refreshToken');
              window.location.replace('/');
              return Promise.reject(err);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise(function(resolve, reject) {
          failedQueue.push({
            resolve: (token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              resolve(axiosJWT(originalRequest));
            },
            reject: (err) => {
              reject(err);
            }
          });
        });
      } else {
        // Aucun refresh token disponible, déconnecter l'utilisateur
        sessionStorage.removeItem('token');
        window.location.replace('/');
      }
    }

    return Promise.reject(error);
  }
);

export default axiosJWT;