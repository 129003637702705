import axiosJwt from '../middlewares/axiosJwt';
import Cle from '../../models/types/Cle';

const API_URL = `${process.env.REACT_APP_API_URL}/cle`;

class CleService {
    async getAllCles() {
        try {
            const response = await axiosJwt.get(`${API_URL}/all`);
            return response.data.map(cleData => new Cle(cleData.id, cleData.vehicule, cleData.principale, cleData.information));
        } catch (error) {
            this.handleError(error);
        }
    }

    async getCleById(id) {
        try {
            const response = await axiosJwt.get(`${API_URL}/${id}`);
            return new Cle(response.data.id, response.data.vehicule, response.data.principale, response.data.information);
        } catch (error) {
            this.handleError(error);
        }
    }

    async getClesByIds(ids) {
        try {
            const response = await axiosJwt.post(`${API_URL}/ids`, ids);
            return response.data.map(cleData => new Cle(cleData.id, cleData.vehicule, cleData.principale, cleData.information));
        } catch (error) {
            this.handleError(error);
        }
    }

    async addCles(cles) {
        try {
            const response = await axiosJwt.post(`${API_URL}/add`, cles);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async updateCle(cle) {
        try {
            const response = await axiosJwt.put(`${API_URL}/update`, cle);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteCle(id) {
        try {
            const response = await axiosJwt.delete(`${API_URL}/delete/${id}`);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteCles(ids) {
        try {
            const response = await axiosJwt.delete(`${API_URL}/delete`, { data: ids });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async getKeysByVehicule(vehicule) {
        try {
            const response = await axiosJwt.get(`${API_URL}/vehicule`, { data: vehicule.toJSON() });
            return response.data.map(cleData => new Cle(cleData.id, cleData.vehicule, cleData.principale, cleData.information));
        } catch (error) {
            this.handleError(error);
        }
    }

    async getKeysByVehiculeId(id) {
        try {
            const response = await axiosJwt.get(`${API_URL}/vehicule/${id}`);
            return response.data.map(cleData => new Cle(cleData.id, cleData.vehicule, cleData.principale, cleData.information));
        } catch (error) {
            this.handleError(error);
        }
    }

    handleError(error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            throw {
                message: errorData.mainMessage || 'Une erreur est survenue',
                errors: errorData.errors || [],
                type: errorData.type || 'Erreur',
                exceptionType: errorData.exceptionType,
                exceptionMessage: errorData.exceptionMessage
            };
        } else {
            throw new Error('Une erreur inattendue est survenue');
        }
    }
}

export default new CleService();