import axiosJwt from '../middlewares/axiosJwt';
import Entreprise from '../../models/types/Entreprise';

const API_URL = `${process.env.REACT_APP_API_URL}/entreprises`;

class EntrepriseService {
    getAllEntreprises() {
        return axiosJwt.get(`${API_URL}/all`)
            .then(response => response.data.map(entrepriseData =>
                new Entreprise(
                    entrepriseData.id,
                    entrepriseData.siret,
                    entrepriseData.idAdresse,
                    entrepriseData.nom,
                    entrepriseData.raisonSociale,
                    entrepriseData.telephone,
                    entrepriseData.abonnement,
                    entrepriseData.nbAbonnement
                )
            ));
    }

    getEntrepriseById(id) {
        return axiosJwt.get(`${API_URL}/${id}`)
            .then(response => new Entreprise(
                response.data.id,
                response.data.siret,
                response.data.idAdresse,
                response.data.nom,
                response.data.raisonSociale,
                response.data.telephone,
                response.data.abonnement,
                response.data.nbAbonnement
            ));
    }

    getEntreprisesByIds(ids) {
        return axiosJwt.post(`${API_URL}/ids`, ids)
            .then(response => response.data.map(entrepriseData =>
                new Entreprise(
                    entrepriseData.id,
                    entrepriseData.siret,
                    entrepriseData.idAdresse,
                    entrepriseData.nom,
                    entrepriseData.raisonSociale,
                    entrepriseData.telephone,
                    entrepriseData.abonnement,
                    entrepriseData.nbAbonnement
                )
            ));
    }


    async addEntreprise(entreprise) {
        try {
            const response = await axiosJwt.post(`${API_URL}/add`, entreprise.toJSON());
            return new Entreprise(/* ... */);
        } catch (error) {
            this.handleError(error);
        }
    }

    async updateEntreprise(entreprise) {
        try {
            const response = await axiosJwt.put(`${API_URL}/update`, entreprise.toJSON());
            return new Entreprise(/* ... */);
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteEntrepriseById(id) {
        try {
            return await axiosJwt.delete(`${API_URL}/delete/${id}`);
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteEntreprise(entreprise) {
        try {
            return await axiosJwt.delete(`${API_URL}/delete`, { data: entreprise.toJSON() });
        } catch (error) {
            this.handleError(error);
        }
    }
    handleError(error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            throw {
                message: errorData.mainMessage || 'Une erreur est survenue',
                errors: errorData.errors || [],
                type: errorData.type || 'Erreur',
                exceptionType: errorData.exceptionType,
                exceptionMessage: errorData.exceptionMessage
            };
        } else {
            throw new Error('Une erreur inattendue est survenue');
        }
    }
}

export default new EntrepriseService();