import axiosJwt from '../middlewares/axiosJwt';
import Mail from '../../models/types/Mail';
import {getTemplateContent} from '../../ressources/utils/TemplatesMail'
import { formatDate } from '../../ressources/utils/dateTimeFormatter';

class MailService{

    API_URL = `${process.env.REACT_APP_API_URL}/mail`;

    getAllUtilisateursFromEmprunt = async (empruntId) => {
        try {
            const response = await axiosJwt.get(`${process.env.REACT_APP_API_URL}/emprunts/utilisateur/${empruntId}`);
            return response.data; 
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs:', error);
            return [];
        }
    };

    sendMailWithTemplate = async (recipients, templateName, emprunt) => {
        const templateContent = getTemplateContent(templateName);
    
        if (!templateContent) {
            console.error('Template introuvable:', templateName);
            return;
        }
    
        recipients = Array.isArray(recipients) ? recipients : [recipients];
    
        for (const recipient of recipients) {
            let body = templateContent.body;
    
            // Replace placeholder [nom]
            body = body.replace(/\[nom\]/g, recipient.name);

            const formattedDate = formatDate(emprunt.datePriseEnChargePrevue)

            // Replace placeholder [date]
            body = body.replace(/\[date\]/g, formattedDate);
    
            const mail = new Mail(recipient.email, templateContent.subject, body);
            const mailData = mail.toJSON();
    
            try {
                // Send the email and await the result
                await axiosJwt.post(`${this.API_URL}/send`, mailData, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                console.log(`E-mail envoyé à ${recipient.email}`);
            } catch (error) {
                console.error(`Erreur lors de l'envoi de l'e-mail à ${recipient.email}:`, error);
            }
        }
    
        alert('Tous les e-mails ont été envoyés avec succès.');
    };

    sendEmail = async (emprunt, templateName) => {
        const utilisateurs = await this.getAllUtilisateursFromEmprunt(emprunt.id);

        const recipients = utilisateurs.map(utilisateur => ({
            email: utilisateur.email,
            name: `${utilisateur.prenom} ${utilisateur.nom}`,
        }));

        await this.sendMailWithTemplate(recipients, templateName, emprunt);
    }

    sendCreationEmail =  async (emprunt) => {
        const mailService = new MailService();
        await mailService.sendEmail(emprunt, 'create');
    };

    sendUpdateEmail =  async (emprunt) => {
        const mailService = new MailService();
        await mailService.sendEmail(emprunt, 'update');
    };

    sendDeletionEmail =  async (emprunt) => {
        const mailService = new MailService();
        await mailService.sendEmail(emprunt, 'delete');
    };
};

export default MailService;