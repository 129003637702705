import axiosJwt from '../middlewares/axiosJwt';
import Role from '../../models/types/Role';

const API_URL = `${process.env.REACT_APP_API_URL}/roles`;

class RoleService {
getAllRoles() {
    return axiosJwt.get(`${API_URL}/all`)
        .then(response => response.data.map(roleData =>
            new Role(
                roleData.id,
                roleData.name
            )
        ));
}
}

export default new RoleService();