import React, {useEffect, useState} from 'react';
import VehiculeService from '../../../controleurs/services/Vehicule';
import Vehicule from '../../../models/types/Vehicule';
import {MapContainer, Marker, Popup, TileLayer} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet';
import CleService from '../../../controleurs/services/Cle';
import { VpnKey } from '@mui/icons-material';

import {
    Container,
    Grid,
    Typography,
    Box,
    Paper,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const customIcon = new Icon({
    iconUrl:require("../../../ressources/images/pointeur.png"),
    iconSize:[38,38]
})

function VehiculeDetails({ vehiculeId }) {
    const [vehicule, setVehicule] = useState(null);
    const [imageData, setImageData] = useState(null);
    const [cles, setCles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedCle, setSelectedCle] = useState(null);

    useEffect(() => {
        VehiculeService.getVehiculeById(vehiculeId)
            .then(response => {
                const vehiculeData = new Vehicule(
                    response.immatriculation,
                    response.entreprise,
                    response.marque,
                    response.modele,
                    response.numAssurance,
                    response.numCarteGrise,
                    response.couleur,
                    response.nbPlace,
                    response.localisation,
                    response.statut,
                    response.disponible,
                    response.image,
                    response.id
                );
                setVehicule(vehiculeData);
                if (vehiculeData.image) {
                    VehiculeService.getVehiculeImage(vehiculeData.image)
                        .then(imageResponse => {
                            const base64String = btoa(
                                Array.from(new Uint8Array(imageResponse.data), byte => String.fromCharCode(byte))
                                    .join('')
                            );
                            setImageData(`data:image/jpeg;base64,${base64String}`);
                        })
                        .catch(error => {
                            console.error('Erreur lors du chargement de l image :', error);
                        });
                }
                CleService.getKeysByVehiculeId(response.id)
                .then(clesData => {
                    setCles(clesData);
                })
                .catch(error => {
                    console.error('Erreur lors de la récupération des clés du véhicule : ', error);
                });
            })
            .catch(error => {
                console.error('Erreur lors de la récupération du véhicule : ', error);
            });
    }, [vehiculeId]);

    const handleOpenModal = (cle) => {
        setSelectedCle(cle);
        setOpenModal(true);
      };
      
      const handleCloseModal = () => {
        setSelectedCle(null);
        setOpenModal(false);
      };

    if (!vehicule) {
        return <div>Chargement...</div>;
    }else{
        return (
            <ThemeProvider theme={theme}>
                <Container maxWidth="md">
                    <Box my={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Détails du véhicule
                        </Typography>
                        <Paper elevation={3}>
                            <Box p={3}>
                                <Grid container spacing={3} alignItems="center">
                                    <Grid item xs={12} md={6}>
                                        <Box display="flex" justifyContent="center">
                                            {vehicule.image ? (
                                                <Avatar
                                                    alt="Image du véhicule"
                                                    src={imageData}
                                                    variant="square"
                                                    sx={{ width: '100%', height: 'auto', maxWidth: 400, borderRadius: '8px' }}
                                                />
                                            ) : (
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        maxWidth: 400,
                                                        height: '100%',
                                                        backgroundColor: 'grey.300',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        borderRadius: '8px',
                                                    }}
                                                >
                                                    <Typography variant="h6">No Image</Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={6} container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Immatriculation:</Typography>
                                            <Typography variant="body1">{vehicule.immatriculation}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Marque:</Typography>
                                            <Typography variant="body1">{vehicule.marque}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Modèle:</Typography>
                                            <Typography variant="body1">{vehicule.modele}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Numéro d'assurance:</Typography>
                                            <Typography variant="body1">{vehicule.numAssurance}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Numéro de carte grise:</Typography>
                                            <Typography variant="body1">{vehicule.numCarteGrise}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Couleur:</Typography>
                                            <Typography variant="body1">{vehicule.couleur}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Nombre de places:</Typography>
                                            <Typography variant="body1">{vehicule.nbPlace}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Site:</Typography>
                                            <Typography variant="body1">{vehicule.localisation.designation}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Statut:</Typography>
                                            <Typography variant="body1">{vehicule.statut}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle1" fontWeight="bold">Publié:</Typography>
                                            <Typography variant="body1">{vehicule.disponible ? 'Oui' : 'Non'}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" component="h2" gutterBottom>
                                            Clés du véhicule
                                        </Typography>
                                        {cles.length > 0 ? (
                                            <Grid container spacing={2} justifyContent="flex-start">
                                            {cles.map((cle, index) => (
                                                <Grid item xs={12} sm={6} md={4} key={cle.id}>
                                                <Paper
                                                  elevation={2}
                                                  sx={{
                                                    p: 2,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '40px',
                                                    width: '150px',
                                                    paddingRight: '5px',
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={() => handleOpenModal(cle)}
                                                >
                                                    <VpnKey sx={{ color: cle.principale ? 'error.main' : 'action.active', mr: 1 }} />
                                                    <div>
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        Clé {index + 1}
                                                    </Typography>
                                                    {cle.principale && (
                                                        <Typography variant="body1">
                                                        Principale
                                                        </Typography>
                                                    )}
                                                    </div>
                                                </Paper>
                                                </Grid>
                                            ))}
                                            </Grid>
                                        ) : (
                                            <Typography variant="body1">Pas de clé disponible</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box sx={{ height: '400px', width: '100%' }}>
                                            <MapContainer
                                                center={[vehicule.localisation.adresse.x, vehicule.localisation.adresse.y]}
                                                zoom={15}
                                                scrollWheelZoom={false}
                                                style={{ height: '100%', width: '100%' }}
                                            >
                                                <TileLayer
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                />
                                                <Marker position={[vehicule.localisation.adresse.x, vehicule.localisation.adresse.y]} icon={customIcon}>
                                                    <Popup>{vehicule.immatriculation}</Popup>
                                                </Marker>
                                            </MapContainer>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
                <Dialog open={openModal} onClose={handleCloseModal}>
                    <DialogTitle>Informations de la clé</DialogTitle>
                    <DialogContent>
                        {selectedCle && (
                        <>
                            <Typography variant="body1">
                            {selectedCle.information}
                            </Typography>
                        </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal}></Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        );
}
}
export default VehiculeDetails;