import Site from '../../models/types/Site';
import axiosJwt from '../middlewares/axiosJwt';

const API_URL = `${process.env.REACT_APP_API_URL}/sites`;

class SiteService {
    getAllSites() {
        return axiosJwt.get(`${API_URL}/all`)
            .then(response => response.data.map(siteData =>
                new Site(
                    siteData.id,
                    siteData.id,
                    siteData.entreprise,
                    siteData.adresse,
                    siteData.actif,
                    siteData.horaireOuverture,
                    siteData.procedure,
                    siteData.complementInformation,
                    siteData.designation,
                    siteData.utilisateur
                )
            ));
    }

    async getSiteById(id) {
        try {
            const response = await axiosJwt.get(`${API_URL}/${id}`);
            return new Site(
                response.data.id,
                response.data.entreprise,
                response.data.adresse,
                response.data.actif,
                response.data.horaireOuverture,
                response.data.procedure,                
                response.data.complementInformation,
                response.data.designation,
                response.data.utilisateur,
            );
        } catch (error) {
            this.handleError(error);
        }
    }

    getSitesByIds(ids) {
        return axiosJwt.post(`${API_URL}/ids`, ids)
            .then(response => response.data.map(siteData =>
                new Site(
                    siteData.id,
                    siteData.entreprise,
                    siteData.adresse,
                    siteData.actif,
                    siteData.horaireOuverture,
                    siteData.procedure,
                    siteData.complementInformation,
                    siteData.designation,
                    siteData.utilisateur
                )
            ));
    }

    addSites(sites) {
        return axiosJwt.post(`${API_URL}/add`, sites.map(site => site.toJSON()));
    }

    async updateSite(site) {
        try {
            const response = await axiosJwt.put(`${API_URL}/update`, site.toJSON());
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteSite(id) {
        try {
            const response = await axiosJwt.delete(`${API_URL}/delete/${id}`);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    deleteSites(ids) {
        return axiosJwt.delete(`${API_URL}/delete`, { data: ids });
    }

    getSitesByEntreprise(entreprise) {
        return axiosJwt.get(`${API_URL}/entreprise`, { data: entreprise.toJSON() });
    }

    async getSitesByEntrepriseId(id) {
        try{        
            const response = await axiosJwt.get(`${API_URL}/entreprise/${id}`);
            return response.data.map(siteData => new Site(
                siteData.id,
                siteData.entreprise,
                siteData.adresse,
                siteData.actif,
                siteData.horaireOuverture,
                siteData.procedure,
                siteData.complementInformation,
                siteData.designation,
                siteData.utilisateur
            ));
        }catch (error) {
            this.handleError(error);
        }
    }
    async searchSites(searchCriteria) {
        const response = await axiosJwt.get(`${API_URL}/search?searchCriteria=${searchCriteria}`);
        return response.data.map(siteData => new Site(
            siteData.id,
            siteData.entreprise,
            siteData.adresse,
            siteData.actif,
            siteData.horaireOuverture,
            siteData.procedure,
            siteData.complementInformation,
            siteData.designation,
            siteData.utilisateur
        )
        );
    }
    handleError(error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            throw {
                message: errorData.mainMessage || 'Une erreur est survenue',
                errors: errorData.errors || [],
                type: errorData.type || 'Erreur',
                exceptionType: errorData.exceptionType,
                exceptionMessage: errorData.exceptionMessage
            };
        } else {
            throw new Error('Une erreur inattendue est survenue');
        }
    }
}

export default new SiteService();