import axiosJwt from '../middlewares/axiosJwt';
import Emprunt from '../../models/types/Emprunt';

const API_URL = `${process.env.REACT_APP_API_URL}/emprunts`;

class EmpruntService {
    async getAllEmprunt() {
        try {
            const response = await axiosJwt.get(`${API_URL}/all`);
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async getEmpruntByEntrepriseId(entrepriseId) {
        try {
            const response = await axiosJwt.get(`${API_URL}/entreprise/${entrepriseId}`);
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async getEmpruntByUserId(userId) {
        try {
            const response = await axiosJwt.get(`${API_URL}/user/${userId}`);
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async getEmpruntById(id) {
        try {
            const response = await axiosJwt.get(`${API_URL}/${id}`);
            return this.createEmpruntFromData(response.data);
        } catch (error) {
            this.handleError(error);
        }
    }

    async getEmpruntByIds(ids) {
        try {
            const response = await axiosJwt.post(`${API_URL}/ids`, ids);
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async addEmprunts(emprunts) {
        try {
            const response = await axiosJwt.post(`${API_URL}/adds`, emprunts.map(emprunt => emprunt.toJSON()));
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async addEmprunt(emprunt) {
        try {
            const response = await axiosJwt.post(`${API_URL}/add`, emprunt);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async filterEmprunts(empruntToFilter) {
        try {
            const response = await axiosJwt.post(`${API_URL}/filter`, empruntToFilter.toJSON());
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async searchEmprunts(searchCriteria) {
        try {
            const response = await axiosJwt.get(`${API_URL}/search?searchCriteria=${searchCriteria}`);
            return response.data.map(this.createEmpruntFromData);
        } catch (error) {
            this.handleError(error);
        }
    }

    async updateEmprunt(emprunt) {
        try {
            const response = await axiosJwt.put(`${API_URL}/update`, emprunt.toJSON());
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteEmprunt(id) {
        try {
            const response = await axiosJwt.delete(`${API_URL}/delete/${id}`);
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    async deleteEmprunts(ids) {
        try {
            const response = await axiosJwt.delete(`${API_URL}/delete`, { data: ids });
            return response.data;
        } catch (error) {
            this.handleError(error);
        }
    }

    createEmpruntFromData(empruntData) {
        return new Emprunt(
            empruntData.id,
            empruntData.vehicule,
            empruntData.entreprise,
            empruntData.nbPlaceDisponible,
            empruntData.utilisateur,
            empruntData.datePriseEnChargePrevue,
            empruntData.datePriseEnCharge,
            empruntData.dateRenduPrevue,
            empruntData.dateRendu,
            empruntData.lieuDePriseEnCharge,
            empruntData.lieuRendu,
            empruntData.kmPriseEnCharge,
            empruntData.kmRemise,
            empruntData.etat,
            empruntData.motif,
            empruntData.destination
        );
    }

    handleError(error) {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            throw {
                message: errorData.mainMessage || 'Une erreur est survenue',
                errors: errorData.errors || [],
                type: errorData.type || 'Erreur',
                exceptionType: errorData.exceptionType,
                exceptionMessage: errorData.exceptionMessage
            };
        } else {
            throw new Error('Une erreur inattendue est survenue');
        }
    }
}

export default new EmpruntService();