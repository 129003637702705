import React from 'react';
import { Typography, Box } from '@mui/material';

const AfficherUtilisateur = ({ utilisateur }) => {
    return (
        <Box>
            <Typography variant="h6">Informations de l'utilisateur</Typography>
            <Box mt={2}>
                <Typography><strong>Nom:</strong> {utilisateur.nom}</Typography>
                <Typography><strong>Prénom:</strong> {utilisateur.prenom}</Typography>
                <Typography><strong>Date de Naissance:</strong> {utilisateur.dateDeNaissance}</Typography>
                <Typography><strong>Email:</strong> {utilisateur.email}</Typography>
                <Typography><strong>Téléphone:</strong> {utilisateur.telephone}</Typography>
                <Typography><strong>Poste:</strong> {utilisateur.poste}</Typography>
                <Typography><strong>Matricule:</strong> {utilisateur.matricule}</Typography>
                <Typography><strong>Gestionnaire:</strong> {utilisateur.gestionnaire ? 'Oui' : 'Non'}</Typography>
            </Box>
        </Box>
    );
};

export default AfficherUtilisateur;