import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import UpdateUtilisateur from '../Utilisateur/CrudUtilisateur/UpdateUtilisateur';
import UpdateEntreprise from '../Entreprise/UpdateEntreprise';
import TunnelEmprunt from '../Emprunt/TunnelEmprunt';  
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Collapse,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Modal,
    Toolbar,
    Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddVehicule from '../Vehicule/AddVehicule'
import ListeVehicules from '../Vehicule/ListVehicules';
import UpdateVehicule from '../Vehicule/UpdateVehicule';
import VehiculeDetails from '../Vehicule/VehiculeDetails';
import ListSites from '../Site/ListSites';
import UpdateSite from '../Site/UpdateSite';
import AddSite from '../Site/AddSite';
import EntrepriseDetails from '../Entreprise/EntrepriseDetails';
import Register from '../Login/Register';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PlanningEmprunts from '../Emprunt/PlanningEmprunts';
import AddEmprunt from '../Emprunt/AddEmprunt';
import UpdateEmprunt from '../Emprunt/UpdateEmprunt';
import EmpruntDetails from '../Emprunt/EmpruntDetails';
import MyEmprunts from '../Emprunt/MyEmprunts';
import ListUtilisateurs from '../Utilisateur/ListUtilisateurs';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginService from '../../../controleurs/services/Login';
import { useUser } from '../../../controleurs/middlewares/userContext';
import CreateUtilisateur from '../Utilisateur/CrudUtilisateur/CreateUtilisateur';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        secondary: {
            main: '#f44336',
            light: '#6755A5',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const HomePage = () => {
    const [showAddVehicule, setShowAddVehicule] = useState(false);
    const [showUtilisateur, setShowUtilisateur] = useState(false);
    const [showVehiculeDetails, setShowVehiculeDetails] = useState(false);
    const [showListeVehicules, setShowListeVehicules] = useState(false);
    const [showListSites, setShowListSites] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [openVehiculeSubmenu, setOpenVehiculeSubmenu] = useState(false);
    const [openSiteSubmenu, setOpenSiteSubmenu] = useState(false);
    const [selectedVehiculeId, setSelectedVehiculeId] = useState(1);
    const [showUpdateVehicule, setShowUpdateVehicule] = useState(false);
    const [entreprise, setEntreprise] = useState(null);
    const [selectedSiteId, setSelectedSiteId] = useState(null);
    const [showSiteDetails, setShowSiteDetails] = useState(false);
    const [showUpdateSite, setShowUpdateSite] = useState(false);
    const [showAddSite, setShowAddSite] = useState(false);
    const [showEntrepriseDetails, setShowEntrepriseDetails] = useState(false);
    const [openEntrepriseSubmenu, setOpenEntrepriseSubmenu] = useState(false);
    const [openUtilisateurSubmenu, setOpenUtilisateurSubmenu] = useState(false);
    const [openNewSubmenu, setOpenNewSubmenu] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [drawerWidth, setDrawerWidth] = useState(64);
    const [showPlanningEmprunts, setShowPlanningEmprunts] = useState(false);
    const [showAddEmprunt, setShowAddEmprunt] = useState(false);
    const [showUpdateEmprunt, setShowUpdateEmprunt] = useState(false);
    const [showEmpruntDetails, setShowEmpruntDetails] = useState(false);
    const [showMyEmprunts, setShowMyEmprunts] = useState(false);
    const [showListUtilisateurs, setShowListUtilisateurs] = useState(false);
    const [openUserModal, setOpenUserModal] = useState(false);
    const { user, loading } = useUser();
    const [showCreateUtilisateur, setShowCreateUtilisateur] = useState(false);
    const [showUpdateUtilisateur, setShowUpdateUtilisateur] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [showUpdateEntreprise, setShowUpdateEntreprise] = useState(false);
    const [showTunnelEmprunt, setShowTunnelEmprunt] = useState(false);
    const getUserInitials = () => {
        return `${user.prenom.charAt(0)}${user.nom.charAt(0)}`;
    };

    useEffect(() => {
        if (!loading && user) {
            handleTogglePlanningEmprunts();
        }
    }, [loading, user]);

    const handleOpenUserModal = () => setOpenUserModal(true);
    const handleCloseUserModal = () => setOpenUserModal(false);

    const handleEditEntreprise = (entrepriseId) => {
        setShowUpdateEntreprise(true);
        setShowEntrepriseDetails(false);
    };

    const handleAddUtilisateur = () => {
        setShowCreateUtilisateur(true);
        setShowListUtilisateurs(false);
    };
    const handleAddVehicule = () => {
        setShowAddVehicule(true);
        setShowListeVehicules(false);
    };
    const handleAddSite = () => {
        setShowAddSite(true);
        setShowListSites(false);
    };

    const handleLogout = () => {
        LoginService.logout()
            .then(() => {
            })
            .catch(error => {
                console.error("Erreur lors de la déconnexion", error);
            });
    };

    const handleCreateUtilisateurSuccess = () => {
        setShowCreateUtilisateur(false);
        setShowListUtilisateurs(true);
    };

    const handleEditUtilisateur = (userId) => {
        setSelectedUserId(userId);
        setShowUpdateUtilisateur(true);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
    };

    const handleUpdateUtilisateurSuccess = () => {
        setShowUpdateUtilisateur(false);
        setShowListUtilisateurs(true);
        // Rafraîchir la liste des utilisateurs si nécessaire
    };

    const handleToggleAddEmprunt = () => {
        setShowAddEmprunt(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowPlanningEmprunts(false);
        setShowUpdateEmprunt(false);
        setShowEmpruntDetails(false);
        setShowMyEmprunts(false);
        setShowListUtilisateurs(false);
    };

    const handleToggleCreateUtilisateur = () => {
        setShowCreateUtilisateur(true);
        setShowAddEmprunt(false);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowPlanningEmprunts(false);
        setShowUpdateEmprunt(false);
        setShowEmpruntDetails(false);
        setShowMyEmprunts(false);
        setShowListUtilisateurs(false);
    };

    const handleToggleListUtilisateurs = () => {
        setShowListUtilisateurs(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleUpdateEmprunt = () => {
        setShowUpdateEmprunt(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleEmpruntDetails = () => {
        setShowEmpruntDetails(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleEmpruntAdded = () => {
        // Cette fonction sera appelée après l'ajout d'un emprunt
        handleTogglePlanningEmprunts();
    };

    const handleToggleMyEmprunts = () => {
        setShowTunnelEmprunt(true);
        setShowMyEmprunts(false);
        setShowAddEmprunt(false);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowPlanningEmprunts(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleToggleAddVehicule = () => {
        setShowAddVehicule(true);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleMouseEnter = () => {
        setDrawerWidth(170);
    };
    const handleTogglePlanningEmprunts = () => {
        setShowPlanningEmprunts(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleMouseLeave = () => {
        setDrawerWidth(64);
        setOpenVehiculeSubmenu(false);
        setOpenSiteSubmenu(false);
        setOpenUtilisateurSubmenu(false);
        setOpenEntrepriseSubmenu(false);
        setOpenNewSubmenu(false);
    }

    const handleToggleUtilisateur = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(true);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleRegister = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(true);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleVehiculeDetails = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowVehiculeDetails(true);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleListeVehicules = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(true);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleToggleListSites = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(true);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleVehiculeSubmenuClick = () => {
        setOpenVehiculeSubmenu(!openVehiculeSubmenu);
    };

    const handleSiteSubmenuClick = () => {
        setOpenSiteSubmenu(!openSiteSubmenu);
    };

    const handleVehiculeClick = (vehiculeId) => {
        setSelectedVehiculeId(vehiculeId);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(true);
        setShowListeVehicules(false);
        setShowListSites(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleSiteClick = (siteId) => {
        setSelectedSiteId(siteId);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowSiteDetails(true);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleVehiculeUpdate = (vehiculeId) => {
        setSelectedVehiculeId(vehiculeId);
        setShowUpdateVehicule(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleSiteUpdate = (siteId) => {
        setSelectedSiteId(siteId);
        setShowUpdateSite(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowSiteDetails(false);
        setMobileOpen(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };

    const handleCloseUpdateVehicule = () => {
        setShowUpdateVehicule(false);
    };
    const handleCloseUpdateSite = () => {
        setShowUpdateSite(false);
    };

    const handleShowListeVehicules = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(true);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleShowListSites = () => {
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(true);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowUpdateSite(false);
        setShowEntrepriseDetails(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleToggleAddSite = () => {
        setShowAddSite(true);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowEntrepriseDetails(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleToggleEntrepriseDetails = () => {
        setShowEntrepriseDetails(true);
        setShowUpdateEntreprise(false);
        setShowAddVehicule(false);
        setShowUtilisateur(false);
        setShowVehiculeDetails(false);
        setShowListeVehicules(false);
        setShowListSites(false);
        setShowUpdateSite(false);
        setMobileOpen(false);
        setShowUpdateVehicule(false);
        setShowAddSite(false);
        setShowRegister(false);
        setShowPlanningEmprunts(false);
        setShowAddEmprunt(false);
        setShowListUtilisateurs(false);
        setShowCreateUtilisateur(false);
    };
    const handleCloseAddSite = () => {
        setShowAddSite(false);
    };
    const handleEntrepriseSubmenuClick = () => {
        setOpenEntrepriseSubmenu(!openEntrepriseSubmenu);
    };
    const handleUtilisateurSubmenuClick = () => {
        setOpenUtilisateurSubmenu(!openUtilisateurSubmenu);
    };
    const handleNewSubmenuClick = () => {
        setOpenNewSubmenu(!openNewSubmenu);
    };
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="fixed"
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, borderBottom: '4px solid #75C9AE' }}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                            DriveLink
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'baseline', mr: 2 }}>
                            <Typography variant="subtitle2" color="inherit" sx={{ mr: 1 }}>
                                powered by
                            </Typography>
                            <Typography variant="h6" color="inherit">
                                Inno Moov
                            </Typography>
                        </Box>
                        <IconButton color="inherit" onClick={handleOpenUserModal}>
                            <Avatar sx={{
                                width: 40,
                                height: 40,
                                bgcolor: '#75C9AE',
                                fontSize: '1.25rem',
                                color: 'white',
                                '&:hover': {
                                    bgcolor: '#5BA98D'
                                }
                            }}>
                                {user ? getUserInitials() : null}
                            </Avatar>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component={Drawer}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 150 },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'top', flexGrow: 1 }}>
                        <List>
                            <ListItem button onClick={handleVehiculeSubmenuClick}>
                                <ListItemText primary="Véhicule" sx={{ textAlign: 'left' }} />
                                {openVehiculeSubmenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openVehiculeSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListeVehicules}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Lister des véhicules
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddVehicule}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un véhicule
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleSiteSubmenuClick}>
                                <ListItemText primary="Site" sx={{ textAlign: 'left' }} />
                                {openSiteSubmenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openSiteSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListSites}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Liste des sites
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddSite}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un site
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleUtilisateurSubmenuClick}>
                                <PersonIcon sx={{ mr: 1 }} />
                                <ListItemText primary="Utilisateurs" sx={{ textAlign: 'left' }} />
                                {openUtilisateurSubmenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openUtilisateurSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListUtilisateurs}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Liste des utilisateurs
                                        </Typography>
                                    </ListItem>
                                </List>
                                <ListItem button onClick={handleToggleCreateUtilisateur} sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                    <Typography variant="body1" sx={{ fontSize: '0.9rem', color: theme.palette.primary.main, textAlign: 'right' }}>
                                        Créer un utilisateur
                                    </Typography>
                                </ListItem>
                            </Collapse>
                            <ListItem button onClick={handleEntrepriseSubmenuClick}>
                                <ListItemText primary="Entreprise" sx={{ textAlign: 'left' }} />
                                {openEntrepriseSubmenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openEntrepriseSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleEntrepriseDetails}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Détails
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleNewSubmenuClick}>
                                <ListItemText primary="Emprunts" sx={{ textAlign: 'left' }} />
                                {openNewSubmenu ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openNewSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleTogglePlanningEmprunts}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Planning des emprunts
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddEmprunt}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un emprunt
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleMyEmprunts}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Mes emprunts
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    </Box>
                </Box>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, zIndex: 1200 },
                        transition: 'width 0.9s ease-in-out',
                    }}
                    open
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <Toolbar />
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>
                        <List sx={{ width: '100%' }}>
                            <ListItem button onClick={handleVehiculeSubmenuClick} sx={{ width: '100%' }}>
                                {drawerWidth === 64 ? (
                                    <DirectionsCarIcon />
                                ) : (
                                    <>
                                        <ListItemText primary="Véhicule" sx={{ textAlign: 'left' }} />
                                        {openVehiculeSubmenu ? <ExpandLess /> : <ExpandMore />}
                                    </>
                                )}
                            </ListItem>
                            <Collapse in={openVehiculeSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListeVehicules}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Lister des véhicules
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddVehicule}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un véhicule
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleSiteSubmenuClick} sx={{ width: '100%' }}>
                                {drawerWidth === 64 ? (
                                    <LocationOnIcon />
                                ) : (
                                    <>
                                        <ListItemText primary="Site" sx={{ textAlign: 'left' }} />
                                        {openSiteSubmenu ? <ExpandLess /> : <ExpandMore />}
                                    </>
                                )}
                            </ListItem>
                            <Collapse in={openSiteSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListSites}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Liste des sites
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddSite}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un site
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleUtilisateurSubmenuClick} sx={{ width: '100%' }}>
                                {drawerWidth === 64 ? (
                                    <PersonIcon />
                                ) : (
                                    <>
                                        <ListItemText primary="Utilisateurs" sx={{ textAlign: 'left' }} />
                                        {openUtilisateurSubmenu ? <ExpandLess /> : <ExpandMore />}
                                    </>
                                )}
                            </ListItem>
                            <Collapse in={openUtilisateurSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleListUtilisateurs}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Liste des utilisateurs
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleCreateUtilisateur} sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{ fontSize: '0.9rem', color: theme.palette.primary.main, textAlign: 'right' }}>
                                            Créer un utilisateur
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleEntrepriseSubmenuClick} sx={{ width: '100%' }}>
                                {drawerWidth === 64 ? (
                                    <BusinessIcon />
                                ) : (
                                    <>
                                        <ListItemText primary="Entreprise" sx={{ textAlign: 'left' }} />
                                        {openEntrepriseSubmenu ? <ExpandLess /> : <ExpandMore />}
                                    </>
                                )}
                            </ListItem>
                            <Collapse in={openEntrepriseSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleToggleEntrepriseDetails}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Détails
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button onClick={handleNewSubmenuClick} sx={{ width: '100%' }}>
                                {drawerWidth === 64 ? (
                                    <SwapHorizIcon />
                                ) : (
                                    <>
                                        <ListItemText primary="Emprunts" sx={{ textAlign: 'left' }} />
                                        {openNewSubmenu ? <ExpandLess /> : <ExpandMore />}
                                    </>
                                )}
                            </ListItem>
                            <Collapse in={openNewSubmenu} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button onClick={handleTogglePlanningEmprunts}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Planning des emprunts
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleAddEmprunt}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Ajouter un emprunt
                                        </Typography>
                                    </ListItem>
                                    <ListItem button onClick={handleToggleMyEmprunts}
                                        sx={{ pl: 4, width: '100%', display: 'flex', justifyContent: 'right' }}>
                                        <Typography variant="body1" sx={{
                                            fontSize: '0.9rem',
                                            color: theme.palette.primary.main,
                                            textAlign: 'right'
                                        }}>
                                            Mes emprunts
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    </Box>
                </Drawer>
                <Box component="main" sx={{ flexGrow: 1, p: { xs: 2, sm: 3 }, ml: { sm: `64px` } }}>
                    <Toolbar />
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {showAddVehicule && <AddVehicule
                                entreprise={{ id: user.idEntreprise.id }}
                                onCancel={() => setShowAddVehicule(false)}
                                onSuccess={() => {
                                    setShowAddVehicule(false);
                                    setShowListeVehicules(true);
                                }}
                            />}
                            {showVehiculeDetails && (
                                <VehiculeDetails vehiculeId={selectedVehiculeId} />
                            )}
                            {showListeVehicules && (
                                <ListeVehicules
                                    entrepriseId={user.idEntreprise.id}
                                    onVehiculeClick={handleVehiculeClick}
                                    onVehiculeUpdate={handleVehiculeUpdate}
                                    onAddVehicule={handleAddVehicule}
                                />
                            )}
                            {showUpdateVehicule && (
                                <UpdateVehicule
                                    vehiculeId={selectedVehiculeId}
                                    onClose={handleCloseUpdateVehicule}
                                    handleToggleListeVehicules={handleShowListeVehicules}
                                    entreprise={{ id: user.idEntreprise.id }}
                                />
                            )}
                            {showListSites && (
                                <ListSites
                                    entrepriseId={user.idEntreprise.id}
                                    onSiteUpdate={handleSiteUpdate}
                                    onAddSite={handleAddSite}
                                />
                            )}
                            {showSiteDetails && (
                                <SiteDetails siteId={selectedSiteId} />
                            )}
                            {showUpdateSite && (
                                <UpdateSite
                                    siteId={selectedSiteId}
                                    onClose={handleCloseUpdateSite}
                                    handleToggleListSites={handleShowListSites}
                                    entreprise={user.idEntreprise.id}
                                />
                            )}
                            {showAddSite && (
                                <AddSite
                                    entreprise={{ id: user.idEntreprise.id }}
                                    onClose={handleCloseAddSite}
                                    handleToggleListSites={handleToggleListSites}
                                />
                            )}
                            {showEntrepriseDetails && (
                                <EntrepriseDetails
                                    entrepriseId={user.idEntreprise.id}
                                    onEdit={handleEditEntreprise}
                                />
                            )}
                            {showRegister && (
                                <Register />
                            )}
                            {showPlanningEmprunts && (
                                <PlanningEmprunts entrepriseId={user.idEntreprise.id}
                                    onNavigateToAddEmprunt={handleToggleAddEmprunt}
                                />
                            )}
                            {showAddEmprunt && (
                                <AddEmprunt
                                    entrepriseId={user.idEntreprise.id}
                                    onEmpruntAdded={handleEmpruntAdded}
                                />
                            )}
                            {showUpdateEmprunt && <UpdateEmprunt />}
                            {showEmpruntDetails && <EmpruntDetails />}
                            {showMyEmprunts && <MyEmprunts />}
                            {showTunnelEmprunt && <TunnelEmprunt entrepriseId={user.idEntreprise.id} />}
                            {showListUtilisateurs && (
                                <ListUtilisateurs
                                    entrepriseId={user.idEntreprise.id}
                                    onAddUtilisateur={handleAddUtilisateur}
                                    onEditUtilisateur={handleEditUtilisateur}
                                />
                            )}
                            {showCreateUtilisateur && (
                                <CreateUtilisateur
                                    utilisateur={null}
                                    onCancel={() => setShowCreateUtilisateur(false)}
                                    idEntreprise={user.idEntreprise.id}
                                    onSuccess={handleCreateUtilisateurSuccess}
                                />
                            )}
                            {showUpdateUtilisateur && (
                                <UpdateUtilisateur
                                    id={selectedUserId}
                                    onCancel={() => {
                                        setShowUpdateUtilisateur(false);
                                        setShowListUtilisateurs(true);
                                    }}
                                    onSuccess={handleUpdateUtilisateurSuccess}
                                />
                            )}
                            {showUpdateEntreprise && (
                                <UpdateEntreprise
                                    entrepriseId={user.idEntreprise.id}
                                    onCancel={() => {
                                        setShowUpdateEntreprise(false);
                                        setShowEntrepriseDetails(true);
                                    }}
                                    onSuccess={() => {
                                        setShowUpdateEntreprise(false);
                                        setShowEntrepriseDetails(true);
                                        // Vous pouvez ajouter ici une logique pour rafraîchir les détails de l'entreprise si nécessaire
                                    }}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Modal
                open={openUserModal}
                onClose={handleCloseUserModal}
                aria-labelledby="user-modal-title"
                aria-describedby="user-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="user-modal-title" variant="h6" component="h2" gutterBottom>
                        Informations utilisateur
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        {user ? (
                            <>
                                <Typography variant="body1"><strong>Nom :</strong> {user.nom}</Typography>
                                <Typography variant="body1"><strong>Prénom :</strong> {user.prenom}</Typography>
                                <Typography variant="body1"><strong>Email :</strong> {user.email}</Typography>
                                <Typography variant="body1"><strong>Poste :</strong> {user.poste}</Typography>
                            </>
                        ) : (
                            <Typography variant="body1">Chargement des informations utilisateur...</Typography>
                        )}
                    </Box>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<LogoutIcon />}
                        onClick={handleLogout}
                        fullWidth
                    >
                        Déconnexion
                    </Button>
                </Box>
            </Modal>
        </ThemeProvider>
    );
};

export default HomePage;