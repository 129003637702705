import React, { useEffect, useState } from 'react';
import EmpruntService from '../../../controleurs/services/Emprunt';
import VehiculeService from '../../../controleurs/services/Vehicule';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import SiteService from '../../../controleurs/services/Site';
import Emprunt from '../../../models/types/Emprunt';
import Adresse from '../../../models/types/Adresse';
import { parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    TextField,
    Typography,
    Autocomplete,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import MailService from '../../../controleurs/services/Mail';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
        cancel: {
            main: '#e0e0e0',
        },
        success: {
            main: '#8bc34a',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575',
        },
    },
});

const ETATS = ['AFFECTE', 'VALIDE', 'DEMANDE', 'TERMINE', 'REFUSE'];

function UpdateEmprunt({ entrepriseId, empruntId, onEmpruntUpdated }) {
    const [empruntData, setEmpruntData] = useState(null);
    const [errors, setErrors] = useState({});
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [vehicules, setVehicules] = useState([]);
    const [conducteurs, setConducteurs] = useState([]);
    const [sites, setSites] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [passagers, setPassagers] = useState([]);
    const [allUtilisateurs, setAllUtilisateurs] = useState([]);
    const [placesDisponibles, setPlacesDisponibles] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [passagersToAdd, setPassagersToAdd] = useState([]);
    const [passagersToRemove, setPassagersToRemove] = useState([]);
    const [initialPassagers, setInitialPassagers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                console.log(`Chargement des données pour l'emprunt ID: ${empruntId}`);

                console.log("UtilisateurService:", UtilisateurService);
                console.log("getPassagers existe:", typeof UtilisateurService.getPassagers === 'function');

                const [rawEmpruntData, vehiculesData, conducteursData, sitesData] = await Promise.all([
                    EmpruntService.getEmpruntById(empruntId),
                    VehiculeService.getVehiculesByEntrepriseId(entrepriseId),
                    UtilisateurService.findUtilisateursByEntrepriseId(entrepriseId),
                    SiteService.getSitesByEntrepriseId(entrepriseId)
                ]);

                console.log("Données brutes de l'emprunt:", rawEmpruntData);

                if (!rawEmpruntData) {
                    throw new Error("Aucune donnée d'emprunt reçue");
                }

                const formattedEmpruntData = {
                    ...rawEmpruntData,
                    datePriseEnChargePrevue: rawEmpruntData.datePriseEnChargePrevue ? parseISO(rawEmpruntData.datePriseEnChargePrevue) : null,
                    datePriseEnCharge: rawEmpruntData.datePriseEnCharge ? parseISO(rawEmpruntData.datePriseEnCharge) : null,
                    dateRenduPrevue: rawEmpruntData.dateRenduPrevue ? parseISO(rawEmpruntData.dateRenduPrevue) : null,
                    dateRendu: rawEmpruntData.dateRendu ? parseISO(rawEmpruntData.dateRendu) : null,
                };

                setEmpruntData(formattedEmpruntData);
                setVehicules(vehiculesData);
                setConducteurs(conducteursData);
                setAllUtilisateurs(conducteursData);
                setSites(sitesData);

                let passagersData = [];
                try {
                    passagersData = await UtilisateurService.getPassagers(empruntId);
                    console.log("Passagers récupérés:", passagersData);
                    setPassagers(passagersData);
                    setInitialPassagers(passagersData.map(p => p.id));
                } catch (passagerError) {
                    console.error("Erreur lors de la récupération des passagers:", passagerError);
                    setPassagers([]);
                }

                if (formattedEmpruntData.destination) {
                    const { numero, rue, codePostal, ville } = formattedEmpruntData.destination;
                    setSearchAddress(`${numero} ${rue}, ${codePostal} ${ville}`);
                }

                if (formattedEmpruntData.vehicule) {
                    const totalPlaces = formattedEmpruntData.vehicule.nbPlace;
                    const placesPrises = 1 + passagersData.length; // 1 pour le conducteur
                    setPlacesDisponibles(Math.max(0, totalPlaces - placesPrises));
                }

            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
                setError(error.message || "Une erreur est survenue lors du chargement des données");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [empruntId, entrepriseId]);

    useEffect(() => {
        validateForm();
    }, [empruntData, errors]);

    const validateForm = () => {
        if (!empruntData) return;
        const requiredFields = ['vehicule', 'utilisateur', 'destination', 'datePriseEnChargePrevue', 'dateRenduPrevue', 'lieuDePriseEnCharge', 'lieuRendu'];
        const isValid = requiredFields.every(field => empruntData[field] && !errors[field]);
        setIsFormValid(isValid);
    };
    const isOptionEqualToValue = (option, value) => {
        // Comparez les propriétés pertinentes
        return option.id === value.id;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmpruntData(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
    };

    const handleDateChange = (name, value) => {
        setEmpruntData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleVehiculeChange = (e) => {
        const selectedVehicule = vehicules.find(v => v.id === e.target.value);
        setEmpruntData(prevState => ({
            ...prevState,
            vehicule: selectedVehicule,
            nbPlaceDisponible: selectedVehicule ? selectedVehicule.nbPlace - 1 : null // Soustraire le conducteur
        }));

        if (selectedVehicule) {
            const totalPlaces = selectedVehicule.nbPlace;
            const placesPrises = 1; // Juste le conducteur pour l'instant
            setPlacesDisponibles(Math.max(0, totalPlaces - placesPrises));
        }
    };

    const validateField = (name, value) => {
        let error = '';
        if (!value) {
            error = 'Ce champ est requis';
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSearchAddressChange = async (event, value) => {
        setSearchAddress(value);

        if (value.length >= 3) {
            try {
                const response = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${encodeURIComponent(value)}&limit=5`);
                setAddressSuggestions(response.data.features);
            } catch (error) {
                console.error('Erreur lors de la recherche d\'adresse :', error);
            }
        } else {
            setAddressSuggestions([]);
        }
    };

    const handleSuggestionClick = (event, value) => {
        if (value) {
            const { properties, geometry } = value;

            const newAdresse = new Adresse(
                null,
                properties.housenumber || '',
                properties.street || '',
                '',
                properties.postcode || '',
                properties.city || '',
                geometry.coordinates[1],
                geometry.coordinates[0]
            );

            setEmpruntData(prevState => ({
                ...prevState,
                destination: newAdresse,
            }));

            setAddressSuggestions([]);
            setSearchAddress(properties.label);
            validateField('destination', newAdresse);
        }
    };
    const handleAddPassagers = async (passagersToAdd) => {
        for (const passagerId of passagersToAdd) {
            if (!initialPassagers.includes(passagerId)) { // Vérifier si le passager est déjà présent
                await UtilisateurService.addPassagerToEmprunt(passagerId, empruntId);
            }
        }
    };
    const handleRemovePassagers = async (passagersToRemove) => {
        for (const passagerId of passagersToRemove) {
            console.log(passagerId)
            console.log(initialPassagers)
            if (initialPassagers.includes(passagerId)) { // Vérifier si le passager est initialement présent
                console.log(passagerId)
                console.log(empruntId)
                await UtilisateurService.deletePassagerFromEmprunt(passagerId, empruntId);
            }
        }
    };

    const handlePassagersChange = (event, newValue) => {
        if (empruntData.vehicule) {
            const maxPassagers = empruntData.vehicule.nbPlace - 1; // -1 pour le conducteur
            const newPassagers = newValue.slice(0, maxPassagers);

            // Identifier les passagers ajoutés
            const added = newPassagers.filter(p => !passagers.some(existing => existing.id === p.id));
            setPassagersToAdd(prev => [...prev, ...added.map(p => p.id)]);
            console.log(passagersToAdd);

            // Identifier les passagers supprimés
            const removed = passagers.filter(p => !newPassagers.some(newP => newP.id === p.id));
            setPassagersToRemove(prev => [...prev, ...removed.map(p => p.id)]);
            console.log(passagersToRemove)
            setPassagers(newPassagers);

            const placesDisponibles = Math.max(0, empruntData.vehicule.nbPlace - 1 - newPassagers.length);
            setEmpruntData(prevState => ({
                ...prevState,
                nbPlaceDisponible: placesDisponibles
            }));
            setPlacesDisponibles(placesDisponibles);
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!empruntData) return;

        const emprunt = new Emprunt(
            empruntData.id,
            empruntData.vehicule,
            empruntData.entreprise,
            empruntData.nbPlaceDisponible,
            empruntData.utilisateur,
            empruntData.datePriseEnChargePrevue,
            empruntData.datePriseEnCharge,
            empruntData.dateRenduPrevue,
            empruntData.dateRendu,
            empruntData.lieuDePriseEnCharge,
            empruntData.lieuRendu,
            empruntData.kmPriseEnCharge,
            empruntData.kmRemise,
            empruntData.etat,
            empruntData.motif,
            empruntData.destination
        );
        try {
            const updatedEmprunt = await EmpruntService.updateEmprunt(emprunt);

            // Gestion des passagers
            await handleAddPassagers(passagersToAdd);
            await handleRemovePassagers(passagersToRemove);
            const mailService = new MailService();
                mailService.sendUpdateEmail(updatedEmprunt);

            setOpenSuccessModal(true);
            setTimeout(() => {
                onEmpruntUpdated();
            }, 2000);
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'emprunt:', error);
            setErrors({
                serverError: error.message,
                detailedErrors: error.errors
            });
            setOpenErrorModal(true);
        }
    };

    const handleCancel = () => {
        onEmpruntUpdated(); // Retour à la liste des emprunts sans sauvegarder
    };

    const handleCloseSuccessModal = () => {
        setOpenSuccessModal(false);
        onEmpruntUpdated();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (!empruntData) {
        return <Typography>Erreur: Impossible de charger les données de l'emprunt.</Typography>;
    }

    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                <Container maxWidth="md">
                    <Box my={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Modifier l'emprunt
                        </Typography>
                        <Paper elevation={3} component="form">
                            <Box p={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Véhicule</InputLabel>
                                            <Select
                                                name="vehicule"
                                                value={empruntData.vehicule ? empruntData.vehicule.id : ''}
                                                onChange={handleVehiculeChange}
                                            >
                                                {vehicules.map((vehicule) => (
                                                    <MenuItem key={vehicule.id} value={vehicule.id}>
                                                        {`${vehicule.marque} ${vehicule.modele} - ${vehicule.immatriculation} (${vehicule.nbPlace} places)`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {empruntData.vehicule && (
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    mt: 1, 
                                                    color: placesDisponibles === 0 ? 'error.main' : 'text.primary'
                                                }}
                                            >
                                                Places disponibles : {placesDisponibles} / {empruntData.vehicule.nbPlace}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Conducteur</InputLabel>
                                            <Select
                                                name="utilisateur"
                                                value={empruntData.utilisateur ? empruntData.utilisateur.id : ''}
                                                onChange={(e) => handleChange({
                                                    target: {
                                                        name: 'utilisateur',
                                                        value: conducteurs.find(u => u.id === e.target.value)
                                                    }
                                                })}
                                            >
                                                {conducteurs.map((conducteur) => (
                                                    <MenuItem key={conducteur.id} value={conducteur.id}>
                                                        {`${conducteur.nom} ${conducteur.prenom}`}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            freeSolo
                                            options={addressSuggestions}
                                            getOptionLabel={(option) => option.properties.label}
                                            inputValue={searchAddress}
                                            onInputChange={handleSearchAddressChange}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            onChange={(event, value) => handleSuggestionClick(event, value)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Destination"
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de prise en charge prévue"
                                            value={empruntData.datePriseEnChargePrevue}
                                            onChange={(newValue) => handleDateChange('datePriseEnChargePrevue', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth required />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de prise en charge effective"
                                            value={empruntData.datePriseEnCharge}
                                            onChange={(newValue) => handleDateChange('datePriseEnCharge', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de rendu prévue"
                                            value={empruntData.dateRenduPrevue}
                                            onChange={(newValue) => handleDateChange('dateRenduPrevue', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth required />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DateTimePicker
                                            label="Date de rendu effective"
                                            value={empruntData.dateRendu}
                                            onChange={(newValue) => handleDateChange('dateRendu', newValue)}
                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                            ampm={false}
                                            format="dd/MM/yyyy HH:mm"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Lieu de prise en charge</InputLabel>
                                            <Select
                                                name="lieuDePriseEnCharge"
                                                value={empruntData.lieuDePriseEnCharge ? empruntData.lieuDePriseEnCharge.id : ''}
                                                onChange={(e) => handleChange({
                                                    target: {
                                                        name: 'lieuDePriseEnCharge',
                                                        value: sites.find(s => s.id === e.target.value)
                                                    }
                                                })}
                                            >
                                                {sites.map((site) => (
                                                    <MenuItem key={site.id} value={site.id}>
                                                        {site.designation}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>Lieu de rendu</InputLabel>
                                            <Select
                                                name="lieuRendu"
                                                value={empruntData.lieuRendu ? empruntData.lieuRendu.id : ''}
                                                onChange={(e) => handleChange({
                                                    target: {
                                                        name: 'lieuRendu',
                                                        value: sites.find(s => s.id === e.target.value)
                                                    }
                                                })}
                                            >
                                                {sites.map((site) => (
                                                    <MenuItem key={site.id} value={site.id}>
                                                        {site.designation}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="kmPriseEnCharge"
                                            label="Kilométrage à la prise en charge"
                                            type="number"
                                            value={empruntData.kmPriseEnCharge || ''}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            name="kmRemise"
                                            label="Kilométrage à la remise"
                                            type="number"
                                            value={empruntData.kmRemise || ''}
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            multiple
                                            id="passagers"
                                            options={allUtilisateurs.filter(u => u.id !== empruntData.utilisateur?.id)}
                                            getOptionLabel={(option) => `${option.nom} ${option.prenom}`}
                                            value={passagers}
                                            onChange={handlePassagersChange}
                                            isOptionEqualToValue={isOptionEqualToValue}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Passagers"
                                                    placeholder="Sélectionner les passagers"
                                                />
                                            )}
                                            disabled={!empruntData.vehicule}
                                        />
                                        {empruntData.vehicule && placesDisponibles === 0 && (
                                            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                                                Le véhicule est complet. Aucune place disponible.
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="motif"
                                            label="Motif"
                                            value={empruntData.motif}
                                            onChange={handleChange}
                                            fullWidth
                                            multiline
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControl fullWidth required>
                                            <InputLabel>État</InputLabel>
                                            <Select
                                                name="etat"
                                                value={empruntData.etat}
                                                onChange={handleChange}
                                            >
                                                {ETATS.map((etat) => (
                                                    <MenuItem key={etat} value={etat}>
                                                        {etat}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2} justifyContent="flex-start">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                    disabled={!isFormValid}
                                                >
                                                    Mettre à jour
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Annuler
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Container>
            </LocalizationProvider>
            <Modal open={openSuccessModal} onClose={handleCloseSuccessModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Modification réussie
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        L'emprunt a été modifié avec succès.
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                        Erreur lors de la mise à jour de l'emprunt
                    </Typography>
                    {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                        <List>
                            {errors.detailedErrors.map((error, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={error} />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default UpdateEmprunt;