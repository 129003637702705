import React, { useState, useEffect } from 'react';
import VehiculeService from '../../../controleurs/services/Vehicule';
import CleService from '../../../controleurs/services/Cle'
import Vehicule from '../../../models/types/Vehicule';
import { Statut,getStatutText,getStatutKey } from '../../../models/types/Statut';
import SiteService from '../../../controleurs/services/Site';
import {
    Button,
    Container,
    Grid,
    TextField,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Box,
    Paper,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Modal,
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import { Delete, Edit, Add } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5', // Violet
        },
        cancel: {
            main: '#e0e0e0', // Gris clair
        },
        success: {
            main: '#8bc34a', // Vert clair
        },
        error: {
            main: '#f44336', // Rouge clair
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
        subtitle1: {
            fontSize: '0.9rem',
            color: '#757575', // Couleur légèrement plus claire
        },
    },
});

function UpdateVehicule({ vehiculeId, onClose, handleToggleListeVehicules,entreprise }) {
    const [vehicule, setVehicule] = useState(null);
    const [errors, setErrors] = useState({});
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [imageData, setImageData] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [sites, setSites] = useState([]);
    const [cles, setCles] = useState([]);
    const [editedCle, setEditedCle] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [selectedCle, setSelectedCle] = useState(null);
    const [newCle, setNewCle] = useState({ principale: false, information: '' });
    const [formModified, setFormModified] = useState(false);

    const [requiredFields, setRequiredFields] = useState({
        immatriculation: false,
        marque: false,
        modele: false,
        numAssurance: false,
        numCarteGrise: false,
        nbPlace: false,
        statut: false,
    });

    useEffect(() => {
        if (entreprise) {
            SiteService.getSitesByEntrepriseId(entreprise.id)
                .then(data => {
                    if (Array.isArray(data)) {
                        setSites(data);
                    } else {
                        console.log("Les données renvoyées ne sont pas un tableau");
                        setErrors({ ...errors, serverError: error.message });
                        setOpenErrorModal(true);
                        setSites([]); 
                    }
                })
                
                .catch(error => {
                    setErrors({ ...errors, serverError: error.message });
                    setOpenErrorModal(true);
                    console.error('Erreur lors de la récupération des sites :', error);
                });
        }
    }, [entreprise.id]);

    useEffect(() => {
        VehiculeService.getVehiculeById(vehiculeId)
            .then(response => {
                const vehiculeData = new Vehicule(
                    response.immatriculation,
                    response.entreprise,
                    response.marque,
                    response.modele,
                    response.numAssurance,
                    response.numCarteGrise,
                    response.couleur,
                    response.nbPlace,
                    response.localisation.id,
                    response.statut,
                    response.disponible,
                    response.image,
                    response.id
                );
                setVehicule(vehiculeData);
                setRequiredFields({
                    immatriculation: response.immatriculation.trim() !== '',
                    marque: response.marque.trim() !== '',
                    modele: response.modele.trim() !== '',
                    numAssurance: response.numAssurance !== '' && response.numAssurance !== null,
                    numCarteGrise: response.numCarteGrise !== '' && response.numCarteGrise !== null,
                    nbPlace: response.nbPlace !== '' && response.nbPlace !== null,
                    statut: response.statut !== '' && response.statut !== null,
                    localisation: response.localisation !== '' && response.localisation !== null
                });
                if (response.image) {
                    VehiculeService.getVehiculeImage(response.image)
                        .then(imageResponse => {
                            const base64String = btoa(
                                Array.from(new Uint8Array(imageResponse.data), byte => String.fromCharCode(byte))
                                    .join('')
                            );
                            setImageData(`data:image/jpeg;base64,${base64String}`);
                        })
                        .catch(error => {
                            setErrors({ ...errors, serverError: error.message });
                            setOpenErrorModal(true);
                            console.error('Erreur lors du chargement de l\'image :', error);
                        });
                }  
                CleService.getKeysByVehiculeId(response.id)
                .then(clesData => {
                  setCles(clesData);
                })
                .catch(error => {
                    setErrors({ ...errors, serverError: error.message });
                    setOpenErrorModal(true);
                    console.error('Erreur lors de la récupération des clés du véhicule : ', error);
                });            
            })
            .catch(error => {
                setErrors({ ...errors, serverError: error.message });
                setOpenErrorModal(true);
                console.error('Erreur lors de la récupération du véhicule : ', error);
            });

    }, [vehiculeId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setVehicule(prevState => ({
            ...prevState,
            [name]: value
        }));
        validateField(name, value);
        setRequiredFields(prevState => ({
            ...prevState,
            [name]: ['numAssurance', 'numCarteGrise', 'nbPlace'].includes(name) 
            ? value !== '' 
            : typeof value === 'string' ? value.trim() !== '' : String(value).trim() !== '',
        }));
        setFormModified(true);
        validateForm();
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        validateForm();
        setFormModified(true);
    };

    const validateForm = () => {
        if (vehicule) {
            const isValid = Object.values(requiredFields).every(value => value) || formModified;
            setIsFormValid(isValid);
        }
    };

    const validateField = (name, value) => {
        let error = '';
        switch (name) {
            case 'immatriculation':
            case 'marque':
            case 'modele':
            case 'entreprise':
            case 'couleur':
                if (!/^[a-zA-Z0-9]*$/.test(value)) {
                    error = 'Seuls les caractères alphanumériques sont autorisés';
                }
                break;
            case 'numAssurance':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                }
                break;
            case 'numCarteGrise':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                }
                break;
            case 'nbPlace':
                if (!/^[0-9]*$/.test(value)) {
                    error = 'Seuls les caractères numériques sont autorisés';
                } else {
                    const nbPlace = Number(value);
                    if (nbPlace < 1 || nbPlace > 100) {
                        error = 'Le nombre de places doit être compris entre 1 et 100';
                    }
                }
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        const vehiculeInstance = new Vehicule(
            vehicule.immatriculation,
            entreprise,
            vehicule.marque,
            vehicule.modele,
            vehicule.numAssurance,
            vehicule.numCarteGrise,
            vehicule.couleur,
            vehicule.nbPlace,
            { id: vehicule.localisation },
            vehicule.statut,
            vehicule.disponible,
            vehicule.image,
            vehicule.id
        );
        formData.append('vehicule', JSON.stringify(vehiculeInstance));
        if (selectedFile) {
            formData.append('file', selectedFile);
        }
        VehiculeService.updateVehicule(vehiculeId, formData)
            .then(response => {
                console.log('Réponse du serveur :', response.data);
                setShowSuccessModal(true);
            })
            .catch(error => {
                console.error('Erreur lors de la mise à jour du véhicule :', error);
                setErrors({
                    serverError: error.message,
                    detailedErrors: error.errors
                });
                setOpenErrorModal(true);
            });
    };
    const handleCancel = () => {
        onClose();
        handleToggleListeVehicules(); 
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        onClose();
        handleToggleListeVehicules();
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const getLocalisationDesignation = (localisationId) => {
        const site = sites.find((site) => site.id === localisationId);
        return site ? site.designation : '';
      };

      const handleDeleteCle = (cleId) => {
        setSelectedCle(cleId);
        setOpenDeleteModal(true);
      };
      
      const confirmDeleteCle = () => {
        CleService.deleteCle(selectedCle)
          .then(() => {
            setCles(cles.filter((cle) => cle.id !== selectedCle));
            setOpenDeleteModal(false);
          })
          .catch((error) => {
            console.error('Erreur lors de la suppression de la clé : ', error);
            setErrors({ ...errors, serverError: error.message });
            setOpenErrorModal(true);
          });
      };
      
      const handleEditCle = (cle) => {
        setEditedCle(cle);
        setOpenEditModal(true);
      };
      
      const confirmEditCle = () => {
        const updatedCle = {
          id: editedCle.id,
          principale: editedCle.principale,
          information: editedCle.information,
          vehicule: editedCle.vehicule
        };
      
        CleService.updateCle(updatedCle)
          .then((response) => {
            const updatedCleData = response.data;
            setCles((prevCles) => {
              let updatedCles = prevCles.map((cle) => {
                if (cle.id === updatedCleData.id) {
                  return updatedCleData;
                } else if (updatedCleData.principale && cle.principale) {
                  return { ...cle, principale: false };
                } else {
                  return cle;
                }
              });
              updatedCles.sort((a, b) => b.principale - a.principale);
              return updatedCles;
            });
            setOpenEditModal(false);
          })
          .catch((error) => {
            console.error('Erreur lors de la modification de la clé : ', error);
            setErrors({ ...errors, serverError: error.message });
            setOpenErrorModal(true);
          });
      };
      
      const handleAddCle = () => {
        setNewCle({ principale: false, information: '' });
        setOpenAddModal(true);
      };
      
      const confirmAddCle = () => {
        const newCleData = {
          ...newCle,
          vehicule: { id: vehiculeId },
        };
      
        CleService.addCles([newCleData])
          .then((response) => {
            const createdCles = response.data;
            setCles([...cles, ...createdCles]);
            setOpenAddModal(false);
          })
          .catch((error) => {
            console.error('Erreur lors de l\'ajout de la clé : ', error);
            setErrors({ ...errors, serverError: error.message });
            setOpenErrorModal(true);
          });
      };


    if (!vehicule) {
        return <div>Chargement...</div>;
    };

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Modifier un véhicule
                    </Typography>
                    <Paper elevation={3} component="form" encType="multipart/form-data">
                        <Box p={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="immatriculation"
                                        label="Immatriculation"
                                        value={vehicule.immatriculation}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 9,
                                        }}
                                    />
                                    {errors.immatriculation && <FormHelperText error>{errors.immatriculation}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="marque"
                                        label="Marque"
                                        value={vehicule.marque}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.marque && <FormHelperText error>{errors.marque}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="modele"
                                        label="Modèle"
                                        value={vehicule.modele}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.modele && <FormHelperText error>{errors.modele}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="entreprise"
                                        label="Entreprise"
                                        value={vehicule.entreprise.nom}
                                        onChange={handleChange}
                                        fullWidth
                                        disabled
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.entreprise && <FormHelperText error>{errors.entreprise}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="numAssurance"
                                        label="Numéro d'assurance"
                                        value={vehicule.numAssurance}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                    {errors.numAssurance && <FormHelperText error>{errors.numAssurance}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="numCarteGrise"
                                        label="Numéro de carte grise"
                                        value={vehicule.numCarteGrise}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 13,
                                        }}
                                    />
                                    {errors.numCarteGrise && <FormHelperText error>{errors.numCarteGrise}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="couleur"
                                        label="Couleur"
                                        value={vehicule.couleur}
                                        onChange={handleChange}
                                        fullWidth
                                        inputProps={{
                                            maxLength: 19,
                                        }}
                                    />
                                    {errors.couleur && <FormHelperText error>{errors.couleur}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="nbPlace"
                                        label="Nombre de places"
                                        type="number"
                                        value={vehicule.nbPlace}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        inputProps={{
                                            min: 1,
                                            max: 100,
                                        }}
                                    />
                                    {errors.nbPlace && <FormHelperText error>{errors.nbPlace}</FormHelperText>}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Localisation</InputLabel>
                                        <Select
                                            name="localisation"
                                            value={vehicule.localisation}
                                            onChange={handleChange}
                                        >
                                            {sites.map((site) => (
                                                <MenuItem key={site.id} value={site.id}>
                                                    {site.designation}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required>
                                        <InputLabel>Statut</InputLabel>
                                        <Select
                                            name="statut"
                                            value={vehicule.statut}
                                            onChange={handleChange}
                                        >
                                            {Object.values(Statut).map((statut) => (
                                                <MenuItem key={statut} value={statut}>
                                                    {getStatutText(statut)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="disponible"
                                                checked={vehicule.disponible}
                                                onChange={(e) => {
                                                    setVehicule(prevState => ({
                                                        ...prevState,
                                                        disponible: e.target.checked
                                                    }));
                                                    setFormModified(true);
                                                    validateForm();
                                                    
                                                }}
                                                color="primary"
                                            />
                                        }
                                        label="Publié"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" component="h2" gutterBottom>
                                        Clés du véhicule
                                    </Typography>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        {cles.map((cle, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={cle.id}>
                                            <Paper
                                            elevation={2}
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                height: '50px',
                                            }}
                                            >
                                            <div>
                                                <Typography variant="subtitle1" fontWeight="bold">
                                                Clé {index + 1}
                                                </Typography>
                                                {cle.principale && (
                                                <Typography variant="body1">Principale</Typography>
                                                )}
                                            </div>
                                            <div>
                                                {!cle.principale && (
                                                <IconButton
                                                    aria-label="Supprimer la clé"
                                                    onClick={() => handleDeleteCle(cle.id)}
                                                >
                                                    <Delete color="error" />
                                                </IconButton>
                                                )}
                                                <IconButton
                                                aria-label="Modifier la clé"
                                                onClick={() => handleEditCle(cle)}
                                                >
                                                <Edit color="primary" />
                                                </IconButton>
                                            </div>
                                            </Paper>
                                        </Grid>
                                        ))}
                                        <Grid item xs={12} sm={6} md={4}>
                                        <Paper
                                            elevation={2}
                                            sx={{
                                            p: 2,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                            height:'50px',
                                            '&:hover': {
                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                              },
                                            }}
                                            onClick={handleAddCle}
                                        >
                                            <Add fontSize="large" color="primary" />
                                        </Paper>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {imageData ? (
                                        <Avatar
                                            alt="Image du véhicule"
                                            src={imageData}
                                            variant="square"
                                            sx={{ width: 400, height: 400, borderRadius: '8px' }}
                                        />
                                    ) : (
                                        <Typography variant="body1">Aucune image disponible</Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <input
                                        type="file"
                                        name="imageFile"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent="flex-start">
                                        <Grid item>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSubmit}
                                            disabled={!isFormValid}
                                        >
                                            Modifier
                                        </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="cancel"
                                                onClick={handleCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                </Box>
            </Container>
            <Modal open={showSuccessModal} onClose={() => { setShowSuccessModal(false); onClose(); handleToggleListeVehicules(); }}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                        Véhicule modifié avec succès !
                    </Typography>
                </Box>
            </Modal>
            <Modal open={openErrorModal} onClose={handleCloseErrorModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                    Erreur lors de la modification du véhicule
                </Typography>
                <Typography variant="body1">{errors.serverError}</Typography>
                {errors.detailedErrors && errors.detailedErrors.length > 0 && (
                    <List>
                        {errors.detailedErrors.map((error, index) => (
                            <ListItem key={index}>
                                <ListItemText primary={error} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Box>
        </Modal>
            <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                <Box
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                    Modifier la clé
                    </Typography>
                    {editedCle && (
                    <>
                        <TextField
                        label="Information"
                        value={editedCle.information}
                        onChange={(e) =>
                            setEditedCle({ ...editedCle, information: e.target.value })
                        }
                        fullWidth
                        margin="normal"
                        />
                        <FormControlLabel
                        control={
                            <Checkbox
                            checked={editedCle.principale}
                            onChange={(e) =>
                                setEditedCle({ ...editedCle, principale: e.target.checked })
                            }
                            color="primary"
                            />
                        }
                        label="Principale"
                        />
                        <Box mt={2} display="flex" justifyContent="flex-end">
                        <Button onClick={() => setOpenEditModal(false)} color="cancel" variant="contained" sx={{marginRight: 2}}>
                            Annuler
                        </Button>
                        <Button onClick={confirmEditCle} color="primary" variant="contained">
                            Enregistrer
                        </Button>
                        </Box>
                    </>
                    )}
                </Box>
            </Modal>
            <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
                <Box
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                    Ajouter une clé
                    </Typography>
                    <TextField
                    label="Information"
                    value={newCle.information}
                    onChange={(e) => setNewCle({ ...newCle, information: e.target.value })}
                    fullWidth
                    margin="normal"
                    />
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={newCle.principale}
                        onChange={(e) => setNewCle({ ...newCle, principale: e.target.checked })}
                        color="primary"
                        />
                    }
                    label="Principale"
                    />
                    <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={() => setOpenAddModal(false)} color="cancel" variant="contained" sx={{ marginRight: 2 }}>
                        Annuler
                    </Button>
                    <Button onClick={confirmAddCle} color="primary" variant="contained">
                        Ajouter
                    </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
                <Box
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                    Ajouter une clé
                    </Typography>
                    <TextField
                    label="Information"
                    value={newCle.information}
                    onChange={(e) => setNewCle({ ...newCle, information: e.target.value })}
                    fullWidth
                    margin="normal"
                    />
                    <FormControlLabel
                    control={
                        <Checkbox
                        checked={newCle.principale}
                        onChange={(e) => setNewCle({ ...newCle, principale: e.target.checked })}
                        color="primary"
                        />
                    }
                    label="Principale"
                    />
                    <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={() => setOpenAddModal(false)} color="cancel" variant="contained" sx={{ marginRight: 2 }}>
                        Annuler
                    </Button>
                    <Button onClick={confirmAddCle} color="primary" variant="contained">
                        Ajouter
                    </Button>
                    </Box>
                </Box>
            </Modal>
            <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                <Box
                    sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                    Confirmer la suppression de la clé
                    </Typography>
                    <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button onClick={() => setOpenDeleteModal(false)} color="cancel" variant="contained" sx={{ marginRight: 2 }}>
                        Annuler
                    </Button>
                    <Button onClick={confirmDeleteCle} color="error" variant="contained">
                        Supprimer
                    </Button>
                    </Box>
                </Box>
            </Modal>
        </ThemeProvider>
    );
}

export default UpdateVehicule;