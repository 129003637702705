import React, { useEffect, useState } from 'react';
import UtilisateurService from '../../../controleurs/services/Utilisateur';
import UpdateUtilisateur from './CrudUtilisateur/UpdateUtilisateur';
import AfficherUtilisateur from './AfficherUtilisateur';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, Box, Modal, CircularProgress, TextField, InputAdornment, IconButton,
    Container, TablePagination, Button, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import {
    Search as SearchIcon,
    Close as CloseIcon,
    Person as PersonIcon,
    KeyboardArrowDown,
    KeyboardArrowUp,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@mui/icons-material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { blue, red, green } from '@mui/material/colors';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6755A5',
        },
    },
    typography: {
        fontFamily: 'Oswald, sans-serif',
    },
});

const SortableTableCell = styled(TableCell)(({ theme }) => ({
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiTableSortLabel-root': {
        display: 'inline-flex',
        alignItems: 'center',
    },
    '& .MuiTableSortLabel-icon': {
        marginLeft: theme.spacing(1),
    },
}));

const SortArrowIcon = ({ direction }) => {
    return (
        <Box
            component={direction === 'asc' ? KeyboardArrowUp : KeyboardArrowDown}
            sx={{
                ml: 1,
                width: 16,
                height: 16,
                flexShrink: 0,
            }}
        />
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function ListUtilisateurs({ entrepriseId, onAddUtilisateur, onEditUtilisateur }) {
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null);
    const [open, setOpen] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [orderBy, setOrderBy] = useState('nom');
    const [order, setOrder] = useState('asc');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [showResetIcon, setShowResetIcon] = useState(false);
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [editingUserId, setEditingUserId] = useState(null);
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [openUserDetailsModal, setOpenUserDetailsModal] = useState(false);


    useEffect(() => {
        UtilisateurService.findUtilisateursByEntrepriseId(entrepriseId)
            .then((data) => {
                // Sort the data by 'nom' in ascending order when it's first fetched
                const sortedData = data.sort((a, b) => a.nom.localeCompare(b.nom));
                setUtilisateurs(sortedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching utilisateurs:', error);
                setLoading(false);
                handleError(error);
            });
    }, [entrepriseId]);

    const handleError = (errorData) => {
        console.log("Erreur reçue:", errorData);
        setError(errorData);
        setOpenErrorModal(true);
    };

    const handleRowClick = (user) => {
        setSelectedUser(user);
        setOpenUserDetailsModal(true);
    };

    const handleCloseUserDetailsModal = () => {
        setOpenUserDetailsModal(false);
        setSelectedUser(null);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleOpen = (user) => {
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleShowSuccessMessage = (message) => {
        setSuccessMessage(message);
        setShowSuccessModal(true);
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchCriteria(searchValue);
        setShowResetIcon(searchValue.trim() !== '');
        if (searchValue.trim() !== '') {
            const results = utilisateurs.filter(user =>
                user.nom.toLowerCase().includes(searchValue.toLowerCase()) ||
                user.prenom.toLowerCase().includes(searchValue.toLowerCase()) ||
                user.email.toLowerCase().includes(searchValue.toLowerCase())
            );
            setSearchResults(results);
            setPage(0);
        } else {
            setSearchResults([]);
        }
    };

    const handleSort = (column) => {
        if (orderBy === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setOrder('asc');
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditClick = (event, userId) => {
        event.stopPropagation();
        onEditUtilisateur(userId);
    };

    const handleUpdateSuccess = () => {
        setEditingUserId(null);
        // Rafraîchir la liste des utilisateurs
        UtilisateurService.findUtilisateursByEntrepriseId(entrepriseId)
            .then((data) => {
                setUtilisateurs(data);
            })
            .catch((error) => {
                console.error('Error fetching utilisateurs:', error);
            });
    };
    const handleUpdateCancel = () => {
        setEditingUserId(null);
    };

    const handleDeleteClick = (event, user) => {
        event.stopPropagation();
        setUserToDelete(user);
        setDeleteConfirmOpen(true);
    };
    const handleConfirmDelete = async () => {
        setDeleteConfirmOpen(false);
        if (userToDelete) {
            try {
                await UtilisateurService.deleteUtilisateur(userToDelete.id);
                setUtilisateurs(prevUsers => prevUsers.filter(u => u.id !== userToDelete.id));
                handleShowSuccessMessage("L'utilisateur a été supprimé avec succès.");
            } catch (error) {
                console.error("Erreur lors de la suppression de l'utilisateur:", error);
                handleError(error);
            } finally {
                setUserToDelete(null);
            }
        }
    };
    const sortedUtilisateurs = (searchResults.length > 0 ? searchResults : utilisateurs).sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        if (aValue < bValue) {
            return order === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleAddClick = () => {
        if (onAddUtilisateur) {
            onAddUtilisateur();
        } else {
            console.warn("La fonction onAddUtilisateur n'est pas définie");
        }
    };
    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
        setUserToDelete(null);
    };

    const displayedUtilisateurs = sortedUtilisateurs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Box my={4}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Liste des Utilisateurs
                    </Typography>
                    <Box display="flex" alignItems="center" mb={2}>
                        <TextField
                            label="Rechercher"
                            value={searchCriteria}
                            onChange={handleSearch}
                            variant="outlined"
                            size="small"
                            sx={{ width: 400 }}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showResetIcon && (
                                            <IconButton
                                                aria-label="reset"
                                                onClick={() => {
                                                    setSearchCriteria('');
                                                    setSearchResults([]);
                                                    setShowResetIcon(false);
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            aria-label="search"
                                            edge="end"
                                            sx={{
                                                backgroundColor: theme.palette.primary.main,
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: theme.palette.primary.dark,
                                                },
                                            }}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<AddIcon />}
                            sx={{ ml: 1 }}
                            onClick={onAddUtilisateur}
                        >
                            Ajouter
                        </Button>
                    </Box>
                    {editingUserId ? (
                        <UpdateUtilisateur
                            id={editingUserId}
                            onCancel={handleUpdateCancel}
                            onSuccess={handleUpdateSuccess}
                        />
                    ) : (
                        <Paper elevation={3}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
                                            <TableCell align="center"></TableCell>
                                            <SortableTableCell align="center" onClick={() => handleSort('nom')}>
                                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                                                    Nom
                                                    {orderBy === 'nom' && <SortArrowIcon direction={order} />}
                                                </Box>
                                            </SortableTableCell>
                                            <SortableTableCell align="center" onClick={() => handleSort('prenom')}>
                                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                                                    Prénom
                                                    {orderBy === 'prenom' && <SortArrowIcon direction={order} />}
                                                </Box>
                                            </SortableTableCell>
                                            <SortableTableCell align="center" onClick={() => handleSort('email')}>
                                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                                                    Email
                                                    {orderBy === 'email' && <SortArrowIcon direction={order} />}
                                                </Box>
                                            </SortableTableCell>
                                            <SortableTableCell align="center" onClick={() => handleSort('poste')}>
                                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                                                    Poste
                                                    {orderBy === 'poste' && <SortArrowIcon direction={order} />}
                                                </Box>
                                            </SortableTableCell>
                                            <SortableTableCell align="center" onClick={() => handleSort('gestionnaire')}>
                                                <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', color: 'white' }}>
                                                    Gestionnaire
                                                    {orderBy === 'gestionnaire' && <SortArrowIcon direction={order} />}
                                                </Box>
                                            </SortableTableCell>
                                            <TableCell align="center" sx={{ color: 'white' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {displayedUtilisateurs.map((user) => (
                                            <TableRow
                                                key={user.id}
                                                onClick={() => handleRowClick(user)}
                                                style={{ cursor: 'pointer' }}
                                                hover
                                            >
                                                <TableCell align="center">
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            bgcolor: theme.palette.primary.main,
                                                            color: 'white',
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: '50%',
                                                        }}
                                                    >
                                                        <PersonIcon
                                                            sx={{
                                                                color: user.gestionnaire === true ? '#FFA500' : 'white',
                                                                fontSize: 24
                                                            }}
                                                        />
                                                    </Box>
                                                </TableCell>
                                                <TableCell align="center">{user.nom}</TableCell>
                                                <TableCell align="center">{user.prenom}</TableCell>
                                                <TableCell align="center">{user.email}</TableCell>
                                                <TableCell align="center">{user.poste}</TableCell>
                                                <TableCell align="center">{user.gestionnaire ? 'Oui' : 'Non'}</TableCell>
                                                <TableCell align="center">
                                                    <Box display="flex" justifyContent="center" alignItems="center">
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleEditClick(event, user.id)}
                                                            sx={{ marginRight: '8px' }}
                                                        >
                                                            <EditIcon style={{ color: blue[500] }} />
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={(event) => handleDeleteClick(event, user)}
                                                        >
                                                            <DeleteIcon style={{ color: red[500] }} />
                                                        </IconButton>
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[20]}
                                component="div"
                                count={sortedUtilisateurs.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    )}
                </Box>
    
                {/* Modal de confirmation de suppression */}
                <Dialog
                    open={deleteConfirmOpen}
                    onClose={handleCancelDelete}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmer la suppression"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Êtes-vous sûr de vouloir supprimer cet utilisateur ?
                            {userToDelete && (
                                <p>
                                    Utilisateur : {userToDelete.prenom} {userToDelete.nom}
                                    <br />
                                    Email : {userToDelete.email}
                                </p>
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                            Confirmer
                        </Button>
                    </DialogActions>
                </Dialog>
    
                {/* Modal d'erreur */}
                <Modal open={openErrorModal} onClose={() => setOpenErrorModal(false)}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80%',
                        maxHeight: '80%',
                        overflow: 'auto',
                    }}>
                        <Typography variant="h6" sx={{ color: theme.palette.error.main }}>
                            {error?.type || "Erreur"}
                        </Typography>
                        <Typography variant="body1" sx={{ color: theme.palette.error.main, mt: 2 }}>
                            {error?.mainMessage}
                        </Typography>
                    </Box>
                </Modal>
    
                {/* Modal de succès */}
                <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography variant="h6" sx={{ color: theme.palette.success.main }}>
                            {successMessage}
                        </Typography>
                    </Box>
                </Modal>
    
                {/* Modal pour afficher les détails de l'utilisateur */}
                <Dialog
                    open={openUserDetailsModal}
                    onClose={handleCloseUserDetailsModal}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogTitle>Détails de l'utilisateur</DialogTitle>
                    <DialogContent>
                        {selectedUser && (
                            <AfficherUtilisateur
                                utilisateur={selectedUser}
                                onEdit={(user) => {
                                    handleCloseUserDetailsModal();
                                    onEditUtilisateur(user.id);
                                }}
                                onDelete={(user) => {
                                    handleCloseUserDetailsModal();
                                    handleDeleteClick(null, user);
                                }}
                            />
                        )}
                    </DialogContent>
                </Dialog>
            </Container>
        </ThemeProvider>
    );
}

export default ListUtilisateurs;