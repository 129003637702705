import React from 'react';
import { Route, Routes, BrowserRouter as Router, Navigate } from 'react-router-dom';
import HomePage from "./vues/composants/Accueil/HomePage";
import NotFound from "./vues/composants/Accueil/NotFound";
import About from "./vues/composants/Accueil/About";
import { UserProvider } from './controleurs/middlewares/userContext';
import useToken from './controleurs/hooks/useToken';
import Login from './vues/composants/Login/Login';
import ResetPassword from './vues/composants/Login/ResetPassword';
import ForgotPassword from './vues/composants/Login/ForgotPassword';

function App() {
  const { token, setToken } = useToken();

  return (
    <Router>
      <React.StrictMode>
        <UserProvider>
          <Routes>
            {!token && (
              <>
                <Route path="/" element={<Login setToken={setToken} />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            )}

            {token && (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route path="*" element={<NotFound />} />
              </>
            )}
          </Routes>
        </UserProvider>
      </React.StrictMode>
    </Router>
  );
}

export default App;
