class Emprunt {
    constructor(id, vehicule, entreprise, nbPlaceDisponible, utilisateur,datePriseEnChargePrevue, datePriseEnCharge, dateRenduPrevue, dateRendu, lieuDePriseEnCharge, lieuRendu, kmPriseEnCharge, kmRemise, etat, motif,destination) {
        this.id = id;
        this.vehicule = vehicule;
        this.entreprise = entreprise;
        this.nbPlaceDisponible = nbPlaceDisponible;
        this.utilisateur = utilisateur;
        this.destination = destination;
        this.datePriseEnChargePrevue = datePriseEnChargePrevue;
        this.datePriseEnCharge = datePriseEnCharge;
        this.dateRenduPrevue = dateRenduPrevue;
        this.dateRendu = dateRendu;
        this.lieuDePriseEnCharge = lieuDePriseEnCharge;
        this.lieuRendu = lieuRendu;
        this.kmPriseEnCharge = kmPriseEnCharge;
        this.kmRemise = kmRemise;
        this.etat = etat;
        this.motif = motif;
    }

    toJSON() {
        return {
            id: this.id,
            vehicule: this.vehicule,
            entreprise: this.entreprise,
            nbPlaceDisponible: this.nbPlaceDisponible,
            utilisateur: this.utilisateur,
            datePriseEnChargePrevue: this.datePriseEnChargePrevue,
            datePriseEnCharge: this.datePriseEnCharge,
            dateRenduPrevue: this.dateRenduPrevue,
            dateRendu: this.dateRendu,
            lieuDePriseEnCharge: this.lieuDePriseEnCharge,
            lieuRendu: this.lieuRendu,
            kmPriseEnCharge: this.kmPriseEnCharge,
            kmRemise: this.kmRemise,
            etat: this.etat,
            motif: this.motif,  
            destination: this.destination,    
        };
    }
}

export default Emprunt;