class Site {
    constructor(id, entreprise, adresse, actif,horaireOuverture, procedure, complementInformation,designation,utilisateur) {
        this.id = id;
        this.entreprise = entreprise;
        this.adresse = adresse;
        this.actif = actif;
        this.horaireOuverture = horaireOuverture;
        this.procedure = procedure;
        this.complementInformation = complementInformation;
        this.designation = designation;
        this.utilisateur = utilisateur;
    }

    toJSON() {
        return {
            id: this.id,
            entreprise: this.entreprise,
            adresse: this.adresse,
            actif: this.actif,
            horaireOuverture: this.horaireOuverture,
            procedure: this.procedure,
            complementInformation: this.complementInformation,
            designation: this.designation,
            utilisateur: this.utilisateur
        };
    }
}

export default Site;