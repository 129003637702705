const Statut = {
    EMPRUNTE:'EMPRUNTE',
    DISPONIBLE:'DISPONIBLE',
    INDISPONIBLE:'INDISPONIBLE',
    DESACTIVE:'DESACTIVE'
};

function getStatutText(valeur) {
    switch (valeur) {
        case Statut.EMPRUNTE:
            return 'Emprunté';
        case Statut.DISPONIBLE:
            return 'Disponible';
        case Statut.INDISPONIBLE:
            return 'Indisponible';
        case Statut.DESACTIVE:
            return 'Désactivé';
        default:
            return 'Inconnu';
    }
}

function getStatutKey(valeur) {
    const entries = Object.entries(Statut);
    for (const [key, value] of entries) {
        if (value === valeur) {
            return key;
        }
    }
    return 'Inconnu';
}

export { Statut, getStatutText,getStatutKey };